import "./Login.css";

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useNavigate } from "react-router";
import app_store from "../../Assets/Images/app-store.jpg";
import logo from "../../Assets/Images/logo-moneta.svg";
import play_store from "../../Assets/Images/play-store.jpg";
import Loading from "../../components/Loader";
import { auth } from "../../redux/actions/Auth/Auth";
import { useAppDispatchJS, useAppSelectorJS } from "../../redux/hooks";

const Auth = ({ authenticate }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [displayLogin, setdisplayLogin] = useState(false);
  const dispatch = useAppDispatchJS();
  const validEmail = useAppSelectorJS((state) => state.ValidEmail);
  const [device_token, setDeviceToken] = useState("");
  useEffect(() => {
    //localStorage.setItem("user", false);
    let deviceToken = JSON.parse(
      localStorage.getItem("userDetails")
    )?.device_token;
    setDeviceToken(deviceToken);    
    if (localStorage.getItem("user") === "true") {
      // Check if branch object exists in the window object
      if (window["branch"]) {
        window["branch"].data(function (err, data) {
          // Avoid XSS by HTML escaping the data for display
          let result = data["data_parsed"];          
          if (result["~stage"] === "Pending_message_conversation") {
            redirectToMessage(result);
          } else if (result["~stage"] === "new_event") {
            navigate("/dashboard/calendar?nm=true");
          } else {
            navigate("/dashboard");
          }
        });

        // Branch object not working
        navigate("/dashboard");
      } else {
        navigate("/dashboard");
      }
    } else {
      const search = new URLSearchParams(window.location.search);
      const branchId = new URLSearchParams(search).get("_branch_match_id");
      if (branchId?.length > 0) {
        window["branch"].data(function (err, data) {
          // Avoid XSS by HTML escaping the data for display
          let result = data["data_parsed"];
          if (result["~stage"] === "create_client_user") {
            navigate({
              pathname: "/verify-email",
              search: `?token=${result["token"]}&email=${result["email"]}`,
            });
          } else if (result["~stage"] === "reset_password_link") {
            navigate({
              pathname: "/verify-email",
              search: `?token=${result["token"]}&email=${result["email"]}&is_reset_pwd_verify=true`,
            });
          } else {
            setdisplayLogin(true);
            localStorage.setItem("user", false);
          }
        });
      } else {
        setdisplayLogin(true);
        localStorage.setItem("user", false);
      }
    }
  }, [validEmail]);

  function redirectToMessage(result) {
    localStorage.setItem("channelUrl", result["channel_url"]);
    navigate("/dashboard/Messages");
  }
  //For Tickte MON-192
  const [email, setEmail] = useState("");
  const [onNextClick, setNext] = useState(false);
  const [validEmailError, setValidEmailError] = useState(false);

  function handleNext(e) {
    setLoader(true);
    e.preventDefault();
    setNext(true);
    dispatch(auth.validateEmail(email, device_token)).then((user) => {
      setLoader(false);
      var value = user;
      if (user.type === "EMAIL_VALID_SUCCESS") {
        localStorage.setItem("Email", email);
        if (value.data.is_otp_required) {
          localStorage.setItem("userDetails", JSON.stringify(value.data));
          navigate("/send-otp");
        } else {
          navigate("/login-password");
        }
      } else {
        setValidEmailError(true);
      }
    });
  }

  function loginWithSSO(e) {
    e.preventDefault();
    let redirectUrl = "";
    if (process.env.REACT_APP_AUTH_DEVPORTAL) {
      // Used for local testing if client and advisor are needed. NOTE: You must be connected to the database that devportal is connected to since the saml2_auth endpoint
      // adds entries to the database for the new tokens
      redirectUrl = "https://devportal.monetagroup.com/saml2_auth/login?redirect_url=" + window.location.origin + "/sso/initiate"
    } else if (process.env.REACT_APP_AUTH_LOCAL_DEVPORTAL) {
      // Used for local testing if you want client and advisor as well as the ability to test the sso login with a new database (Good for working on new integrations separate from dev db)
      redirectUrl = "https://grand-liked-tuna.ngrok-free.app" + "/saml2_auth/login";
    } else {
      // Used for production
      redirectUrl = window.location.origin + "/saml2_auth/login?redirect_url=" + window.location.origin + "/sso/initiate";
    }
    // Used for local testing if only client is needed
    // const redirectUrl = window.location.origin +"/saml2_auth/login?redirect_url=" + window.location.origin +"/sso/initiate";  
    window.location.href = redirectUrl;
  }

  return (
    <>
      {loader && <Loading active={loader} />}

      {displayLogin && (
        <form name="form-email" onSubmit={handleNext}>
          <div
            className={`form-group ${onNextClick && !email ? "has-error" : ""
              }  `}
          >
            <label className="form-label">
              Email Address <sup>*</sup>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter email address"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setValidEmailError(false);
                setNext(false);
              }}
            />
            {onNextClick && !email && (
              <div className="invalid-feedback d-block">Email is required</div>
            )}
            {onNextClick && validEmailError && email && (
              <div className="invalid-feedback d-block">
                {validEmail.error.email[0]}
              </div>
            )}
          </div>
          <div className="mb-16">
            <button className="btn btn-success w-100">Next</button>
          </div>
          <div className="mb-16 pt-8">
            <button
              className="btn btn-sso w-100"
              onClick={(e) => loginWithSSO(e)}
            >
              <img src={logo} alt="" />
              Employee Login
            </button>
          </div>
          <div className="text-center mb-8">
            <Link to="/forgot-email" className="btn-link fs-18 lh-32">
              Forgot email address?
            </Link>
          </div>
          <div className="mb-16 pt-8 text-center">
            Available on the iOS and Android app stores.
          </div>
          <div className="text-center mb-16">
            <a href="https://apps.apple.com/us/app/moneta-group/id1617799877" target="_blank" rel="noreferrer">
              <img src={app_store} alt="" />
            </a>
            <span> &nbsp;&nbsp;&nbsp;&nbsp; </span>
            <a href="https://play.google.com/store/apps/details?id=com.moneta.app" target="_blank" rel="noreferrer">
              <img src={play_store} alt="" />
            </a>
          </div>
        </form>
      )}
    </>
  );
};

export default Auth;
