// Tanner Fry
// tfry@monetagroup.com
// File contains the messages component for the twilio dashboard. This handles and displays messages for a conversation.

import { MessageOutlined } from "@ant-design/icons";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import DOMPurify from "dompurify";
import GifPicker from 'gif-picker-react';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { convertISOToTime, notifyInAppError, notifyInAppSuccess, SystemMessage } from "../../components/UtilitiesTS";
import { ChatType, IUserDetails } from "../../interfaces/General";
import { ITwilioConversationsConversationProps, ITwilioConversationsMessageProps, ITwilioConversationsParticipantProps } from "../../interfaces/TwilioConversations";
import { TwilioConversationsRedux } from "../../redux/actions/TwilioConversations/TwilioConversations";
import { useAppDispatchTS, useAppSelectorTs } from "../../redux/hooksTS";
import { reduxMessageIndex, reduxScrollToMessage, reduxSetActiveConversation, reduxSetLoadedConversationsPreSendMessage, reduxSetMessageIndex, reduxSetScrollToMessage } from "../../redux/slicesTS/TwilioConversationsSlice";
import TCUtilities from "./TwilioConversationUtilities";
// MUI Imports
import AddIcon from '@mui/icons-material/Add';
import AttachFile from '@mui/icons-material/AttachFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import GifBoxOutlinedIcon from '@mui/icons-material/GifBoxOutlined';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RemoveIcon from '@mui/icons-material/Remove';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { Popper } from "@mui/material";
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import { twilioConversationsConstants } from "../../redux/constants/TwilioConversationsConstant";
import { clearChatOpenClass } from "../../redux/slicesTS/ChatOpenSlice";

const VisuallyHiddenInput = styled("input")({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface IEmoji {
    aliases: string[];
    id: string;
    keywords: string[];
    name: string;
    native: string;
    shortcodes: string[];
    skin: number;
    unified: string;
}

interface ITwilioMessagesProps {
    conversation: ITwilioConversationsConversationProps | null;
    userDetailsAsParticipant: ITwilioConversationsParticipantProps | null;
    handleCreateNewMessage: (conversationId: string, message: string, participantSid: string, participantName: string) => void;
    handlePullNextPageOfMessages: (conversation: ITwilioConversationsConversationProps) => void;
    paginatorClicked?: boolean;
    setPaginatorClicked: (clicked: boolean) => void;
    messagesHasNextPage: boolean;
    setShowConversationInfo: (show: boolean) => void;
    setShowConversationSearch: (show: boolean) => void;
    isMessagingEnabled: boolean;
    userDetails: IUserDetails;
    chatType: string;
    setHasUserScrolled: React.Dispatch<React.SetStateAction<boolean>>;
    hasUserScrolled: boolean;
}

const TwilioMessages = ({
    conversation,
    userDetailsAsParticipant,
    handleCreateNewMessage,
    handlePullNextPageOfMessages,
    paginatorClicked,
    setPaginatorClicked,
    messagesHasNextPage,
    setShowConversationInfo,
    setShowConversationSearch,
    isMessagingEnabled,
    userDetails,
    chatType,
    setHasUserScrolled,
    hasUserScrolled

}: ITwilioMessagesProps) => {
    const newMessage = useAppSelectorTs((state) => state.twilioConversations.loadedConversationsPreSendMessage.find((loadedConversation) => loadedConversation.conversation_sid === conversation?.sid)?.message || "");
    const [isMessageTextAreaEnabled, setIsMessageTextAreaEnabled] = useState<boolean>(true);
    const messageScrollEndRef = useRef<HTMLDivElement>(null);
    const messageListRef = useRef<HTMLDivElement>(null);
    const [isExtraActionsActive, setIsExtraActionsActive] = useState<boolean>(false);
    const [isEmojiPickerActive, setIsEmojiPickerActive] = useState<boolean>(false);
    const [selectedEmoji, setSelectedEmoji] = useState<string>("");  // Left for now in case we want to do something else with the emoji
    const [isGifPickerActive, setIsGifPickerActive] = useState<boolean>(false);
    const [selectedGifUrl, setSelectedGifUrl] = useState<string>("");  // Left for now in case we want to do something else with the gif
    const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
    const [emojiToInsert, setEmojiToInsert] = useState<string>("");
    const [activeReactionMessage, setactiveReactionMessage] = useState<HTMLDivElement | null>(null);
    const refFileUploadInput = useRef<HTMLInputElement>(null);
    const antdMessageIconProps = {
        className: 'icon-32',
        // onPointerEnterCapture: () => {},
        // onPointerLeaveCapture: () => {},
    };
    const dispatch = useAppDispatchTS();
    const loadedConversationsPreSendMessage = useAppSelectorTs((state) => state.twilioConversations.loadedConversationsPreSendMessage);
    const [isMessageFooterPopperOpen, setIsMessageFooterPopperOpen] = useState<boolean>(false);
    const refMessageFooter = useRef<HTMLDivElement>(null);
    const refExtraActionsButton = useRef<HTMLDivElement>(null);
    const [isAddReactionButtonPopperOpen, setAddReactionButtonPopperOpen] = useState<boolean>(false);
    const refAddReactionButton = useRef<(HTMLDivElement | null)[]>([]);
    const [reactionIcon, setReactionIcon] = useState<string>("");
    const [currentMessageIdHolder, setCurrentMessageIdHolder] = useState<string>("");
    const [currentMessageId, setCurrentMessageId] = useState<string>("");
    // Validation text
    const refNewMessageTextbox = useRef<HTMLTextAreaElement>(null);
    const [newMessageValidationText, setNewMessageValidationText] = useState<string>("");
    const [isNewMessagePopoverOpen, setIsNewMessagePopoverOpen] = useState<boolean>(false);
    const scrollToMessage = useAppSelectorTs(reduxScrollToMessage);
    const messageIndex = useAppSelectorTs(reduxMessageIndex)
    const handleScroll = () => {
        setHasUserScrolled(true);
        // If messageScrollEndRef is in view, set hasUserScrolled to false
        if (messageScrollEndRef.current && messageScrollEndRef.current.getBoundingClientRect().top <= window.innerHeight) {
            setHasUserScrolled(false);
        }
    }
    useEffect(() => {
        if (messageIndex >= 0 && scrollToMessage >= 0) {
            console.log("Message Index: ", messageIndex);
            const scrollTimeout = setTimeout(() => {
                const messageElement = document.getElementById(`message-` + messageIndex);
                messageElement?.scrollIntoView({ behavior: 'smooth' });
                messageElement?.classList.add('highlighted');
                const highlightTimeout = setTimeout(() => {
                messageElement?.classList.remove('highlighted');
                clearTimeout(highlightTimeout);
                dispatch(reduxSetScrollToMessage(-1))
                dispatch(reduxSetMessageIndex(-1))
                }, 1000);
            }, 500);
            
            return () => {
                clearTimeout(scrollTimeout);
                
            };
        }
        
    },[scrollToMessage])

    const scrollToBottom = () => {
        messageScrollEndRef.current?.scrollIntoView({ behavior: "smooth" });
        setHasUserScrolled(false);
    };

    useEffect(() => {
        // NOTE: Twilio max MMS size is 5MB or a total of 10 files per message
        // INFO: IMPORTANT - We also upload all files in Azure blob so the size may be compressed there before it's sent over the wire
        // INFO: See https://www.twilio.com/docs/messaging/guides/accepted-mime-types
        // INFO: See https://help.twilio.com/articles/360018832773-Twilio-Programmable-SMS-Supported-File-Types-and-Size-Limits-for-MMS-Media-Messages
        if (selectedFiles && selectedFiles.length > 10) {
            notifyInAppError("Maximum of 10 files per message. Please select fewer files.");
            setSelectedFiles(null);
            setIsMessageFooterPopperOpen(false);
            // Update file upload ref
            if (refFileUploadInput.current) {
                refFileUploadInput.current.value = "";
                refFileUploadInput.current.files = null;
            }
        }
        if (selectedFiles && selectedFiles.length > 0) {
            setIsMessageFooterPopperOpen(true);
            // Check the size of the files
            // NOTE: If this is for the chat integration, then we can set an arbitrary limit of 25MB for the total size of the files.
            // NOTE: For texting, we provide a link so the same arbitrary limit can be used.
            let totalSize = 0;
            let isAFileLargerThan25MB = false;
            let fileNamesOver25MB: string[] = [];
            Array.from(selectedFiles).forEach((file) => {
                // Check against 25MB
                if (file.size > 26214400) {
                    isAFileLargerThan25MB = true;
                    fileNamesOver25MB.push(file.name);
                }
            });
            if (isAFileLargerThan25MB) {
                notifyInAppError("The following files are larger than 25MB: " + fileNamesOver25MB.join(", ") + ". Please maintain a total file size of 25MB or less.");
                // Remove the files from the selected files
                try {
                    setSelectedFiles((prevSelectedFiles) => {
                        if (!prevSelectedFiles) return null;
                        let newSelectedFiles = prevSelectedFiles;
                        fileNamesOver25MB.forEach((fileName) => {
                            newSelectedFiles = Array.from(newSelectedFiles).filter((file: File) => file.name !== fileName) as unknown as FileList;
                        });

                        // Update file upload ref
                        if (refFileUploadInput.current && newSelectedFiles.length > 0) {
                            refFileUploadInput.current.value = "C:\\fakepath\\" + Array.from(newSelectedFiles)[0].name;
                            refFileUploadInput.current.files = newSelectedFiles;
                        } else if (refFileUploadInput.current && newSelectedFiles.length === 0) {
                            refFileUploadInput.current.value = "";
                            refFileUploadInput.current.files = null;
                        } else {
                            console.log("Error: File upload ref is null or undefined");
                        }

                        return newSelectedFiles;
                    });
                } catch (error) {
                    console.log("Error removing files larger than 25MB: ", error);
                }
            }
        } else {
            setIsMessageFooterPopperOpen(false);
        }
    }, [selectedFiles]);

    useEffect(() => {
        if (conversation && newMessageValidationText !== "") {
            setIsNewMessagePopoverOpen(true);
        }
    }, [newMessageValidationText]);

    useEffect(() => {
        // Don't scroll to the bottom if paginator was clicked
        if (!paginatorClicked) {
            // Check if the user has scrolled at all. If so, don't scroll to the bottom
            if (hasUserScrolled) {
                return;
            }
            scrollToBottom();
        } else {
            setPaginatorClicked(false);
        }

        // Determine if any sms participants in conversation are opted in. If so, allow messaging
        const sms_participants = conversation?.participants.filter((participant) => {
            return participant.messaging_binding?.type === 'sms';
        });
        let has_opt_in_participant = false
        if (sms_participants && sms_participants.length > 0) {
            const sms_participant = sms_participants[0];
            if (sms_participant.opt_in_status === true) {
                has_opt_in_participant = true;
                console.log("Participant is opted in to conversation");
            } else {
                console.log("Participant is not opted in to conversation");
            }
        }
        // setIsMessageTextAreaEnabled(has_opt_in_participant);

        // Add current user to "read_by" for each message in conversation if not already in it
        conversation?.messages?.map((message) => {
            if (!message.attributes.read_by?.includes(userDetails.twilio_conversations_user_identity)) {
                dispatch(TwilioConversationsRedux.UpdateUserMessageReadBy(message.sid, userDetails.twilio_conversations_user_identity));
            }
        })
    }, [conversation]);

    const handleEmojiPickerStatusFlip = () => {
        if (isEmojiPickerActive) {
            setIsEmojiPickerActive(false);
        } else {
            setIsEmojiPickerActive(true);
            setIsGifPickerActive(false);
        }
    }

    const handleAddEmjoiToMessage = useCallback((emoji: IEmoji) => {
        console.log("Emoji clicked: ", emoji);

        if (conversation) {
            setSelectedEmoji(emoji.native);
            dispatch(reduxSetLoadedConversationsPreSendMessage(loadedConversationsPreSendMessage.map((loadedConversation: { conversation_sid: string, message: string }) => {
                if (loadedConversation.conversation_sid === conversation.sid) {
                    if (refNewMessageTextbox.current) {
                        refNewMessageTextbox.current.innerHTML = loadedConversation.message + emoji.native;
                    }
                    return { ...loadedConversation, message: loadedConversation.message + emoji.native };

                }
                return loadedConversation;
            })));
        } else {
            notifyInAppError("Error: Could not insert emoji into message. Please notify support.");
            // Close the emoji picker on eerror
            setIsEmojiPickerActive(false);
        }
    }, [conversation, loadedConversationsPreSendMessage]);

    const handleGifPickerStatusFlip = () => {
        if (isGifPickerActive) {
            setIsGifPickerActive(false);
        } else {
            setIsGifPickerActive(true);
            setIsEmojiPickerActive(false);
        }
    }
    const handleExtraActionsFlip = () => {
        if (isExtraActionsActive) {
            setIsExtraActionsActive(false);
            setIsGifPickerActive(false);
            setIsEmojiPickerActive(false);
        } else {
            setIsExtraActionsActive(true);
            setIsGifPickerActive(false);
            setIsEmojiPickerActive(false);
        }
    }

    return (
        <div className="twilio-conversations-messages flex-grow-1">
            <div className="messages-header d-md-flex align-items-center justify-content-between"
                style={conversation ? { display: "block" } : { padding: "0", border: "none" }}
            >
                <span className="d-md-none icon-32 me-12">
                    <em
                        className="sb-back fs-24 text-success"
                        onClick={(event) => {
                            dispatch(clearChatOpenClass())
                            dispatch(reduxSetActiveConversation(null));
                        }}
                    ></em>
                </span>
                <h5 className="conversation-title m-0">{conversation?.friendly_name}</h5>
                <div className="conversation-actions d-flex">
                    {/* Search icon */}
                    <span
                        className="icon-32 cursor-pointer"
                        onClick={() => {
                            setShowConversationInfo(false);
                            setShowConversationSearch(true);
                        }}
                        style={conversation ? { display: "block" } : { display: "none" }}
                    >
                        <em className="icon-search-outline fs-24"></em>
                    </span>
                    {/* Settings icon */}
                    <span
                        className="icon-32 cursor-pointer "
                        onClick={() => {
                            setShowConversationInfo(true);
                            setShowConversationSearch(false);
                        }}
                        style={conversation ? { display: "block" } : { display: "none" }}
                    >
                        <em className="icon-info-circle-outline fs-24"></em>
                    </span>
                </div>
            </div>
            <div
                className="messages-list flex-grow-1"
                style={{
                    height: conversation ? "unset" : "90%",
                }}
                ref={messageListRef}
                onScroll={() => { handleScroll() }}
            >
                {/* Messages */}
                {/* TODO: Add message styling for current user messages vs other user messages
                TODO: Add message status
                TODO: Add message actions
                TODO: Add message reactions
                TODO: Add message replies
                TODO: Add message mentions
                TODO: Add message styling for different message types
                TODO: Add message styling for different message statuses */}
                {conversation && messagesHasNextPage && (
                    <div className="pageinator">
                        <button
                            className="btn-paginator"
                            onClick={() => {
                                if (conversation) {
                                    handlePullNextPageOfMessages(conversation);
                                }
                            }}
                        >
                            Load previous messages
                        </button>
                    </div>
                )}
                {conversation && conversation.messages && userDetailsAsParticipant && conversation.messages.length > 0 ? (
                    conversation.messages.map((message: ITwilioConversationsMessageProps, index) => {
                        if (!conversation.messages) return null;
                        const previousMessage = index > 0 ? conversation.messages[index - 1] : undefined;
                        const dateSeparator = TCUtilities.getDateSeparator(message, previousMessage);

                        return (
                            // Return a date separator if the current message doesn't have the same date as the previous message
                            <React.Fragment key={index}>
                                {dateSeparator && (
                                    <div className="date-separator">
                                        <div className="line"></div>
                                        <span>{dateSeparator}</span>
                                        <div className="line"></div>
                                    </div>
                                )}
                                {message.author === "system" ? (
                                    <SystemMessage message={message.body} />
                                ) : (
                                    <div className={"message-item " + ((TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid)) ? "current-user" : "other-user")} id={`message-` + message.index}>
                                        <div className="message-item-image">
                                            {message.profile_image ? (
                                                <img src={message.profile_image} alt="Profile" />
                                            ) : (
                                                <em className="icon-user"></em>
                                            )}
                                        </div>
                                        <div className="message-item-details">
                                            <div className="message-author">
                                                {!TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && (
                                                    // Check if the author is a phone number or not. If so, format the number for display.
                                                    message.author.startsWith("+") ? (
                                                        <>
                                                            {/* NOTE: We've changed showing numbers in message to showing conversation name as we're assuming most people will
                                                                name the conversation after who they're texting. This is also utilized in TwilioConversationSearch for message author.
                                                            */}
                                                            {/* <h6>{TCUtilities.formatPhoneNumber(message.author)}</h6> */}
                                                            <h6>{conversation.friendly_name}</h6>
                                                            {!TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid)
                                                                && message.attributes.text_user_opt_in_at_time_of_message === false && (
                                                                    <div className="message-not-opted-in">
                                                                        Not opted in to conversation
                                                                    </div>
                                                                )}
                                                        </>
                                                    ) : (
                                                        <h6>{message.author}</h6>
                                                    )
                                                )}
                                            </div>
                                            <div className="message-content">
                                                {/* TODO: Add a isDeilvered flag for this? */}
                                                {/* TODO: update read status in background */}
                                                {TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && (
                                                    <div className="message-read">
                                                        {message.attributes.is_unread ? (
                                                            <CheckIcon fontSize="inherit" />
                                                        ) : (
                                                            <DoneAllIcon color="primary" fontSize="inherit" />
                                                        )}
                                                    </div>
                                                )}

                                                {TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && (
                                                    <div className="message-time">
                                                        {/* Always created_at, modified_at should not be used */}
                                                        {convertISOToTime(message.created_at)}
                                                    </div>
                                                    // <div className={"message-time " + (message.created_at !== message.modified_at ? "edited" : "")}>
                                                    //     {message.created_at === message.modified_at ? (
                                                    //         convertISOToTime(message.created_at)
                                                    //     ) : (
                                                    //         convertISOToTime(message.modified_at) + " (edited)"
                                                    //     )}
                                                    // </div>
                                                )}
                                                <div className="message-body-wrapper">
                                                    {/* If body is an empty string and media is not, insert media */}
                                                    {/* NOTE: This is for twilio created images and media which usually have the body empty */}
                                                    {message.body === "" && message.media && message.media.length > 0 && typeof message.media === "object" && (
                                                        <div className="message-sms-media">
                                                            {message.media.map((media, index) => {
                                                                if (media.content_type.includes("image")) {
                                                                    return (
                                                                        <div key={index} className="message-sms-media-item">
                                                                            {/* Example: https://devmonetabesa.blob.core.windows.net/public-media/tc-files/xyz */}
                                                                            <a href={media.url} target="_blank" rel="noreferrer">
                                                                                <img src={media.url} alt={media.filename} />
                                                                            </a>
                                                                        </div>
                                                                    );
                                                                } else if (media.content_type.includes("video")) {
                                                                    return (
                                                                        <div key={index} className="message-sms-media-item">
                                                                            <video controls>
                                                                                <source src={media.url} type={media.content_type} />
                                                                            </video>
                                                                        </div>
                                                                    );
                                                                } else if (media.content_type.includes("audio")) {
                                                                    return (
                                                                        <div key={index} className="message-sms-media-item">
                                                                            <audio controls>
                                                                                <source src={media.url} type={media.content_type} />
                                                                            </audio>
                                                                            <br />
                                                                            <a href={media.url} target="_blank" rel="noreferrer">
                                                                                {media.filename}
                                                                            </a>
                                                                            {media.content_type == "audio/amr" && (
                                                                                <>
                                                                                    <br />
                                                                                    <span >Note: Apple audio is currently not playable in the browser.</span>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                } else if (media.content_type.includes("application/pdf")) {
                                                                    return (
                                                                        <div key={index} className="message-sms-media-item">
                                                                            <a href={media.url} target="_blank" rel="noreferrer">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z" /></svg>
                                                                            </a>
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <div key={index} className="message-sms-media-item">
                                                                            <span>Unsupported media:</span><br />
                                                                            <a href={media.url} target="_blank" rel="noreferrer">
                                                                                {media.filename}
                                                                            </a>
                                                                        </div>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                    )}
                                                    {/* If body is not an empty string, insert body */}
                                                    {/* NOTE: This also works with multimedia we create as we set the media data and the body to be the media URL of our content */}
                                                    <div
                                                        className="message-body"
                                                        // Sanitize and preserve special characters in Twilio message with HTML elements. 
                                                        // Also, allow us to modify the message body to add target="_blank" to links.
                                                        dangerouslySetInnerHTML={{
                                                            __html: TCUtilities.performTcMessageModifications(DOMPurify.sanitize(
                                                                TCUtilities.preserveSpecialCharactersInTwilioMessageWithHTMLElements(
                                                                    message.body
                                                                )
                                                            ))
                                                        }}
                                                    />
                                                    {/* TODO: Need to change this to use a message.attr instead of a use state */}
                                                    {!TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && chatType == ChatType.CHAT && reactionIcon !== null && currentMessageId === message.sid && (
                                                        <div className="reaction-cont">
                                                            <div className="reaction-icon" dangerouslySetInnerHTML={{ __html: reactionIcon }}></div>
                                                        </div>
                                                    )}
                                                </div>
                                                {/* TODO: pass current messageId into state to update the attr once setup */}
                                                {/* {!TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && chatType == ChatType.CHAT && (
                                                    <div className="add-reaction" ref={el => refAddReactionButton.current[index] = el} id={'add-reaction-button' + message.index}>
                                                        <button className="reaction-button" onClick={() => { setAddReactionButtonPopperOpen(!isAddReactionButtonPopperOpen); setactiveReactionMessage(refAddReactionButton.current[index]); setCurrentMessageIdHolder(message.sid); }}>
                                                            <AddReactionOutlinedIcon />
                                                        </button>
                                                    </div>
                                                )} */}

                                                {!TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && (
                                                    <div className="message-time">
                                                        {/* Always created_at, modified_at should not be used */}
                                                        {convertISOToTime(message.created_at)}
                                                    </div>
                                                    // <div className={"message-time " + (message.created_at !== message.modified_at ? "edited" : "")}>
                                                    //     {message.created_at === message.modified_at ? (
                                                    //         convertISOToTime(message.created_at)
                                                    //     ) : (
                                                    //         convertISOToTime(message.modified_at) + " (edited)"
                                                    //     )}
                                                    // </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                )}
                            </React.Fragment>

                        );
                    })
                ) : (
                    <>
                        {conversation && conversation.messages && conversation.messages.length === 0 ? (
                            <div className="no-messages">
                                <MessageOutlined {...antdMessageIconProps} />
                                <h5>No messages at this time</h5>
                            </div>
                        ) : (
                            <div className="no-messages">
                                <MessageOutlined {...antdMessageIconProps} />
                                <h5>Select a conversation to get started</h5>
                            </div>
                        )}
                    </>
                )}
                <div ref={messageScrollEndRef}></div>
            </div>
            {/* <Popover
                id={isAddReactionButtonPopperOpen ? 'add-reactions-popper' : undefined}
                open={isAddReactionButtonPopperOpen}
                anchorEl={activeReactionMessage}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                        setAddReactionButtonPopperOpen(false);
                    }
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className="add-reactions-popper"
            > */}
                {/* TODO: add onCLick function to update message.attr once setup on BE */}
                {/* <button
                    onClick={() => {
                        console.log("Clicked &#128525; on message ID" + currentMessageId); setAddReactionButtonPopperOpen(false); setReactionIcon("&#128525;"); setCurrentMessageId(currentMessageIdHolder);
                    }}
                    style={{ fontSize: '1.5em' }}
                >
                    &#128525;
                </button>
                <button
                    onClick={() => {
                        console.log("Clicked &#128518; on message ID" + currentMessageId); setAddReactionButtonPopperOpen(false); setReactionIcon("&#128518;"); setCurrentMessageId(currentMessageIdHolder);
                    }}
                    style={{ fontSize: '1.5em' }}>
                    &#128518;
                </button>
                <button
                    onClick={() => {
                        console.log("Clicked &#128517; on message ID" + currentMessageId); setAddReactionButtonPopperOpen(false); setReactionIcon("&#128517;"); setCurrentMessageId(currentMessageIdHolder);
                    }}
                    style={{ fontSize: '1.5em' }}
                >&#128517;
                </button>
                <button
                    onClick={() => {
                        console.log("Clicked &#128557; on message ID" + currentMessageId); setAddReactionButtonPopperOpen(false); setReactionIcon("&#128557;"); setCurrentMessageId(currentMessageIdHolder);
                    }}
                    style={{ fontSize: '1.5em' }}
                >&#128557;
                </button>
                <button
                    onClick={() => {
                        console.log("Clicked &#128545; on message ID" + currentMessageId); setAddReactionButtonPopperOpen(false); setReactionIcon("&#128545;"); setCurrentMessageId(currentMessageIdHolder);
                    }}
                    style={{ fontSize: '1.5em' }}
                >&#128545;
                </button>
                <button
                    onClick={() => {
                        console.log("Clicked &#128077; on message ID" + currentMessageId); setAddReactionButtonPopperOpen(false); setReactionIcon("&#128077;"); setCurrentMessageId(currentMessageIdHolder);
                    }}
                    style={{ fontSize: '1.5em' }}
                >&#128077;
                </button>
                <button
                    onClick={() => {
                        console.log("Clicked &#128078; on message ID" + currentMessageId); setAddReactionButtonPopperOpen(false); setReactionIcon("&#128078;"); setCurrentMessageId(currentMessageIdHolder);
                    }}
                    style={{ fontSize: '1.5em' }}
                >&#128078;
                </button> */}
            {/* </Popover> */}
            {/* <Popper
                id={isMessageFooterPopperOpen ? 'message-footer-popper' : undefined}
                open={isMessageFooterPopperOpen}
                anchorEl={refMessageFooter.current}
                // onClose={() => {
                //     setIsMessageFooterPopoverOpen(false)
                // }}
                placement="top-start"
                className="message-footer-popper"
            >
                If your files or images contain sensitive content, please utilize our ShareFile integration.
            </Popper> */}
            <div
                className="messages-footer"
                ref={refMessageFooter}
            // style={{
            //     height: selectedFiles && selectedFiles.length > 0 ? "15%" : "10%",
            // }}
            >
                <div
                    className="selected-files-for-upload"
                // style={{
                //     height: selectedFiles && selectedFiles.length > 0 ? "50%" : "0",
                // }}
                >
                    {selectedFiles && selectedFiles.length > 0 && (
                        <div className="selected-files-for-upload-list">
                            {Array.from(selectedFiles).map((file, index) => {
                                return (
                                    <div key={index} className="selected-file-for-upload">
                                        <div className="remove-icon" onClick={() => {
                                            setSelectedFiles((prevSelectedFiles) => {
                                                if (!prevSelectedFiles) return null;

                                                const newFiles = Array.from(prevSelectedFiles).filter((selectedFile) => selectedFile.name !== file.name) as unknown as FileList;
                                                // Update file upload ref
                                                if (newFiles.length === 0 && refFileUploadInput.current) {
                                                    refFileUploadInput.current.value = "";
                                                    refFileUploadInput.current.files = null;
                                                } else if (newFiles.length > 0 && refFileUploadInput.current) {
                                                    refFileUploadInput.current.value = "C:\\fakepath\\" + Array.from(newFiles)[0].name;
                                                    refFileUploadInput.current.files = newFiles;
                                                }

                                                return newFiles;
                                            });
                                        }}>
                                            <CloseOutlinedIcon sx={{ color: "white" }} />
                                        </div>
                                        {file.type.includes("image") ? (
                                            <div className="file-icon">
                                                <ImageIcon sx={{ color: "white" }} />
                                            </div>
                                        ) : (
                                            <div className="file-icon">
                                                {file.type.includes("pdf") ? (<PictureAsPdfIcon sx={{ color: "white" }} />) : (
                                                    <>
                                                        {file.type.includes("audio") ? (<AudioFileIcon sx={{ color: "white" }} />) : (
                                                            <>
                                                                {file.type.includes("video") ? (<VideoFileIcon sx={{ color: "white" }} />) : (
                                                                    <TextSnippetIcon sx={{ color: "white" }} />
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                        )}
                                        <span>
                                            {(() => {
                                                let filename = file.name;
                                                let filenameExtension = filename.split('.').pop();
                                                if (filename.length > 20) {
                                                    filename = filename.slice(0, 20) + "..." + filenameExtension;
                                                }

                                                return filename;
                                            })()}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>

                <Popover
                    open={isExtraActionsActive}
                    anchorEl={refExtraActionsButton.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    id={'message-extraactions-popper'}
                    onClose={(event, reason) => {
                        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                            // Set 'open' to false, however you would do that with your particular code.
                            setIsEmojiPickerActive(false);
                            setIsGifPickerActive(false);
                            setIsExtraActionsActive(false);
                        }
                    }}
                >
                    <Popper
                        open={isEmojiPickerActive}
                        anchorEl={refExtraActionsButton.current}
                        placement="right-start"
                        className="extra-actions-popper"
                        id={isEmojiPickerActive ? 'message-emoji-popper' : undefined}
                    >
                        {/* Add invisible background that when clicked will set emoji picker to hidden */}
                        <div className={"emoji-picker-background " + (isEmojiPickerActive ? "active" : "hidden")} onClick={() => setIsEmojiPickerActive(false)} />
                        <div className={"emoji-picker " + (isEmojiPickerActive ? "active" : "hidden")}>
                            {/* <EmojiPicker 
                                onEmojiClick={(emojiObject: EmojiClickData, event) => {
                                    handleAddEmjoiToMessage(emojiObject);
                                }}
                            /> */}
                            <Picker
                                data={data}
                                categories={['frequent', 'people', 'nature', 'foods', 'activity', 'places', 'objects', 'symbols', 'flags']}
                                exceptEmojis={[]}
                                onEmojiSelect={(emoji: IEmoji, event: any) => {
                                    handleAddEmjoiToMessage(emoji);
                                }}
                                theme="light"
                            />
                        </div>

                    </Popper>

                    <Popper
                        open={isGifPickerActive}
                        anchorEl={refExtraActionsButton.current}
                        id={isGifPickerActive ? 'message-gif-popper' : undefined}
                        className="extra-actions-popper"
                        placement="right-start"
                    >
                        {/* Add invisible background that when clicked will set gif picker to hidden */}
                        <div className={"tenor-gif-picker-background " + (isGifPickerActive ? "active" : "hidden")} onClick={() => setIsGifPickerActive(false)} />
                        <div className={"tenor-gif-picker " + (isGifPickerActive ? 'active' : "hidden")}>
                            <GifPicker
                                tenorApiKey="AIzaSyA1AHgaWt7c7t7JmVdDrUv5-ggZHSyxUkw"
                                onGifClick={(gif) => {
                                    console.log("Gif clicked: ", gif);

                                    // Insert gif into message
                                    if (conversation) {
                                        setSelectedGifUrl(gif.url);
                                        dispatch(reduxSetLoadedConversationsPreSendMessage(loadedConversationsPreSendMessage.map((loadedConversation: { conversation_sid: string, message: string }) => {
                                            if (loadedConversation.conversation_sid === conversation.sid) {
                                                if (refNewMessageTextbox.current) {
                                                    refNewMessageTextbox.current.innerHTML = loadedConversation.message + gif.url;
                                                }
                                                return { ...loadedConversation, message: loadedConversation.message + gif.url };
                                            }
                                            return loadedConversation;
                                        })));
                                        // Close the gif picker
                                        setIsGifPickerActive(false);
                                    } else {
                                        notifyInAppError("Error: Could not insert gif into message. Please notify support.");
                                    }

                                    // Close the gif picker
                                    setIsGifPickerActive(false);
                                }}
                            />
                        </div>
                    </Popper>
                    <div className="emoji-picker-container">

                        <SentimentSatisfiedOutlinedIcon
                            className="emoji-picker-icon icon-32 cursor-pointer"
                            onClick={() => handleEmojiPickerStatusFlip()}

                        />


                    </div>
                    {/* Gif integration */}
                    <div className="tenor-gif-picker-container">
                        <GifBoxOutlinedIcon
                            className="tenor-gif-picker-icon icon-32 cursor-pointer"
                            onClick={() => handleGifPickerStatusFlip()}
                        />

                    </div>
                    {/* File upload integration */}

                    <div className="file-upload-container">
                        <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<AttachFile className="emoji-picker-icon icon-32 cursor-pointer" />}
                            disabled={(conversation && userDetailsAsParticipant && isMessageTextAreaEnabled) ? false : true}
                            className={"file-upload-button " + ((conversation && userDetailsAsParticipant && isMessageTextAreaEnabled) ? "" : "disabled")}
                        >
                            {/* Upload files */}
                            <VisuallyHiddenInput
                                ref={refFileUploadInput}
                                type="file"
                                onChange={(event) => {
                                    // Add onto the state if it's not null
                                    console.log("Selected files: ", selectedFiles);
                                    console.log("Attaching files: ", event.target.files);


                                    if (selectedFiles && selectedFiles.length > 0) {
                                        setSelectedFiles(() => {
                                            if (!event.target.files) return null;

                                            let newList = new DataTransfer();
                                            Array.from(selectedFiles).forEach((file) => {
                                                newList.items.add(file);
                                            });
                                            Array.from(event.target.files).forEach((file) => {
                                                newList.items.add(file);
                                            });

                                            return newList.files;
                                        });
                                    } else {
                                        setSelectedFiles(event.target.files);
                                    }
                                }}
                                multiple
                            />
                        </Button>
                    </div>
                    {/* Emoji integration */}


                    <div className="close-picker-container">
                        <RemoveIcon
                            className="close-picker-icon icon-32 cursor-pointer"
                            onClick={() => { setIsExtraActionsActive(!isExtraActionsActive); }}
                        />
                    </div>
                </Popover>

                <div
                    className="main-inputs"
                // style={{
                //     height: selectedFiles && selectedFiles.length > 0 ? "50%" : "100%",
                // }}
                >
                    {(conversation && userDetailsAsParticipant && isMessageTextAreaEnabled) ? (

                        <div className="user-input">

                            <div role="button" className="extra-actions-button" id="" ref={refExtraActionsButton}>
                                <AddIcon
                                    color="primary"
                                    className="extra-actions-icon icon-rounded icon-32 cursor-pointer"
                                    onClick={() => { handleExtraActionsFlip(); }}
                                />


                            </div>

                            <span
                                ref={refNewMessageTextbox}
                                role="textarea"
                                className="message-input"
                                contentEditable
                                data-value={loadedConversationsPreSendMessage.find((loadedConversation) => loadedConversation.conversation_sid === conversation.sid)?.message || ""}
                                autoFocus
                                onInput={(e) => {
                                    e.preventDefault();
                                    // Set the new message
                                    if (e.currentTarget.innerText.length > 1600) {
                                        setNewMessageValidationText("Message must be between 1 and 1600 characters");
                                    } else {
                                        setNewMessageValidationText("");
                                        // Update pre-send message for existing conversation with empty message so it doesn't appear if the user switches back to the conversation
                                        dispatch(reduxSetLoadedConversationsPreSendMessage(loadedConversationsPreSendMessage.map((loadedConversation: { conversation_sid: string, message: string }) => {
                                            if (loadedConversation.conversation_sid === conversation.sid) {
                                                if (refNewMessageTextbox.current && e.currentTarget.innerText === "\n") {
                                                    refNewMessageTextbox.current.innerText = "";
                                                }
                                                return { ...loadedConversation, message: e.currentTarget.innerText };
                                            }
                                            return loadedConversation;
                                        })));
                                    }
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === "Enter" && !e.shiftKey && conversation && userDetailsAsParticipant) {
                                        e.preventDefault();
                                        // Other conditions to check before sending the message
                                        if ((newMessage === "" || newMessage.length === 0 || newMessage.trim() === "") || newMessage.length > 1600 || newMessage === null || newMessage === undefined) {
                                            // Notify user of error and don't send message
                                            if (newMessage === "" || newMessage.length === 0 || newMessage.trim() === "") {
                                                setNewMessageValidationText("Message cannot be empty");
                                                // Update pre-send message for existing conversation with empty message so it doesn't appear if the user switches back to the conversation
                                                dispatch(reduxSetLoadedConversationsPreSendMessage(loadedConversationsPreSendMessage.map((loadedConversation: { conversation_sid: string, message: string }) => {
                                                    if (loadedConversation.conversation_sid === conversation.sid) {
                                                        if (refNewMessageTextbox.current) {
                                                            refNewMessageTextbox.current.innerHTML = "";
                                                        }
                                                        return { ...loadedConversation, message: "" };
                                                    }
                                                    return loadedConversation;
                                                })));
                                            } else if (newMessage.length > 1600) {
                                                setNewMessageValidationText("Message cannot be longer than 1600 characters");
                                            } else {
                                                setNewMessageValidationText("UI Error: Message null or undefined. Please notify support.");
                                            }
                                        } else {
                                            console.log("New message length: ", newMessage.length);

                                            handleCreateNewMessage(conversation.sid, newMessage.trim(), userDetailsAsParticipant.sid, userDetailsAsParticipant.name);
                                            // Update pre-send message for existing conversation with empty message so it doesn't appear if the user switches back to the conversation
                                            dispatch(reduxSetLoadedConversationsPreSendMessage(loadedConversationsPreSendMessage.map((loadedConversation: { conversation_sid: string, message: string }) => {
                                                if (loadedConversation.conversation_sid === conversation.sid) {
                                                    if (refNewMessageTextbox.current) {
                                                        refNewMessageTextbox.current.innerText = "";
                                                    }
                                                    return { ...loadedConversation, message: "" };
                                                }
                                                return loadedConversation;
                                            })));
                                            setNewMessageValidationText("");
                                        }
                                    }
                                }}

                            // TODO: Finish experimental feature below for best user experience
                            // onKeyUp={(e) => {
                            //     if (e.key === 'Enter' && !e.shiftKey && conversation && participant) {
                            //         e.preventDefault();
                            //         if (newMessage === "") {
                            //             return;
                            //             // Do nothing if the message is empty
                            //             // TODO: Add error message
                            //         }
                            //         handleCreateNewMessage(conversation.sid, newMessage, participant.sid, participant.name);
                            //         setNewMessage("");

                            //     // NOTE: If we want complete control over the keys and how they work, we can use the following code. This was
                            //     //       experimental in order to try and have the enter key automatically send the message instead of it acting
                            //     //       like a newline and then the message sending immediately after it.
                            //     } else {
                            //         // Make sure no special keys are being pressed
                            //         if (e.key === 'Enter' || e.key === 'Shift' || e.key === 'Control' || e.key === 'Alt' ||
                            //             e.key === 'Meta' || e.key === 'CapsLock' || e.key === 'Tab' || e.key === 'Escape' ||
                            //             e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' ||
                            //             e.key === 'PageUp' || e.key === 'PageDown' || e.key === 'Home' || e.key === 'End' ||
                            //             e.key === 'Insert' || e.key === 'Delete' || e.key === 'Backspace' || e.key === 'F1' ||
                            //             e.key === 'F2' || e.key === 'F3' || e.key === 'F4' || e.key === 'F5' || e.key === 'F6' ||
                            //             e.key === 'F7' || e.key === 'F8' || e.key === 'F9' || e.key === 'F10' || e.key === 'F11' ||
                            //             e.key === 'F12' || e.key === 'PrintScreen' || e.key === 'ScrollLock' || e.key === 'Pause' ||
                            //             e.key === 'ContextMenu' || e.key === 'NumLock' || e.key === 'Clear' || e.key === 'Help' || e.key === 'a'
                            //         ) {
                            //             e.preventDefault();
                            //             console.log("Target data: ", e.target)
                            //             console.log("Event data: ", e);

                            //             // Backspace functionality
                            //             if (e.key === 'Backspace") {
                            //                 // Check if the message is empty
                            //                 if (newMessage === "") {
                            //                     return;
                            //                 }

                            //                 // TODO: Check if the target is selected. If so, remove the selected text
                            //                 // NOTE: Looks like we may have to implement refs to get selected data off the native textarea element

                            //                 // Remove the last character
                            //                 setNewMessage(newMessage.slice(0, -1));
                            //                 return;
                            //             }
                            //             // Select all functionality
                            //             if (e.key === 'a' && (e.ctrlKey || e.metaKey)) {
                            //                 // Select all text in the message input
                            //                 (e.target as HTMLInputElement).select();
                            //             }
                            //         } else {
                            //             // Set the new message
                            //             setNewMessage(newMessage + e.key);
                            //         }
                            //     }
                            // }}
                            // onKeyDownCapture={(e) => {
                            //     if (e.key === 'Backspace") {
                            //         e.preventDefault();
                            //         // Remove the last character from the message
                            //         setNewMessage((prevMessage) => prevMessage.slice(0, -1));
                            //     }
                            // }}
                            // onPaste={(e) => {
                            //     e.preventDefault();
                            //     // Get the pasted text
                            //     const pastedText = e.clipboardData.getData("text");
                            //     // Set the new message
                            //     setNewMessage(newMessage + pastedText);
                            // }}
                            />
                            <Popover
                                id={isNewMessagePopoverOpen ? 'new-message-popover' : undefined}
                                open={isNewMessagePopoverOpen}
                                anchorEl={refNewMessageTextbox.current}
                                onClose={() => {
                                    setIsNewMessagePopoverOpen(false)
                                    setNewMessageValidationText("");
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                className="new-message-validation-popover"
                            >
                                {newMessageValidationText}
                            </Popover>
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    // Check files
                                    // TODO: Upload any files that may need it. We should first upload to Azure blob, get feedback on success, then move on to sending via Twilio
                                    let azureBlobFileUrls: string[] = [];

                                    if (selectedFiles && selectedFiles.length > 0) {
                                        // Upload files to Azure blob. Return data should include the URL to the file(s) in Azure blob
                                        dispatch(TwilioConversationsRedux.PostNewFiles(userDetailsAsParticipant.sid, selectedFiles)).then((response: any) => {
                                            console.log('TC Files Upload response', response);
                                            if (response.type === twilioConversationsConstants.POST_NEW_FILES_SUCCESS) {
                                                // Upload successful
                                                notifyInAppSuccess("Files uploaded successfully");
                                                setSelectedFiles(null);
                                                // Update file upload ref
                                                if (refFileUploadInput.current) {
                                                    refFileUploadInput.current.value = "";
                                                    refFileUploadInput.current.files = null;
                                                }
                                            } else if (response.type === twilioConversationsConstants.POST_NEW_FILES_FAILURE) {
                                                notifyInAppError("Error: Could not upload files: " + response.error);
                                                notifyInAppError("Error: Could not upload files. Please notify support.");
                                            }
                                        }).catch((error) => {
                                            console.log('new message error', error);
                                            notifyInAppError("Error: Could not upload files: " + error);
                                            notifyInAppError("Error: Could not upload files. Please notify support.");
                                        });

                                        if (newMessage === "" || newMessage.length === 0 || newMessage.trim() === "") {
                                            // User is just trying to upload some files. Don't send a message and just return
                                            return;
                                        }
                                    }

                                    // Other message conditions to check before sending the message
                                    if ((newMessage === "" || newMessage.length === 0 || newMessage.trim() === "") || newMessage.length > 1600 || newMessage === null || newMessage === undefined) {
                                        // Notify user of error and don't send message
                                        if (newMessage === "" || newMessage.length === 0 || newMessage.trim() === "") {
                                            setNewMessageValidationText("Message cannot be empty");
                                            // Update pre-send message for existing conversation with empty message so it doesn't appear if the user switches back to the conversation
                                            dispatch(reduxSetLoadedConversationsPreSendMessage(loadedConversationsPreSendMessage.map((loadedConversation: { conversation_sid: string, message: string }) => {
                                                if (loadedConversation.conversation_sid === conversation.sid) {
                                                    if (refNewMessageTextbox.current) {
                                                        refNewMessageTextbox.current.innerText = "";
                                                    }
                                                    return { ...loadedConversation, message: "" };
                                                }
                                                return loadedConversation;
                                            })));
                                        } else if (newMessage.length > 1600) {
                                            setNewMessageValidationText("Message cannot be longer than 1600 characters");
                                        } else {
                                            setNewMessageValidationText("UI Error: Message null or undefined. Please notify support.");
                                        }
                                    } else {
                                        console.log("New message length: ", newMessage.length);

                                        // Create new message
                                        handleCreateNewMessage(conversation.sid, newMessage, userDetailsAsParticipant.sid, userDetailsAsParticipant.name);

                                        // Update pre-send message for existing conversation with empty message so it doesn't appear if the user switches back to the conversation
                                        dispatch(reduxSetLoadedConversationsPreSendMessage(loadedConversationsPreSendMessage.map((loadedConversation: { conversation_sid: string, message: string }) => {
                                            if (loadedConversation.conversation_sid === conversation.sid) {
                                                if (refNewMessageTextbox.current) {
                                                    refNewMessageTextbox.current.innerHTML = "";
                                                }
                                                return { ...loadedConversation, message: "" };
                                            }
                                            return loadedConversation;
                                        })));
                                        setNewMessageValidationText("");
                                    }
                                }}
                            >
                                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.24854 7.23479L7.26422 2.23249C7.94725 1.55127 9.05275 1.55127 9.73578 2.23249L12.25 4.74L14.7515 7.23479C15.4395 7.92097 15.4367 9.03627 14.7453 9.71904L13.6653 10.7856C12.9837 11.4588 11.8876 11.4588 11.206 10.7856L9.72966 9.3277C9.04806 8.65458 7.95194 8.65458 7.27033 9.3277L5.79402 10.7856C5.11242 11.4588 4.01629 11.4588 3.33469 10.7856L2.25465 9.71904C1.56328 9.03627 1.56053 7.92097 2.24854 7.23479Z" stroke="white" stroke-width="1.5" />
                                </svg>
                            </button>
                        </div>

                    ) : (
                        <div className="user-input">
                            <div className="extra-actions-button disabled">
                                <AddIcon
                                    color="primary"
                                    className="extra-actions-icon  icon-rounded icon-32"
                                />
                            </div>
                            <div style={{ cursor: "not-allowed", width: "calc(100% - 104px)" }}>
                                <span
                                    className="message-input disabled w-100"
                                    role="textarea"
                                    contentEditable
                                />
                            </div>
                            <div style={{ cursor: "not-allowed" }}>
                                <Button
                                    className="btn btn-success disabled"
                                >
                                    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.24854 7.23479L7.26422 2.23249C7.94725 1.55127 9.05275 1.55127 9.73578 2.23249L12.25 4.74L14.7515 7.23479C15.4395 7.92097 15.4367 9.03627 14.7453 9.71904L13.6653 10.7856C12.9837 11.4588 11.8876 11.4588 11.206 10.7856L9.72966 9.3277C9.04806 8.65458 7.95194 8.65458 7.27033 9.3277L5.79402 10.7856C5.11242 11.4588 4.01629 11.4588 3.33469 10.7856L2.25465 9.71904C1.56328 9.03627 1.56053 7.92097 2.24854 7.23479Z" stroke="white" stroke-width="1.5" />
                                    </svg>
                                </Button>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}

export default TwilioMessages;