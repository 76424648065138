import { Route, Routes } from 'react-router';
import Auth from '../src/pages/Login/Login';
import Mainlayout from './layout/MainLayout';
import BlankRoute from './pages/BlankRoute/BlankRoute';
import CDC from './pages/CDC/CDC';
import ClientUser from './pages/ClientUser/ClinetUser';
import ComplianceDashboard from './pages/Compliance/ComplianceDashboard';
import Dashboard from './pages/Dashboard/Dashboard';
import DocuSign from './pages/DocuSign/DocuSign';
import EmailVerification from './pages/EmailVerification/EmailVerification';
import Investment from './pages/Financial/Investment';
import ForgotEmailAddress from './pages/Login/ForgotEmailAddress';
import ForgotPasswordCode from './pages/Login/ForgotPassswordCode';
import ForgotPasswordVerification from './pages/Login/ForgotPasswordVerification';
import LoginCodeVerify from './pages/Login/LoginCodeVerify';
import LoginLayout from './pages/Login/LoginLayout';
import PasswordAndOtp from './pages/Login/PasswordAndOtp';
import PasswordOnly from './pages/Login/PasswordOnly';
import ResetForgotPassword from './pages/Login/ResetForgotPassword';
import ResetPassword from './pages/Login/ResetPassword';
import SendBird from './pages/Messages/Messages';
import NotFound from './pages/NotFound/notfound';
import Notification from './pages/Notification/notification';
import ProfileSetting from './pages/ProfileSetting/ProfileSetting';
import ShareFile from './pages/ShareFile/ShareFile';
import TwilioDashboard from './pages/TwilioConversations/TwilioDashboard';
import UserDeatils from './pages/UserDeatils/UserDeatils';
import { useAppSelectorTs } from './redux/hooksTS';
const AppRoutes = (props) => {
  const user = props;
  const hasComplianceAuth = useAppSelectorTs(state => state.compliance.hasComplianceAuth);

  return (
    <Routes>
      <Route path="/sso/initiate" element={<BlankRoute />} />
      {user.loggedIn !== "false" ? (
        <>
          {/* <Route path="*" element={<NotFound />}></Route> */}
          <Route path="/notfound" element={<NotFound />}></Route>  {/* Utilize previous line after we figure out how to handle branch.io links like https://portal.monetagroup.com/://open?_branch_referrer=XYZZZZZ */}
          <Route path="/dashboard" element={<Mainlayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/dashboard/userdeatils" element={<UserDeatils />} />
            <Route path="/dashboard/share-file" element={<ShareFile />} />
            <Route path="/dashboard/docu-sign" element={<DocuSign />} />
            <Route path="/dashboard/Messages" element={<SendBird />} />
            <Route path="/dashboard/notification" element={<Notification />} />
            {/* <Route path="/dashboard/calendar" element={<CalendarMain />} /> */}
            <Route path="/dashboard/text" element={<TwilioDashboard chatType='text' />} />
            <Route path="/dashboard/chat" element={<TwilioDashboard chatType='chat' />} />
            <Route path="/dashboard/client-user" element={<ClientUser />} />
            <Route path="/dashboard/profile" element={<ProfileSetting />} />
            <Route path="/dashboard/content-distribution-center" element={<CDC />} />
            <Route path="/dashboard/financial" element={<Investment />} />
            {hasComplianceAuth ? <Route path="/dashboard/compliance" element={<ComplianceDashboard />} /> : <Route path="/dashboard/compliance" element={<NotFound />} />}
          </Route>
        </>
      ) : < Route path="/" element={<Auth />} />}
      <Route path="/verify-email" element={<EmailVerification />}></Route>
      <Route path="/" element={<LoginLayout />} >
        <Route index element={<Auth />} />
        <Route path="/forgot-password-verify" element={<ForgotPasswordVerification />} />
        <Route path="/forgot-email" element={<ForgotEmailAddress />} />
        <Route path="/send-otp" element={<PasswordAndOtp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password-code" element={<ForgotPasswordCode />} />
        <Route path="/verify-code" element={<LoginCodeVerify />} />
        <Route path="/login-password" element={<PasswordOnly />} />
        <Route path="/reset-forgot-password" element={<ResetForgotPassword />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
