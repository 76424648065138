import { useEffect, useRef, useState } from 'react';
import { ITwilioConversationsConversationProps, ITwilioConversationsMessageProps } from '../../interfaces/TwilioConversations';
import { useAppDispatchTS, useAppSelectorTs } from '../../redux/hooksTS';
import { convertISOToTime, getInitials } from "../../components/UtilitiesTS";
import TCUtilities from './TwilioConversationUtilities';
import { reduxSetMessageIndex } from '../../redux/slicesTS/TwilioConversationsSlice';
interface SearchConversationMessagesProps {
    userConversation: ITwilioConversationsConversationProps;
    setShowConversationSearch: (show: boolean) => void;
    handlePullCurrentPageOfMessages: (conversation: ITwilioConversationsConversationProps) => void;
    setCurrentConversationMessagesPage: React.Dispatch<React.SetStateAction<number>>;
    setHasUserScrolled: React.Dispatch<React.SetStateAction<boolean>>;
    setMessagesHasNextPage: React.Dispatch<React.SetStateAction<boolean>>;
    //setMessageIndex: React.Dispatch<React.SetStateAction<number>>;
}
interface ISearchResultEntry {
    author: string;
    authorProfileImage: string;
    message: string;
    messageTimestamp: string;
    index: number;
    messageObject: ITwilioConversationsMessageProps;
}
const SearchConversationMessages = ({
    userConversation,
    setShowConversationSearch,
    handlePullCurrentPageOfMessages,
    setCurrentConversationMessagesPage,
    setHasUserScrolled,
    setMessagesHasNextPage,
    //setMessageIndex
}: SearchConversationMessagesProps) => {
    const dispatch = useAppDispatchTS();
    const searchMessagesInputRef = useRef<HTMLInputElement>(null);
    const [searchText, setSearchText] = useState('');
    const [listOfMessages, setListOfMessages] = useState<ITwilioConversationsMessageProps[]>([]);
    const [listOfMessagesFiltered, setListOfMessagesFiltered] = useState<ITwilioConversationsMessageProps[]>([]);
    //const currentUser = useAppSelectorTs(state => state.user.currentUser || null);
    const activeConversation: ITwilioConversationsConversationProps | null = userConversation;
    const [searchTrigger, setSearchTrigger] = useState(false);
    const [state, setState] = useState({
        warnToastShow: false,
        warnMessage: '',
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (activeConversation) {
            // Load messages of active conversation from API and keep pulling messages based on page number and 
            setIsLoading(true);
            const fetchAllMessages = async () => {
                const messagesAndPage = await TCUtilities.getAllMessagesForConversation(dispatch, activeConversation, false);
                console.log('[Mobile]: Setting messages.');

                // Order messages from newest to oldest
                messagesAndPage.messages.sort((a, b) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });

                setListOfMessages(messagesAndPage.messages);
                // Leadership wants the search results to be empty initially
                // setListOfMessagesFiltered(messagesAndPage.messages);
            }

            fetchAllMessages();
            setIsLoading(false);
        }
    }, [activeConversation]);

    useEffect(() => {
        if (!isLoading) {
            if (searchText.length >= 1) {
                searchMessagesApi();
            } else {
                console.log('[Mobile]: Clearing search text and filtered messages.');

                setSearchText('');
                setListOfMessagesFiltered(listOfMessages);
            }
        }
    }, [searchText]);

    useEffect(() => {
        searchMessagesInputRef.current?.focus();
        if (searchTrigger) {
            console.log('Search Message Pull Triggered');
            handlePullCurrentPageOfMessages(userConversation);
            setSearchTrigger(false)
        }
    }, [searchTrigger]);

    const handlePageChange = (pageIndex: number, totalPages: number, messageIndex: number) => {
        dispatch(reduxSetMessageIndex(messageIndex));
        setCurrentConversationMessagesPage(pageIndex + 1);
        
        if (pageIndex === totalPages) {
            setMessagesHasNextPage(false);
        }
        setHasUserScrolled(true);
        setSearchTrigger(true);
        // const scrollTimeout = setTimeout(() => {
        //     const messageElement = document.getElementById(`message-` + messageIndex);
        //     messageElement?.scrollIntoView({ behavior: 'smooth' });
        //     messageElement?.classList.add('highlighted');

        //     const highlightTimeout = setTimeout(() => {
        //     messageElement?.classList.remove('highlighted');
        //     clearTimeout(highlightTimeout);
        //     }, 500);
        // }, 750);

        // return () => clearTimeout(scrollTimeout);
    };

    const searchMessagesApi = async () => {
        let searchQuery = searchText.trim();
        if (searchQuery.length >= 1) {
            let filteredMessages = listOfMessages.filter((message) => {
                let messageText = message.body;
                let searchRegex = new RegExp(searchQuery, 'i');
                return searchRegex.test(messageText);
            });
            console.log('[Mobile]: Setting filtered messages: ', filteredMessages);

            setListOfMessagesFiltered(filteredMessages);
        }
    };

    return (
        <div className='twilio-conversation-search d-flex' style={{ width: '320px' }}>
            <div className='conversation-search-header'>
                <h5 className='conversation-search-title m-0'>Search Conversation</h5>
                <span
                    className='icon-32 cursor-pointer'
                    onClick={() => {
                        setShowConversationSearch(false);
                    }}
                >
                    <em className='icon-x-outline fs-14'></em>
                </span>
            </div>
            <div className='conversation-search-results'>
                <div className="search-area py-12">
                    <input
                        placeholder="Search Conversation..."
                        className="form-control"
                        value={searchText}
                        ref={searchMessagesInputRef}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            if (e.target.value !== "" && listOfMessages) {
                                let searchResultEntries = listOfMessages.filter((message) => {
                                    if (message.author.toLowerCase() === "system") {
                                        return false;
                                    }
                                    // NOTE: We've changed showing numbers in message to showing conversation name as we're assuming most people will
                                    // NOTE: name the conversation after who they're texting. This is also utilized in TwilioMessages for message author.
                                    if (message.author.startsWith('+')) {
                                        message.author = userConversation.friendly_name
                                    }

                                    return message.body.toLowerCase().includes(e.target.value.toLowerCase());
                                });

                                setListOfMessagesFiltered(searchResultEntries);
                            } else {
                                //setListOfMessages([]);
                            }
                        }}
                    />
                    <button className="search-btn icon-20">
                        <em className="icon-search-outline"></em>
                    </button>
                    {searchText !== "" && (
                        <button
                            className="close-search-btn icon-20"
                            onClick={(event) => {
                                setSearchText("");
                                setListOfMessages([]);
                            }}
                        >
                            <em className="icon-x-circle-filled"></em>
                        </button>
                    )}
                </div>
                <div className='search-results'>
                    {listOfMessagesFiltered.length > 0 && searchText.length > 1 ? (
                        listOfMessagesFiltered.map((message: ITwilioConversationsMessageProps, index) => {
                            const result: ISearchResultEntry = {
                                author: message.author,
                                authorProfileImage: userConversation.participants.find(participant => participant.sid === message.participant_sid)?.profile_image || '',
                                message: message.body,
                                messageTimestamp: message.created_at,
                                index: message.index,
                                messageObject: message
                            };
                            return (
                                <div key={index} className='search-result-item' onClick={() => {
                                    var pageIndex = Math.floor((listOfMessages.length - 1 - (listOfMessages.length - 1 - listOfMessages.indexOf(listOfMessages[listOfMessages.length - 1 - result.messageObject.index]))) / 20);
                                    var totalPages = Math.floor((listOfMessages.length - 1) / 20);
                                    handlePageChange(pageIndex, totalPages, message.index);
                                    
                                }}>
                                    <div className='item-image'>
                                        {result.authorProfileImage ? (
                                            <img src={result.authorProfileImage} alt="Profile" />
                                        ) : (
                                            <span className='user-photo-no-image'>
                                                {getInitials(result.author)}
                                            </span>
                                        )}
                                    </div>
                                    <div className='item-details'>
                                        <div className='details-header'>
                                            <h6>{result.author}</h6>
                                            <div className='message-time'>{convertISOToTime(result.messageTimestamp, false, true)}</div>
                                        </div>
                                        <p>{result.message}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className='no-search-results'>
                            No search results found
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchConversationMessages;