// Tanner Fry
// tfry@monetagroup.com
// File contains the overall interface for the compliance integration.

import { Button, Divider, Tab, Tabs, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { Dropdown, MenuProps } from 'antd';
import { ArcElement, Chart, Tooltip as ChartTooltip, Legend } from 'chart.js';
import DOMPurify from "dompurify";
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Tooltip } from 'react-tooltip';
import { ModalGeneralError } from '../../components/GeneralModals';
import { convertISOToTime, copyToClipboard, LoadingSpinner, notifyInAppError, notifyInAppSuccess, SystemMessage } from '../../components/UtilitiesTS';
import { IComplianceAlert, IComplianceAlertContext, IComplianceAlertWithContext, IComplianceLexiconTerm } from '../../interfaces/Compliance';
import { IUserDetails } from '../../interfaces/General';
import { ITwilioConversationsMessageProps } from '../../interfaces/TwilioConversations';
import { ComplianceRedux } from '../../redux/actions/Compliance/Compliance';
import { useAppDispatchTS, useAppSelectorTs } from '../../redux/hooksTS';
import { reduxAddActiveAlertsContext, reduxAddActiveAlertsWithContext, reduxSelectActiveAlerts, reduxSelectActiveAlertsContexts, reduxSelectActiveAlertsWithContexts, reduxSelectTotalMessageCount, reduxSelectUniqueActiveAlerts, reduxSetActiveAlerts, reduxSetActiveAlertsContexts, reduxSetActiveAlertsWithContexts, reduxSetComplianceData, reduxSetLexiconData, reduxSetSelectedActiveAlertWithContext, reduxSetTotalMessageCount } from '../../redux/slicesTS/ComplianceSlice';
import { reduxSelectIsDarkMode } from '../../redux/slicesTS/UsersSlice';
import TCUtilities from '../TwilioConversations/TwilioConversationUtilities';
import UnderConstruction from '../UnderConstruction/UnderConstruction';
import ComplianceHeader from './ComplianceHeader';

interface IComplianceDashboard {
    
}

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    className?: any;
    style?: React.CSSProperties;
}

function CustomTabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ComplianceDashboard = ({
    
}: IComplianceDashboard) => {
    // Setup
    const refComplianceContainer = useRef(null);
    const refComplianceContainerHeader = useRef(null);
    const [complianceTabsHeight, setComplianceTabsHeight] = useState(0);
    const refComplianceTabs = useRef(null);
    const [componentRefreshed, forceComponentRefresh] = useReducer(x => x + 1, 0);
    const [isLoading, setIsLoading] = useState(true);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const dispatch = useAppDispatchTS();
    // Themeing
    const isDarkMode = useAppSelectorTs(reduxSelectIsDarkMode);
    // Modals 
    const [showModalTwilioNewConversation, setShowModalTwilioNewConversation] = useState(false);
    const [showModalTwilioDeleteParticipantFromConversation, setShowModalTwilioDeleteParticipantFromConversation] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [showModalErrorText, setShowModalErrorText] = useState("");
    // Details
    const [userDetails, setUserDetails] = useState<IUserDetails>(
        JSON.parse(localStorage.getItem("userDetails") || "")
    );
    const lexiconData = useAppSelectorTs((state) => state.compliance.lexiconData);
    // Switches
    const [noResponseFromServer, setNoResponseFromServer] = useState(false)
    const soundPlayerRef = useRef<{ play: () => void; pause: () => void }>(null);

    const playSoundDingCorrect = () => {
        if (soundPlayerRef.current) {
            soundPlayerRef.current.play();
        }
    };

    const pauseSoundDingCorrect = () => {
        if (soundPlayerRef.current) {
            soundPlayerRef.current.pause();
        }
    };

    // KPIs
    const allActiveAlerts = useAppSelectorTs(reduxSelectActiveAlerts);
    const allActiveAlertsContexts = useAppSelectorTs(reduxSelectActiveAlertsContexts);
    const allActiveAlertsWithContexts = useAppSelectorTs(reduxSelectActiveAlertsWithContexts);
    const uniqueActiveAlerts = useAppSelectorTs(reduxSelectUniqueActiveAlerts);
    const totalAlertCount = useAppSelectorTs(reduxSelectTotalMessageCount);
    const [percentOfCompliantTexts, setPercentOfCompliantTexts] = useState(0);

    // Alert functionality
    const [activeAlert, setActiveAlert] = useState<IComplianceAlertWithContext | null>(null);
    const [activeAlertMessagesTemp, setActiveAlertMessagesTemp] = useState<ITwilioConversationsMessageProps[]>([]);
    const [currentHoveredAlert, setCurrentHoveredAlert] = useState<IComplianceAlertWithContext | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const alertItemOptions: MenuProps['items'] = [
        {
            key: '1',
            onClick: (e) => {
                if (currentHoveredAlert) {
                    // TODO: Add "archive" functionality, granted we just set is_active to false. Maybe look into archiving functionality (another bool?)
                    // handleArchiveAlert(currentHoveredAlert);
                } else {
                    notifyInAppError("Error: No alert was found to archive.")
                }
            },
            label: 'Archive Alert',
            disabled: true
        },
        {
            key: '2',
            onClick: (e) => {
                if (currentHoveredAlert) {
                    // TODO: Add "delete" functionality, granted we just set is_active to false and is_deleted to true
                    handleAlertDelete(currentHoveredAlert);
                } else {
                    notifyInAppError("Error: No alert found to delete.");
                }
            },
            label: 'Delete Alert',
        }
    ];

    const handleAlertClicked = (alert: IComplianceAlertWithContext) => {
        if (activeAlert && activeAlert.uuid === alert.uuid) {
            dispatch(reduxSetSelectedActiveAlertWithContext(null));
            setActiveAlert(null);
            setActiveAlertMessagesTemp([]);
            setSearchValue('');
        } else {
            dispatch(reduxSetSelectedActiveAlertWithContext(alert));
            setActiveAlert(alert);
            // Set active alert messages temp variable
            console.log("Clicked Alert context messages: ", alert.context.messages);
            // Reorder so that oldest messages are at the top
            let reorderedMessages = [...alert.context.messages].sort((a: any, b: any) => {
                return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
            });
            setActiveAlertMessagesTemp(reorderedMessages);
        }
    }

    const handleAlertDelete = (alert: IComplianceAlertWithContext) => {
        dispatch(ComplianceRedux.DeleteAlert(alert.uuid)).then((response: any) => {
            console.log("Alert deleted: ", response);
            notifyInAppSuccess("Alert deleted successfully.");
            // If deleted alert is the active alert, set it to null
            if (activeAlert && activeAlert.uuid === alert.uuid) {
                dispatch(reduxSetSelectedActiveAlertWithContext(null));
                setActiveAlert(null);
                setActiveAlertMessagesTemp([]);
                setSearchValue('');
            }

            // Update state by removing the current alert
            dispatch(reduxSetActiveAlerts(allActiveAlerts.filter((alertItem: IComplianceAlert) => alertItem.uuid !== alert.uuid)));
            dispatch(reduxSetActiveAlertsWithContexts(allActiveAlertsWithContexts.filter((alertItem: IComplianceAlertWithContext) => alertItem.uuid !== alert.uuid)));
        }).catch((response: any) => {
            console.log("Error deleting alert: ", response.error.error);
            notifyInAppError("Error deleting alert: " + response.error.error);
            // setShowModalError(true);
            // setShowModalErrorText("Error deleting alert.");
        });
    }

    const handleMouseEnterAlertItem = (alert: IComplianceAlertWithContext) => {
        setCurrentHoveredAlert(alert);
    }

    const handleMouseLeaveAlertItem = () => {
        setCurrentHoveredAlert(null);
    }

    // Update percentOfCompliantTexts when uniqueActiveAlerts or totalAlertCount changes
    useEffect(() => {
        // Make sure new values are different from current values
        if (percentOfCompliantTexts !== 100 - (uniqueActiveAlerts.length / totalAlertCount) * 100) {
            console.log("Updating percentOfCompliantTexts: ", 100 - (uniqueActiveAlerts.length / totalAlertCount) * 100);
            
            setPercentOfCompliantTexts(100 - (uniqueActiveAlerts.length / totalAlertCount) * 100);
        }
    }, [uniqueActiveAlerts, totalAlertCount]);

    // Load initial data by building all compliance objects together
    useEffect(() => {
        Chart.register(ArcElement, ChartTooltip, Legend);
        setIsRefreshing(true);

        // Clear contexts and alerts with context
        dispatch(reduxSetActiveAlertsContexts([]));
        dispatch(reduxSetActiveAlertsWithContexts([]));

        // Load lexicon terms
        try {
            dispatch(ComplianceRedux.GetLexiconTerms()).then((response: any) => {
                console.log("Lexicon terms: ", response);
                dispatch(reduxSetLexiconData(response.data));
            }).catch((response: any) => {
                console.log("Error getting lexicon terms: ", response.error.error);
                notifyInAppError("Error getting lexicon terms: " + response.error.error);
            });
        } catch (error) {
            console.log("Error loading lexicon terms: ", error);
            notifyInAppError("Error loading lexicon terms: " + error);
        }

        // TODO: Setup pulling inactive alerts (alerts already read by the user) so we can allow previous alert viewing
        // Load initial compliance data
        // Load initial conversations
        try {
            dispatch(ComplianceRedux.GetAllInitialConversationData()).then((response: any) => {
                console.log("Initial conversation data: ", response);
                dispatch(reduxSetComplianceData(response.data));
                // Load active alerts
                dispatch(ComplianceRedux.GetActiveAlerts()).then((activeAlertsResponse: any) => {
                    console.log("Active alerts: ", activeAlertsResponse);
                    dispatch(reduxSetActiveAlerts(activeAlertsResponse.data));
                    // Load total message count
                    dispatch(ComplianceRedux.GetTotalMessageCount()).then(async (response: any) => {
                        console.log("Total message count: ", response);
                        dispatch(reduxSetTotalMessageCount(response.data));

                        // Load alert contexts
                        // await Promise.all(activeAlertsResponse.data.map(async (alert: IComplianceAlert) => {
                        //     return await dispatch(ComplianceRedux.GetAlertContext(alert.uuid)).then((alertContextResponse: any) => {
                        //         // Order messages by created_at date in descending order
                        //         let newAlertContext: IComplianceAlertContext = {
                        //             alert_message: alert,
                        //             conversation: alertContextResponse.data.conversation,
                        //             participants: alertContextResponse.data.participants,
                        //             messages: alertContextResponse.data.messages.sort((a: any, b: any) => {
                        //                 return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                        //             })
                        //         };

                        //         return newAlertContext;
                        //     });
                        // })).then((alertContextsResponse: IComplianceAlertContext[]) => {
                        //     console.log("Alert contexts: ", alertContextsResponse);
                        //     dispatch(reduxSetActiveAlertsContexts(alertContextsResponse));

                        //     // Create alerts with contexts and set them in the state
                        //     let allActiveAlertsWithContexts = activeAlertsResponse.data.map((alert: IComplianceAlert) => {
                        //         return {
                        //             ...alert,
                        //             context: alertContextsResponse.find((alertContext: IComplianceAlertContext) => alertContext.alert_message.uuid === alert.uuid)
                        //         };
                        //     });
                        //     console.log("All active alerts with contexts: ", allActiveAlertsWithContexts);
                        //     dispatch(reduxSetActiveAlertsWithContexts(allActiveAlertsWithContexts));
                        // }).catch((response: any) => {
                        //     console.log("Error getting alert contexts: ", response.error.error);
                        //     notifyInAppError("Error getting alert contexts: " + response.error.error);
                        // });

                        // NOTE: This code causes https://github.com/reduxjs/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
                        // activeAlertsResponse.data.map(async (alert: IComplianceAlert) => {
                        console.log("Total active alerts: ", activeAlertsResponse.data.length);
                        
                        setIsLoading(false);
                        for (let i = 0; i < activeAlertsResponse.data.length; i++) {
                            const alert: IComplianceAlert = activeAlertsResponse.data[i];
                            if (i > 25 && i % 3 === 0) {
                                // Between every 3 alerts, wait 3 seconds to prevent overloading memory on client
                                await new Promise((resolve) => setTimeout(resolve, 3000));
                            }

                            dispatch(ComplianceRedux.GetAlertContext(alert.uuid)).then((alertContextResponse: any) => {
                                handleUpdatingReduxAlertContexts(alert, alertContextResponse);
                            }).catch((response: any) => {
                                console.log("Error getting alert context: ", response.error);
                                notifyInAppError("Error getting alert context: " + response.error);
                            });
                        }

                        setIsRefreshing(false);  // TODO: Track total requests to send out and set this once all have come back over time.
                        notifyInAppSuccess("Initial compliance data loaded successfully. Loading alerts and their context in the background.");
                    }).catch((response: any) => {
                        console.log("Error getting total message count: ", response);
                        notifyInAppError("Error getting total message count: " + response);
                        setIsRefreshing(false);
                        setIsLoading(false);
                    });
                }).catch((response: any) => {
                    console.log("Error getting active alerts: ", response.error);
                    notifyInAppError("Error getting active alerts: " + response.error);
                    setIsRefreshing(false);
                    setIsLoading(false);
                });
            }).catch((response: any) => {
                console.log("Error getting initial conversation data: ", response.error);
                notifyInAppError("Error getting initial conversation data: " + response.error);
                setIsRefreshing(false);
                setIsLoading(false);
            });
        } catch (error) {
            console.log("Error loading initial compliance data: ", error);
            notifyInAppError("Error loading initial compliance data: " + error);
            setIsRefreshing(false);
            setIsLoading(false);
        }
    }, [componentRefreshed]);

    const handleUpdatingReduxAlertContexts = (alert: IComplianceAlert, alertContextResponse: any) => {
        try {
            // Order messages by created_at date in descending order
            let newAlertContext: IComplianceAlertContext = {
                alert_message: alert,
                conversation: alertContextResponse.data.conversation,
                participants: alertContextResponse.data.participants,
                messages: alertContextResponse.data.messages.sort((a: any, b: any) => {
                    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                })
            };
            
            // Update allActiveAlertsWithContexts
            dispatch(reduxAddActiveAlertsContext(newAlertContext));

            // Create alert with context and add it to the allActiveAlertsWithContexts state
            let newActiveAlertsWithContexts = {
                ...alert,
                context: newAlertContext
            }

            // Update allActiveAlertsWithContexts state
            dispatch(reduxAddActiveAlertsWithContext(newActiveAlertsWithContexts));
        } catch (error) {
            console.log("Error updating redux alert contexts: ", error);
            notifyInAppError("Error updating redux alert contexts: " + error);
        }
    }

    // Tab panel helpers and state
    const [tabPanelValue, setTabPanelValue] = useState(0);

    const handleTabPanelChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabPanelValue(newValue);
    };

    // Helper functions
    interface IReturnFindReplaceLexiconTermInMessage {
        messagePart0: string;
        reactElement: JSX.Element;
        messagePart1: string;
    }
    const findAndReplaceLexiconTermInMessage = (message: string, lexiconTerm: string): IReturnFindReplaceLexiconTermInMessage => {
        // Find the lexicon term in the message and replace it with a highlighted version
        // NOTE: the matching should work with upper and lowercase in the message
        const regex = new RegExp(lexiconTerm, "gi");

        // Break up the message into 3 parts: before the lexicon term, the lexicon term, and after the lexicon term
        const messageParts = message.split(regex);
        // Keep the found lexicon term in its original case
        const originalLexiconTerm = message.match(regex);
        const element =
            <>
                {originalLexiconTerm && <span className='highlighted-lexicon-term'>{originalLexiconTerm}</span>}
            </>
        ;

        // Convert element to string
        // const elementString = DOMPurify.sanitize(renderToString(element));        

        return {
            messagePart0: messageParts[0],
            reactElement: element,
            messagePart1: messageParts[1],
        };
    }

    return (
        <div 
            className='compliance-container h-100 overflow-auto position-relative' 
            ref={refComplianceContainer} 
            style={{
                // Only apply background if dark mode is enabled
                backgroundColor: isDarkMode ? "#15222b" : "",
            }}
        >
            <div 
                className={"compliance-header heading-wrap d-flex align-items-center justify-content-between px-16 px-md-24"} 
                ref={refComplianceContainerHeader}
                style={{
                    // Only apply background if dark mode is enabled
                    backgroundColor: isDarkMode ? "#15222b" : "",
                    height: '77px',
                }}
            >
                <ComplianceHeader refreshFunction={forceComponentRefresh} isRefreshing={isRefreshing} />
            </div>
            {isLoading ? (
                <LoadingSpinner isActive={isLoading} />
            ) : (
                <>
                    <div className='compliance-content d-flex w-100 position-relative' style={{height: 'calc(100% - 77px)'}}>
                        <Box 
                            sx={{ 
                                borderBottom: 1, 
                                borderColor: 'divider',
                                boxShadow: '10px 20px 40px rgba(0, 0, 0, 0.5)',
                            }}
                            ref={refComplianceTabs}
                        >
                            <Tabs 
                                value={tabPanelValue} 
                                onChange={handleTabPanelChange} 
                                aria-label="compliance-tabs" 
                            >
                                <Tab label="Analytics and Alerts" {...a11yProps(0)} />
                                <Tab label="Conversations" {...a11yProps(1)} />
                                <Tab label="Lexicon" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel 
                            value={tabPanelValue} 
                            index={0} 
                            className={'simple-tabpanel compliance-analytics-and-alerts'}
                            style={{

                            }}
                        >
                            <div className='compliance-kpis'>
                                {/* <h5 className='compliance-kpi-title m-0'>KPIs</h5> */}
                                <div className='percent-of-compliant-texts-wrapper'>
                                    <div className='percent' data-tooltip-id='tooltip-percent-of-compliant-texts'>{percentOfCompliantTexts.toFixed(0)}%</div>
                                    <Doughnut
                                        id='chart-percent-of-compliant-texts-1'
                                        about='Compliant ranges'
                                        aria-label='Compliant ranges'
                                        // redraw={true}
                                        data={{
                                            labels: ['Poor', 'Average', 'Good'],
                                            datasets: [{
                                                label: 'Compliant range',
                                                data: [33, 33, 33],
                                                backgroundColor: [
                                                    'rgba(231, 76, 60, 1)',
                                                    'rgba(255, 164, 46, 1)',
                                                    'rgba(46, 204, 113, 1)'
                                                ],
                                                borderColor: [
                                                    'rgba(255, 255, 255 ,1)',
                                                    'rgba(255, 255, 255 ,1)',
                                                    'rgba(255, 255, 255 ,1)'
                                                ],
                                                borderWidth: 5,
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            rotation: -90,
                                            circumference: 180,
                                            cutout: '90%',
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label: function(context) {
                                                            return context.raw + '%';
                                                        }
                                                    }
                                                },
                                                legend: {
                                                    display: false
                                                }
                                            }
                                        }}
                                    />
                                    <Doughnut
                                        id='chart-percent-of-compliant-texts-2'
                                        about='Percent of compliant texts'
                                        aria-label='Percent of compliant texts'
                                        // redraw={true}
                                        data={{
                                            labels: ['Poor', 'Average', 'Good'],
                                            datasets: [{
                                                // NOTE: If the first bar has a value of 88.5 and the second bar has a value of 
                                                // NOTE: 1 and the third bar has a value of 10.5 you will have effectively put 
                                                // NOTE: the second bar at 89%, with a width of 1% (88.5 + 1 + 10.5 = 100).
                                                data: [percentOfCompliantTexts-2, 4, 100-percentOfCompliantTexts-2],
                                                label: 'Percent of compliant texts',
                                                backgroundColor: [
                                                    "rgba(0,0,0,0)",
                                                    "rgba(255,255,255,1)",
                                                    "rgba(0,0,0,0)",
                                                ],
                                                borderColor: [
                                                    'rgba(0, 0, 0 ,0)',
                                                    percentOfCompliantTexts <= 33.33 ? 'rgba(231, 76, 60, 1)' : percentOfCompliantTexts <= 66.66 ? 'rgba(255, 164, 46, 1)' : 'rgba(46, 204, 113, 1)',
                                                    'rgba(0, 0, 0 ,0)'
                                                ],
                                                borderWidth: 3
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            rotation: -90,
                                            circumference: 180,
                                            cutout: '85%',
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label: function(context: any) {
                                                            if (context?.dataIndex === 1) {
                                                                return ' ' + percentOfCompliantTexts.toFixed(2) + '% of messages are compliant';
                                                            } else {
                                                                return '';
                                                            }
                                                        },
                                                        title: function(context: any) {
                                                            // Check which bar is being hovered over
                                                            if (context[0]?.dataIndex === 1) {
                                                                return percentOfCompliantTexts <= 33.33 ? 'Poor' : percentOfCompliantTexts <= 66.66 ? 'Average' : 'Good';
                                                            } else {
                                                                return '';
                                                            }
                                                        },
                                                    },
                                                    filter: function (tooltipItem) { 
                                                        return tooltipItem.dataIndex === 1;
                                                    },

                                                },
                                                legend: {
                                                    display: false
                                                }
                                            }
                                        }}
                                    />
                                    <div 
                                        className='percent' 
                                        data-tooltip-id='tooltip-percent-of-compliant-texts'
                                        // Make transparent to allow for the chart tooltips to show through
                                        style={{
                                            backgroundColor: 'rgba(0,0,0,0)',
                                            color: 'rgba(0,0,0,0)',
                                        }}
                                    >
                                        {percentOfCompliantTexts.toFixed(0)}%
                                    </div>
                                    <Tooltip id='tooltip-percent-of-compliant-texts' place='top'>
                                        <span>Percent of compliant texts</span>
                                    </Tooltip>
                                </div>
                            </div>
                            <Divider 
                                sx={{
                                    borderColor: '#E9EAEE',
                                    opacity: 1,
                                    marginLeft: '-24px',
                                    marginRight: '-24px',
                                }}
                            />
                            <div className='compliance-main'>
                                <div className='compliance-alerts-wrapper'>
                                    {activeAlert ? (
                                        <h5>Selected Alert</h5>
                                    ) : (
                                        <h5>Active Alerts ({allActiveAlerts.length})</h5>
                                    )}
                                    {activeAlert && (
                                        <>
                                            <div className='compliance-active-alert'>
                                                <div 
                                                    className='compliance-alert active'
                                                    onClick={() => handleAlertClicked(activeAlert)}
                                                    onMouseEnter={() => handleMouseEnterAlertItem(activeAlert)}
                                                    onMouseLeave={() => handleMouseLeaveAlertItem()}
                                                >
                                                    <div className='compliance-alert-header'>
                                                        <span className='compliance-alert-title'>
                                                            {/* NOTE: This will need to be modified in the future based on the alert. Since we're only just looking for triggered words based */}
                                                            {/* NOTE: on the lexicon, this is fine for now. */}
                                                            {"Found \"" + activeAlert.alert_message.split('lexicon term: ')[1] + "\" in a text message"}
                                                        </span>
                                                        {currentHoveredAlert && currentHoveredAlert.uuid === activeAlert.uuid ? (
                                                            <div
                                                                className="dropdown"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <Dropdown
                                                                    trigger={["click"]}
                                                                    placement="bottomRight"
                                                                    menu={{
                                                                        items: alertItemOptions,
                                                                    }}
                                                                >
                                                                    <span className="icon-32 cursor-pointer">
                                                                        <em className="icon-dots-vertical-outline"></em>
                                                                    </span>
                                                                </Dropdown>
                                                            </div>
                                                        ) : (
                                                            <div className="message-time">
                                                                {
                                                                    activeAlert.created_at
                                                                    ? "Created at " + convertISOToTime(activeAlert.created_at) + " " + new Date(activeAlert.created_at).toLocaleDateString()
                                                                    : ""
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='compliance-alert-body'>
                                                            <div className='compliance-alert-message'>
                                                                {/* Format the lexicon term in the message body so that it's highlighted */}
                                                                {(() => {
                                                                    const messageParts = findAndReplaceLexiconTermInMessage(
                                                                        activeAlert.context.messages.find((message) => message.uuid === activeAlert.message_uuid)?.body || "", 
                                                                        activeAlert.alert_message.split('lexicon term: ')[1]
                                                                    );

                                                                    return (
                                                                        <>
                                                                            <span>{messageParts.messagePart0}</span>
                                                                            <span className='highlighted-lexicon-term'>{messageParts.reactElement}</span>
                                                                            <span>{messageParts.messagePart1}</span>
                                                                        </>
                                                                    );
                                                                })()}
                                                                {/* {findAndReplaceLexiconTermInMessage(
                                                                    activeAlert.context.messages.find((message) => message.uuid === activeAlert.message_uuid)?.body || "", 
                                                                    activeAlert.alert_message.split('lexicon term: ')[1]
                                                                )} */}
                                                            </div>
                                                            <div className='compliance-alert-actions'>
                                                            
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            <h5>Other Active Alerts ({allActiveAlerts.length - 1})</h5>
                                        </>
                                    )}
                                    <div className='compliance-alerts'>
                                        {allActiveAlertsWithContexts && allActiveAlertsWithContexts.length > 0 ? (
                                            <ul>
                                                {allActiveAlertsWithContexts.map((alert: IComplianceAlertWithContext, index: number) => (
                                                    <li 
                                                        className={'compliance-alert ' + (activeAlert && activeAlert.uuid === alert.uuid ? 'active' : '')}
                                                        onClick={() => handleAlertClicked(alert)}
                                                        onMouseEnter={() => handleMouseEnterAlertItem(alert)}
                                                        onMouseLeave={() => handleMouseLeaveAlertItem()}
                                                        key={index}
                                                    >
                                                        <div className='compliance-alert-header'>
                                                            <span className='compliance-alert-title'>
                                                                {/* NOTE: This will need to be modified in the future based on the alert. Since we're only just looking for triggered words based */}
                                                                {/* NOTE: on the lexicon, this is fine for now. */}
                                                                {"Found \"" + alert.alert_message.split('lexicon term: ')[1] + "\" in a text message"}
                                                            </span>
                                                            {currentHoveredAlert && currentHoveredAlert.uuid === alert.uuid ? (
                                                                <div
                                                                    className="dropdown"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <Dropdown
                                                                        trigger={["click"]}
                                                                        placement="bottomRight"
                                                                        menu={{
                                                                            items: alertItemOptions,
                                                                        }}
                                                                    >
                                                                        <span className="icon-32 cursor-pointer">
                                                                            <em className="icon-dots-vertical-outline"></em>
                                                                        </span>
                                                                    </Dropdown>
                                                                </div>
                                                            ) : (
                                                                <div className="message-time">
                                                                    {
                                                                        alert.created_at
                                                                        ? "Created at " + convertISOToTime(alert.created_at) + " " + new Date(alert.created_at).toLocaleDateString()
                                                                        : ""
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='compliance-alert-body'>
                                                            <div className='compliance-alert-message'>
                                                                {/* Format the lexicon term in the message body so that it's highlighted */}
                                                                {(() => {
                                                                    const messageParts = findAndReplaceLexiconTermInMessage(
                                                                        alert.context.messages.find((message) => message.uuid === alert.message_uuid)?.body || "", 
                                                                        alert.alert_message.split('lexicon term: ')[1]
                                                                    );

                                                                    return (
                                                                        <>
                                                                            <span>{messageParts.messagePart0}</span>
                                                                            <span className='highlighted-lexicon-term'>{messageParts.reactElement}</span>
                                                                            <span>{messageParts.messagePart1}</span>
                                                                        </>
                                                                    );
                                                                })()}
                                                            </div>
                                                            <div className='compliance-alert-actions'>
                                                            
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <span>No active alerts found</span>
                                        )}
                                    </div>
                                </div>
                                <div className='compliance-alert-context-wrapper'>
                                    <h5>
                                        Alert Context
                                        <TextField
                                            id="compliance-alert-context-search outlined-basic"
                                            label={activeAlert ? "Search alert messages" : "Select an alert to filter messages"}
                                            variant="outlined"
                                            size="small"
                                            className={"compliance-alert-context-search" + (searchValue ? " has-search-value" : "")}
                                            onChange={(e) => {
                                                // Filter the activeAlertMessagesTemp by the search value
                                                if (activeAlert) {
                                                    setSearchValue(e.target.value);
                                                    setActiveAlertMessagesTemp(
                                                        activeAlert.context.messages.filter((message) => {
                                                            return message.body.toLowerCase().includes(e.target.value.toLowerCase());
                                                        })
                                                    );
                                                }
                                            }}
                                            disabled={!activeAlert}
                                        />
                                    </h5>
                                    <div className='compliance-alert-context'>
                                        {activeAlert && activeAlert.uuid ? (
                                            <>
                                                <div className='compliance-alert-context-header'>
                                                    <h6 className='conversation-name'>
                                                        Conversation Name: {activeAlert.context.conversation.friendly_name}
                                                    </h6>
                                                </div>
                                                <div className='compliance-alert-context-body'>
                                                    <div className='compliance-alert-context-messages'>
                                                        <h6>Messages</h6>
                                                        <ul>
                                                            {activeAlertMessagesTemp.map((message: ITwilioConversationsMessageProps, index: number) => {
                                                                if (!activeAlertMessagesTemp) return null;
                                                                const previousMessage = index > 0 ? activeAlertMessagesTemp[index - 1] : undefined;
                                                                
                                                                const dateSeparator = TCUtilities.getDateSeparator(message, previousMessage);

                                                                return (
                                                                    <li key={index}>
                                                                        {dateSeparator && (
                                                                            <div className='date-separator'>
                                                                                <div className='line'></div>
                                                                                <span>{dateSeparator}</span>
                                                                                <div className='line'></div>
                                                                            </div>
                                                                        )}
                                                                        {message.author === 'system' ? (
                                                                            <SystemMessage message={message.body} />
                                                                        ) : (
                                                                            <div 
                                                                                // Highlight the message if it's the one that triggered the alert
                                                                                // Also, add 'advisor-user' if the message.author is not a phone number (Ex: +11234567890)
                                                                                className={
                                                                                    'compliance-alert-context-message ' 
                                                                                    + (message.uuid === activeAlert.message_uuid ? 'highlighted' : '')
                                                                                    + (!message.author.startsWith('+') ? ' advisor-user' : '')
                                                                                }
                                                                            >
                                                                                <div className='compliance-alert-context-message-details'>
                                                                                    <div className='compliance-alert-context-message-author'>
                                                                                        <h6>
                                                                                            {message.author.startsWith('+') ? (
                                                                                                TCUtilities.formatPhoneNumber(message.author)
                                                                                            ) : (
                                                                                                message.author
                                                                                            )}
                                                                                        </h6>
                                                                                        {!message.author.startsWith('+') && message.attributes.text_user_opt_in_at_time_of_message === false && (
                                                                                            <div className="message-not-opted-in">
                                                                                                Not opted in to conversation
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className='compliance-alert-context-message-content'>
                                                                                        {!message.author.startsWith('+') && (
                                                                                            <span className='compliance-alert-context-message-timestamp'>
                                                                                                {/* MC-415 - Change from date to time
                                                                                                {TCUtilities.getMessageTime(message.created_at, {month: "short", day: "numeric", year: "numeric"})}
                                                                                                */}
                                                                                                <div className="message-time">
                                                                                                    {/* Always created_at, modified_at should not be used */}
                                                                                                    {convertISOToTime(message.created_at)}
                                                                                                </div>
                                                                                            </span>
                                                                                        )}
                                                                                        <div className='compliance-alert-context-message-body-wrapper'>
                                                                                            {/* If body is an empty string and media is not, insert media */}
                                                                                            {/* NOTE: This is for twilio created images and media which usually have the body empty */}
                                                                                            {message.body === "" && message.media && message.media.length > 0 && typeof message.media === "object" && (
                                                                                                <div className="message-sms-media">
                                                                                                    {message.media.map((media, index) => {
                                                                                                        if (media.content_type.includes("image")) {
                                                                                                            return (
                                                                                                                <div key={index} className="message-sms-media-item">
                                                                                                                    {/* Example: https://devmonetabesa.blob.core.windows.net/public-media/tc-files/xyz */}
                                                                                                                    <a href={media.url} target="_blank" rel="noreferrer">
                                                                                                                        <img src={media.url} alt={media.filename} />
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            );
                                                                                                        } else if (media.content_type.includes("video")) {
                                                                                                            return (
                                                                                                                <div key={index} className="message-sms-media-item">
                                                                                                                    <video controls>
                                                                                                                        <source src={media.url} type={media.content_type} />
                                                                                                                    </video>
                                                                                                                </div>
                                                                                                            );
                                                                                                        } else if (media.content_type.includes("audio")) {
                                                                                                            return (
                                                                                                                <div key={index} className="message-sms-media-item">
                                                                                                                    <audio controls>
                                                                                                                        <source src={media.url} type={media.content_type} />
                                                                                                                    </audio>
                                                                                                                    <br/>
                                                                                                                    <a href={media.url} target="_blank" rel="noreferrer">
                                                                                                                        {media.filename}
                                                                                                                    </a>
                                                                                                                    {media.content_type == "audio/amr" && (
                                                                                                                        <>
                                                                                                                            <br/>
                                                                                                                            <span >Note: Apple audio is currently not playable in the browser.</span>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            );
                                                                                                        } else if (media.content_type.includes("application/pdf")) {
                                                                                                            return (
                                                                                                                <div key={index} className="message-sms-media-item">
                                                                                                                    <a href={media.url} target="_blank" rel="noreferrer">
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"/></svg>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            );
                                                                                                        } else {
                                                                                                            return (
                                                                                                                <div key={index} className="message-sms-media-item">
                                                                                                                    <span>Unsupported media:</span><br/>
                                                                                                                    <a href={media.url} target="_blank" rel="noreferrer">
                                                                                                                        {media.filename}
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            );
                                                                                                        }
                                                                                                    })}
                                                                                                </div>
                                                                                            )}
                                                                                            {/* If body is not an empty string, insert body */}
                                                                                            {/* NOTE: This also works with multimedia we create as we set the media data and the body to be the media URL of our content */}
                                                                                            <div 
                                                                                                className="compliance-alert-context-message-body"
                                                                                                // Sanitize and preserve special characters in Twilio message with HTML elements. 
                                                                                                // Also, allow us to modify the message body to add target="_blank" to links.
                                                                                                // dangerouslySetInnerHTML={{
                                                                                                //     __html: TCUtilities.performTcMessageModifications(DOMPurify.sanitize(
                                                                                                //         TCUtilities.preserveSpecialCharactersInTwilioMessageWithHTMLElements(
                                                                                                //             findAndReplaceLexiconTermInMessage(message.body, activeAlert.alert_message.split('lexicon term: ')[1])
                                                                                                //         )
                                                                                                //     ))
                                                                                                // }}
                                                                                            >
                                                                                                {(() => {
                                                                                                    let messageParts: IReturnFindReplaceLexiconTermInMessage = {
                                                                                                        messagePart0: "",
                                                                                                        reactElement: <></>,
                                                                                                        messagePart1: "",
                                                                                                    };
                                                                                                    if (message.uuid === activeAlert.message_uuid) {
                                                                                                        messageParts = findAndReplaceLexiconTermInMessage(
                                                                                                            message.body,
                                                                                                            activeAlert.alert_message.split('lexicon term: ')[1]
                                                                                                        );
                                                                                                    } else {
                                                                                                        messageParts = {
                                                                                                            messagePart0: message.body,
                                                                                                            reactElement: <></>,
                                                                                                            messagePart1: "",
                                                                                                        };
                                                                                                    }

                                                                                                    return (
                                                                                                        <>
                                                                                                            <span
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html: TCUtilities.performTcMessageModifications(DOMPurify.sanitize(
                                                                                                                        TCUtilities.preserveSpecialCharactersInTwilioMessageWithHTMLElements(
                                                                                                                            messageParts.messagePart0
                                                                                                                        )
                                                                                                                    ))
                                                                                                                }}
                                                                                                            />
                                                                                                            <span className='highlighted-lexicon-term'>{messageParts.reactElement}</span>
                                                                                                            <span
                                                                                                                dangerouslySetInnerHTML={{
                                                                                                                    __html: TCUtilities.performTcMessageModifications(DOMPurify.sanitize(
                                                                                                                        TCUtilities.preserveSpecialCharactersInTwilioMessageWithHTMLElements(
                                                                                                                            messageParts.messagePart1
                                                                                                                        )
                                                                                                                    ))
                                                                                                                }}
                                                                                                            />
                                                                                                        </>
                                                                                                    );
                                                                                                })()}
                                                                                            </div>
                                                                                        </div>
                                                                                        {message.author.startsWith('+') && (
                                                                                            <span className='compliance-alert-context-message-timestamp'>
                                                                                                {/* MC-415 - Change from date to time
                                                                                                {TCUtilities.getMessageTime(message.created_at, {month: "short", day: "numeric", year: "numeric"})}
                                                                                                */}
                                                                                                <div className="message-time">
                                                                                                    {/* Always created_at, modified_at should not be used */}
                                                                                                    {convertISOToTime(message.created_at)}
                                                                                                </div>
                                                                                            </span>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div> 
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className='compliance-alert-context-header'>
                                                    <span className='compliance-alert-context-title'>
                                                        No alert selected
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel 
                            value={tabPanelValue} 
                            index={1} 
                            className={'simple-tabpanel compliance-conversations'}
                            style={{
                                
                            }}
                        >
                            <UnderConstruction />
                            {/* TODO: Employee list that shows conversations */}
                            {/* TODO: Search functionality that works with conversation name, participant name, and messages in the conversation */}
                            {/* TODO: Could also add badges to the conversation to indicate the alerts per conversation/employee */}
                            {/* <span>Conversations</span> */}
                        </CustomTabPanel>
                        <CustomTabPanel 
                            value={tabPanelValue} 
                            index={2} 
                            className={'simple-tabpanel compliance-lexicon'}
                            style={{
                                
                            }}
                        >
                            {/* CRUD buttons */}
                            <div className='lexicon-header'>
                                <Button 
                                    className='lexicon-term-create-button disabled'
                                    variant='contained' 
                                    color='primary' 
                                    // onClick={() => setShowModalAddLexiconTerm(true)}
                                    disabled={true}
                                >
                                    Add Term
                                </Button>
                            </div>
                            {/* Term grid */}
                            {lexiconData && lexiconData.length > 0 ? (
                                <>
                                    {(() => {
                                        let currentLetter = '';

                                        return (
                                            <div className='lexicon-grid'>
                                                {lexiconData.map((lexiconTerm: IComplianceLexiconTerm, index: number) => (
                                                    <>
                                                        {/* Separate the terms by letter */}
                                                        {(() => {
                                                            // Check if the first letter of the term is different from the current letter
                                                            if (lexiconTerm.term.charAt(0).toUpperCase() !== currentLetter) {
                                                                currentLetter = lexiconTerm.term.charAt(0).toUpperCase();
                                                                return (
                                                                    <h6 key={currentLetter} className='lexicon-letter'>{currentLetter}</h6>
                                                                );
                                                            }
                                                        })()}
                                                        <div 
                                                            key={index} 
                                                            className='lexicon-term' 
                                                            onClick={async () => {
                                                                await copyToClipboard(lexiconTerm.term);
                                                            }}
                                                        >
                                                            <span>{lexiconTerm.term}</span>
                                                            <span>{lexiconTerm.definition}</span>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        )
                                    })()}
                                </>
                            ) : (
                                <span>No lexicon terms found</span>
                            )}
                        </CustomTabPanel>
                    </div>
                    <ModalGeneralError 
                        isShowing={showModalError}
                        setIsShowing={setShowModalError}
                        errorMessage={showModalErrorText}
                        setErrorMessage={setShowModalErrorText}
                    />
                    {/* TODO: Figure out why soundplayer is pulling a large amount of requests when active */}
                    {/* <SoundPlayer ref={soundPlayerRef} url={dingCorrect} /> */}
                </>
            )}
        </div>
    );
}

export default ComplianceDashboard;