import { Dispatch } from 'redux';
import axiosInstance from '../../../config/axios';
import { IArticle } from '../../../interfaces/General';
import { articleConstants } from '../../constants/ArticleConstant';

export const dashboard = {
    getTopics,
    getArticles,
    getTasks,
    retrieveArticle,
    getRelatedArticles,
    getBookmarkedArticles,
    handleBookmarkStatusBookmarkedArticle,
    handleReadStatusBookmarkedArticle,
    handleRelatedReadStatus,
    handleBookmarkRelatedArticles,
    handleReadStatusCommonArticle,
    handleBookmarkStatusCommonArticle,
    handleBookmarkStatusSingleArticle,
    handleReadStatusSingleArticle,
    getAdvisors,
    getmyclient,
    updatetask,
    getHousehold,
    getAdvisoryList,
    updatestatus,
    sharearticle,
    getArticleClientList,
    getTeams,
    getRefreshTime,
    postArticleLinkedClicked,
};

interface Payload {
    is_read?: boolean;
    is_bookmark?: boolean;
}

function getTopics(topic_url: string) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get(topic_url)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if ((error as any).response) {
                    if (error && typeof error === 'object' && 'response' in error) {
                        return dispatch(failure((error as any).response.data));
                    }
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_TOPIC_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_TOPIC_FAILURE, error };
    }
}

function retrieveArticle(uuid: string) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get(`article/${uuid}`)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error && typeof error === 'object' && 'response' in error) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.RETRIEVE_ARTICLE_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.RETRIEVE_ARTICLE_FAILURE, error };
    }
}

function getRelatedArticles(uuid: string) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get(`article/${uuid}/related`)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error && typeof error === 'object' && 'response' in error) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_RELATED_ARTICLE_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_RELATED_ARTICLE_FAILURE, error };
    }
}

function getBookmarkedArticles(bookmark_url: string) {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosInstance.get(bookmark_url);
            if (response?.data) {
                return dispatch(success(response.data));
            }
        } catch (error) {
            if ((error as any)?.response?.status === 401 || (error as any)?.response?.status === 403) {
                window.location.assign('/');
                localStorage.clear();
                sessionStorage.clear();
            }
            if (error && typeof error === 'object' && 'response' in error) {
                return dispatch(failure((error as any)?.response.data));
            }
        }
    };

    function success(data: any) {
        return { type: articleConstants.GET_BOOKMARKED_ARTICLE_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_BOOKMARKED_ARTICLE_FAILURE, error };
    }
}

function handleBookmarkStatusBookmarkedArticle(article: IArticle, payload: Payload) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: articleConstants.UPDATE_BOOKMARK_STATUS_SUCCESS,
            data: { article: article, is_bookmark: payload.is_bookmark }
        });
        return await axiosInstance
            .post(`article/${article.uuid}/action`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign('/');
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error && typeof error === 'object' && 'response' in error) {
                    return dispatch(failure((error as any).response.data));
                }
            });

        function failure(data: any) {
            return { type: articleConstants.UPDATE_BOOKMARK_STATUS_FAILURE, data };
        }
    };
}

function handleReadStatusBookmarkedArticle(article: IArticle, payload: Payload) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: articleConstants.UPDATE_BOOKMARKED_READ_STATUS_SUCCESS, data: { article: article, is_read: payload.is_read } });
        return await axiosInstance
            .post(`article/${article.uuid}/action`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign('/');
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });

        function failure(data: any) {
            return { type: articleConstants.UPDATE_BOOKMARKED_READ_STATUS_FAILURE, data };
        }
    };
}

function handleReadStatusCommonArticle(article: IArticle, payload: Payload) {
    return async (dispatch: Dispatch) => {
        dispatch({ type: articleConstants.UPDATE_COMMON_ARTICLE_READ_STATUS_SUCCESS, data: { article: article, is_read: payload.is_read } });
        return await axiosInstance
            .post(`article/${article.uuid}/action`, payload)
            .then((response) => {
                console.log(response, "response");
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign('/');
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });

        function failure(data: any) {
            return { type: articleConstants.UPDATE_COMMON_ARTICLE_READ_STATUS_FAILURE, data };
        }
    };
}

function handleRelatedReadStatus(article: IArticle, payload: Payload) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: articleConstants.UPDATE_RELATED_READ_STATUS,
            data: { article: article, is_read: payload.is_read }
        });
        return await axiosInstance
            .post(`article/${article.uuid}/action`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign('/');
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });

        function failure(data: any) {
            return { type: articleConstants.UPDATE_RELATED_READ_STATUS_FAILURE, data };
        }
    };
}

function getArticles(article_url: string) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get(article_url)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/dashboard");
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_ARTICLE_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_ARTICLE_FAILURE, error };
    }
}

function handleBookmarkRelatedArticles(article: IArticle, payload: Payload) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: articleConstants.UPDATE_BOOKMARK_RELATED_STATUS_SUCCESS,
            data: { article: article, is_bookmark: payload.is_bookmark }
        });
        return await axiosInstance
            .post(`article/${article.uuid}/action`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign('/');
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });

        function failure(data: any) {
            return { type: articleConstants.UPDATE_BOOKMARK_RELATED_STATUS_FAILURE, data };
        }
    };
}

function handleBookmarkStatusCommonArticle(article: IArticle, payload: Payload) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: articleConstants.UPDATE_BOOKMARK_STATUS_COMMON_ARTICLE_SUCCESS,
            data: { article: article, is_bookmark: payload.is_bookmark }
        });
        return await axiosInstance
            .post(`article/${article.uuid}/action`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign('/');
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });

        function failure(data: any) {
            return { type: articleConstants.UPDATE_BOOKMARK_STATUS_COMMON_ARTICLE_FAILURE, data };
        }
    };
}

function handleBookmarkStatusSingleArticle(article: IArticle, payload: Payload) {
    console.log(payload.is_bookmark)
    return async (dispatch: Dispatch) => {
        dispatch({
            type: articleConstants.UPDATE_BOOKMARK_STATUS_SINGLE_ARTICLE_SUCCESS,
            data: { article: article, is_bookmark: payload.is_bookmark }
        });
        return await axiosInstance
            .post(`article/${article.uuid}/action`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign('/');
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });

        function failure(data: any) {
            return { type: articleConstants.UPDATE_BOOKMARK_STATUS_SINGLE_ARTICLE_FAILURE, data };
        }
    };
}

function handleReadStatusSingleArticle(article: IArticle, payload: Payload) {
    return async (dispatch: Dispatch) => {
        dispatch({
            type: articleConstants.UPDATE_READ_STATUS_SINGLE_ARTICLE_SUCCESS,
            data: { article: article, is_read: payload.is_read }
        });
        return await axiosInstance
            .post(`article/${article.uuid}/action`, payload)
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign('/');
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });

        function failure(data: any) {
            return { type: articleConstants.UPDATE_READ_STATUS_SINGLE_ARTICLE_FAILURE, data };
        }
    };
}

function getTasks(task_url: string) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get(task_url)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_TASK_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_TASK_FAILURE, error };
    }
}

function getAdvisors() {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get("user/advisory_team")
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_ADVISOR_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_ADVISOR_FAILURE, error };
    }
}

function getmyclient() {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get("advisoryclientlist")
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_MYCLIENT_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_MYCLIENT_FAILURE, error };
    }
}

function updatetask(update_task_url: string, payload: any) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .put(update_task_url, payload)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.UPDATE_TASK_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.UPDATE_TASK_FAILURE, error };
    }
}

function getHousehold(household_url: string) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get(household_url)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_HOUSEHOLD_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_HOUSEHOLD_FAILURE, error };
    }
}

function getAdvisoryList() {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get("advisoryclientlist/advisorylist")
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_ADVISORYLIST_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_ADVISORYLIST_FAILURE, error };
    }
}

function updatestatus(update_task_url: string, payload: any) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .put(update_task_url, payload)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_PUBLISH_UPDATE_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_PUBLISH_UPDATE_FAILURE, error };
    }
}

function sharearticle(article_uuid: string) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .put("share-article-count/" + article_uuid)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_ARTICLE_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_ARTICLE_FAILURE, error };
    }
}

function getArticleClientList(article_uuid: string) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get(`article/${article_uuid}/clientlist`)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_ARTICLECLIENTLIST_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_ARTICLECLIENTLIST_FAILURE, error };
    }
}

function getTeams() {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get("user/team")
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_TEAM_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_TEAM_FAILURE, error };
    }
}

function getRefreshTime() {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .get("screen-refresh-time/get-refresh-time")
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.GET_REFRESHTIME_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.GET_REFRESHTIME_FAILURE, error };
    }
}

function postArticleLinkedClicked(article_uuid: object) {
    return (dispatch: Dispatch) => {
        return axiosInstance
            .post(`article/${article_uuid}/link-clicked`)
            .then((response) => {
                return dispatch(success(response.data));
            })
            .catch((error) => {
                if (
                    error?.response?.status === 401 ||
                    error?.response?.status === 403
                ) {
                    window.location.assign("/");
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return dispatch(failure(error.response.data));
                }
            });
    };

    function success(data: any) {
        return { type: articleConstants.POST_ARTICLE_LINKED_CLICKED_SUCCESS, data };
    }

    function failure(error: any) {
        return { type: articleConstants.POST_ARTICLE_LINKED_CLICKED_FAILURE, error };
    }
}
