import 'antd/dist/antd.min.css';
import './Assets/Css/style.scss';
import './custom.js';
import './data-dog';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { ContextStore, store } from './redux/store';
import { ContextStoreTS, storeTS } from './redux/storeTS.tsx';

const container = document.getElementById('root');
const root = createRoot(container);

// MUI Theme
import { createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#41BA77',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#000000',
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store} context={ContextStore}>
      <Provider store={storeTS} context={ContextStoreTS}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </Provider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);
