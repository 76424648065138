import { createSlice } from '@reduxjs/toolkit';
import { ITwilioConversationsConversationProps } from '../../interfaces/TwilioConversations';
import { RootStateTS } from '../storeTS';
interface IAdvisorList {
    data: any[];
}

export interface ITwilioConversationsSlice {
    userConversations: ITwilioConversationsConversationProps[]
    activeConversation: ITwilioConversationsConversationProps | null
    messageIndex: number
    scrollToMessage: number
    advisorList: {
        data: {
            docusign_data: {

            },
            email: string,
            first_name: string,
            household: number,
            household_sf_id: string,
            international_calling_codes: string,
            is_active: boolean,
            is_selected: boolean,
            last_name: string,
            phone_number: string,
            profile_image: string,
            sendbird_user_id: string,
            twilio_conversations_user_identity: string,
            twilio_conversations_user_token: string,
            user_id: string,
            user_type: string,
            uuid: string,
        }[]
    };
    loadedConversationsPreSendMessage: {
        conversation_sid: string,
        message: string,
    }[]
}

const initialState: ITwilioConversationsSlice = {
    userConversations: [],
    activeConversation: null,
    messageIndex: -1,
    scrollToMessage: -1,
    advisorList: {
        data: []
    },
    loadedConversationsPreSendMessage: []
};

// @ts-ignore
const twilioConversationsSlice = createSlice({
    name: 'twilioConversationsSlice',
    initialState,
    reducers: {
        reduxSetAdvisorList: (state, action) => {
            state.advisorList = action.payload;
        },
        reduxSetLoadedConversationsPreSendMessage: (state, action) => {
            state.loadedConversationsPreSendMessage = action.payload;
        },
        reduxSetUserConversations: (state, action) => {
            state.userConversations = action.payload;
        },
        reduxSetMessageIndex: (state, action) => {
            state.messageIndex = action.payload;
        },
        reduxSetScrollToMessage: (state, action) => {
            state.scrollToMessage = action.payload;
        },
        reduxSetActiveConversation: (state, action) => {
            state.activeConversation = action.payload;
        },
        reduxSetActiveConversationMessages: (state, action) => {
            state.activeConversation && (state.activeConversation.messages = action.payload.messages);
        },
    },
});

export const {
    reduxSetAdvisorList,
    reduxSetLoadedConversationsPreSendMessage,
    reduxSetUserConversations,
    reduxSetMessageIndex,
    reduxSetScrollToMessage,
    reduxSetActiveConversation,
    reduxSetActiveConversationMessages
} = twilioConversationsSlice.actions;
export const reduxUserConversations = (state: RootStateTS) => state.twilioConversations.userConversations;
export const reduxActiveConversation = (state: RootStateTS) => state.twilioConversations.activeConversation;
export const reduxMessageIndex = (state: RootStateTS) => state.twilioConversations.messageIndex;
export const reduxScrollToMessage = (state: RootStateTS) => state.twilioConversations.scrollToMessage;
export const reduxActiveConversationMessages = (state: RootStateTS) => state.twilioConversations.activeConversation;
export default twilioConversationsSlice.reducer;