import { createSlice } from '@reduxjs/toolkit';
import { IComplianceAlert, IComplianceAlertContext, IComplianceAlertWithContext, IComplianceLexiconTerm } from '../../interfaces/Compliance';
import { RootStateTS } from '../storeTS';

export interface IComplianceSlice {
    activeAlerts: IComplianceAlert[];
    activeAlertsContexts: IComplianceAlertContext[];
    activeAlertsWithContexts: IComplianceAlertWithContext[];  // Built from activeAlerts and activeAlertsContexts
    selectedActiveAlertWithContext: IComplianceAlertWithContext | null;
    totalMessageCount: number;
    complianceData: any[];
    lexiconData: IComplianceLexiconTerm[];
    hasComplianceAuth: boolean;
    usersWhoCanAttemptCompliance: string[];
}

const initialState: IComplianceSlice = {
    activeAlerts: [],
    activeAlertsContexts: [],
    activeAlertsWithContexts: [],
    selectedActiveAlertWithContext: null,
    totalMessageCount: 0,
    complianceData: [],
    lexiconData: [],
    hasComplianceAuth: false,
    usersWhoCanAttemptCompliance: [
        "tfry@monetagroup.com",
        "bwoodruff@monetagroup.com",
        "kblankenship@monetagroup.com",
        "akruger@monetagroup.com",
        "gmurray@monetagroup.com",
        "khicks@monetagroup.com",
        "jbyrum@monetagroup.com",
    ],
};

// @ts-ignore
const complianceSlice = createSlice({
    name: 'complianceSlice',
    initialState,
    reducers: {
        reduxSetActiveAlerts: (state, action) => {
            state.activeAlerts = action.payload;
        },
        reduxSetActiveAlertsContexts: (state, action) => {
            state.activeAlertsContexts = action.payload;
        },
        reduxAddActiveAlertsContext: (state, action) => {
            state.activeAlertsContexts.push(action.payload);
        },
        reduxSetActiveAlertsWithContexts: (state, action) => {
            state.activeAlertsWithContexts = action.payload;
        },
        reduxAddActiveAlertsWithContext: (state, action) => {
            state.activeAlertsWithContexts.push(action.payload);
        },
        reduxSetSelectedActiveAlertWithContext: (state, action) => {
            state.selectedActiveAlertWithContext = action.payload;
        },
        reduxSetTotalMessageCount: (state, action) => {
            state.totalMessageCount = action.payload;
        },
        reduxSetComplianceData: (state, action) => {
            state.complianceData = action.payload;
        },
        reduxSetLexiconData: (state, action) => {
            state.lexiconData = action.payload;
        },
        reduxSetHasComplianceAuth: (state, action) => {
            state.hasComplianceAuth = action.payload;
        },
    },
});

export const { 
    reduxSetComplianceData, 
    reduxSetLexiconData,
    reduxSetHasComplianceAuth, 
    reduxSetActiveAlerts, 
    reduxSetActiveAlertsContexts, 
    reduxAddActiveAlertsContext,
    reduxSetActiveAlertsWithContexts, 
    reduxAddActiveAlertsWithContext,
    reduxSetSelectedActiveAlertWithContext, 
    reduxSetTotalMessageCount 
} = complianceSlice.actions;

export const reduxSelectActiveAlerts = (state: RootStateTS) => {
    // Sort active alerts by created_at date in descending order
    if (!state.compliance.activeAlerts) return [];

    // Do not directly mutate the state. Spread instead
    let sortedAlerts = [...state.compliance.activeAlerts].sort((a, b) => {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });

    return sortedAlerts;
}

export const reduxSelectUniqueActiveAlerts = (state: RootStateTS) => {
    // Go through active alerts and return only unique alerts based on message_uuid
    const uniqueAlerts: any[] = [];
    state.compliance.activeAlerts.forEach((alert) => {
        if (!uniqueAlerts.some((uniqueAlert) => uniqueAlert.message_uuid === alert.message_uuid)) {
            uniqueAlerts.push(alert);
        }
    });

    return uniqueAlerts;
}

export const reduxSelectActiveAlertsContexts = (state: RootStateTS) => state.compliance.activeAlertsContexts;

export const reduxSelectActiveAlertsWithContexts = (state: RootStateTS) => {
    // Sort active alerts by created_at date in ascending order
    if (!state.compliance.activeAlertsWithContexts) return [];

    // Do not directly mutate the state. Spread instead
    let sortedAlerts = [...state.compliance.activeAlertsWithContexts].sort((a, b) => {
        // return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    });

    return sortedAlerts;
}

export const reduxSelectCurrentActiveAlertWithContext = (state: RootStateTS) => state.compliance.selectedActiveAlertWithContext;

export const reduxSelectTotalMessageCount = (state: RootStateTS) => state.compliance.totalMessageCount;

export const reduxSelectComplianceData = (state: RootStateTS) => state.compliance.complianceData;

export const reduxSelectLexiconData = (state: RootStateTS) => state.compliance.lexiconData;

export const reduxSelectHasComplianceAuth = (state: RootStateTS) => state.compliance.hasComplianceAuth;

export const reduxSelectUsersWhoCanAttemptCompliance = (state: RootStateTS) => state.compliance.usersWhoCanAttemptCompliance;

export default complianceSlice.reducer;