// Tanner Fry
// tfry@monetagroup.com
// File contains the header component for the Twilio Conversations dashboard.

import React from "react";
import { capitalizeFirstLetter, copyToClipboard } from "../../components/UtilitiesTS";
import { ChatType, IUserDetails } from "../../interfaces/General";
import { useAppSelectorTs } from "../../redux/hooksTS";
import { reduxSelectIsDarkMode } from "../../redux/slicesTS/UsersSlice";

import TCUtilities from "./TwilioConversationUtilities";

import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import SearchAllConversationMessages from "./SearchAllConversationMessages";
import { ITwilioConversationsConversationProps } from "../../interfaces/TwilioConversations";

interface ITwilioHeaderProps {
    chatType: string;
    advisor_or_staff_number: string;
    onFilterClick: (newText: string) => void;
    filterOption: string;
    searchAllConversationsText: string
    setSearchAllConversationsText: React.Dispatch<React.SetStateAction<string>>;
    userDetails: IUserDetails;
    handleConversationClicked: (conversation: ITwilioConversationsConversationProps) => void;
    messageSearchId: number;
    setMessageSearchId: React.Dispatch<React.SetStateAction<number>>;
    handlePullCurrentPageOfMessages: (conversation: ITwilioConversationsConversationProps) => void;
    setCurrentConversationMessagesPage: React.Dispatch<React.SetStateAction<number>>;
    currentConversationMessagesPage: number;
    setHasUserScrolled: React.Dispatch<React.SetStateAction<boolean>>;
    setMessagesHasNextPage: React.Dispatch<React.SetStateAction<boolean>>;
}
const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#7E8C9A',
    borderRadius: '20px',
    fontSize: '12px',
    lineHeight: '12px',
    padding: '8px 12px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#41BA77',
    },
    '&.active': {
      backgroundColor: '#41BA77',
    },
  }));

const TwilioHeader = ({
    chatType,
    advisor_or_staff_number,
    filterOption,
    onFilterClick,
    searchAllConversationsText,
    setSearchAllConversationsText,
    userDetails,
    handleConversationClicked,
    messageSearchId,
    setMessageSearchId,
    handlePullCurrentPageOfMessages,
    setCurrentConversationMessagesPage,
    currentConversationMessagesPage,
    setHasUserScrolled,
    setMessagesHasNextPage
} : ITwilioHeaderProps) => {
    const isDarkMode = useAppSelectorTs(reduxSelectIsDarkMode);
    return (
        <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
                <span className="head-icon me-16">
                    <em className="icon-chat-alt-2-filled"></em>
                </span>
                <h4 className="m-0" style={{color: isDarkMode ? "#fff" : ""}}>{ capitalizeFirstLetter(chatType) }</h4>
            </div>

            {advisor_or_staff_number && advisor_or_staff_number != "" && chatType == ChatType.TEXT && (
                <div className="advisor-number">
                    Your assigned text number: {TCUtilities.formatPhoneNumber(advisor_or_staff_number)}
                    <button 
                        className="btn-icon-info-plain"
                        style={{color: "#fff", paddingRight: 'unset'}}
                        onClick={() => {
                            copyToClipboard(advisor_or_staff_number)
                        }}
                    >
                        <em className="icon-clipboard-copy-outline"/>
                    </button>
                </div>
            )}
            <div className="conversation-filters">
                <div className="d-flex align-items-center">
                    <ColorButton className={"btn-small-plain " + (filterOption === 'all' ? 'active' : '')} onClick={(event) => { onFilterClick("all"); }}>
                        ALL
                    </ColorButton>
                    <ColorButton className={"btn-small-plain mx-8 " + (filterOption === 'unread' ? 'active' : '')} onClick={(event) => { onFilterClick("unread"); }}>
                        UNREAD
                    </ColorButton>
                    {/* <ColorButton className={"btn-small-plain " + (filterOption === 'archived' ? 'active' : '')} onClick={(event) => { onFilterClick("archived"); }}>
                        ARCHIVED
                    </ColorButton> */}
                </div>
                <SearchAllConversationMessages 
                    setSearchAllConversationsText={setSearchAllConversationsText}
                    searchAllConversationsText={searchAllConversationsText}
                    userDetails={userDetails}
                    handleConversationClicked={handleConversationClicked}
                    messageSearchId={messageSearchId}
                    setMessageSearchId={setMessageSearchId}
                    handlePullCurrentPageOfMessages={handlePullCurrentPageOfMessages}
                    setCurrentConversationMessagesPage={setCurrentConversationMessagesPage}
                    setHasUserScrolled={setHasUserScrolled}
                    setMessagesHasNextPage={setMessagesHasNextPage}
                    chatType={chatType}
                />
            </div>
            
        </div>
    );
}

export default TwilioHeader;