// Tanner Fry
// tfry@monetagroup.com
// File contains general interfaces and types used throughout the app.

interface IServerResponseProps {
    data: any;
    type: string;
    error: {
        error: string;
    }
}

/**
 * UserDetailsProps interface that contains the properties of the user.
 * 
 * @interface IUserDetails
 * @property {string} admin_type - The type of admin.
 * @property {string[]} default_meeting_attendees - The default meeting attendees.
 * @property {string} default_meeting_duration - The default meeting duration.
 * @property {number} default_scheduling - The default scheduling.
 * @property {string} email - The email of the user.
 * @property {string} employee - The employee data. Null if not an employee.
 * @property {string} enable_meeting_request - Boolean value (in string form...) to enable meeting request.
 */
interface IUserDetails {
    admin_type: string;
    default_meeting_attendees: string[];
    default_meeting_duration: string;
    default_scheduling: number;
    email: string;
    employee?: IEmployeeDetails;
    enable_meeting_request: string;  // boolean
    face_id_toggle: boolean;
    first_name: string;
    home_number: string;
    household: number;
    household_sf_id: string;
    international_calling_codes: string;
    is_first_login: boolean;
    last_name: string;
    notification_end_time: string;
    notification_every_day: boolean;
    notification_start_time: string;
    notification_toggle: boolean;
    phone_number: string;
    profile_image: string;
    sendbird_user_id: string;
    sendbird_user_token: string;
    session_timeout: number;
    session_timestamp: string;
    timezone: {
        abbreviation: string;
        description: string;
        live_meeting: string;
        utc_offset: number;
        uuid: string;
        windows: string;
    }
    timezone_id: string;
    token: string;
    twilio_assigned_proxy_number: string;
    twilio_conversations_user_identity: string;
    twilio_conversations_user_token: string;
    user_id: string;
    user_permission: {
        can_manage_message_group: boolean;
    }
    user_type: string;
    uuid: string;
    work_hours_end_time: string;
    work_hours_start_time: string;
}

interface IEmployeeDetails {
    first_name: string;
    id: number;
    is_active: boolean;
    last_name: string;
    role: EmployeeRole;
    salesforce_id: string;
}
interface IArticle {
    uuid: string;
    title: string;
    description: string;
    image: string;
    publish_date: string;
    is_featured: boolean;
    topic: { uuid: string; name: string }[];
    article_actions?: { is_read?: boolean; is_bookmark?: boolean }[];
    public_url?: string;
    is_published?: boolean;
    notificationRead?: boolean;
}
interface ITopic {
    uuid: string;
    name: string;
}

interface ITopicComponent {
    topic: ITopic[];
    getArticle: (article_url: string) => void;
    setArticlesDataList: (articles: any[]) => void;
    setNext: (next: string | null) => void;
    bookmark: number;
}
export enum EmployeeRole {
    ADVISOR = 'Advisor',
    CSM = 'CSM',
    PARTNER = 'Partner',
    STAFF = 'Staff',
}

export enum AdminType {
    FIRM_ADMIN = 'firm_admin',
    TEAM_ADMIN = 'team_admin',
    NONE = 'none'
}

export enum ChatType {
    TEXT = 'text',
    CHAT = 'chat',
}

export type {
    IEmployeeDetails,
    IServerResponseProps,
    IUserDetails,
    IArticle,
    ITopic,
    ITopicComponent
};

