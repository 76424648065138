import { useEffect, useRef, useState } from 'react';
import { ITwilioConversationsConversationProps, ITwilioConversationsMessageProps } from '../../interfaces/TwilioConversations';
import { useAppDispatchTS, useAppSelectorTs } from '../../redux/hooksTS';
import { convertISOToTime, getInitials } from "../../components/UtilitiesTS";

import TCUtilities from './TwilioConversationUtilities';
import { Popper } from '@mui/base/Popper';
import { TwilioConversationsRedux } from '../../redux/actions/TwilioConversations/TwilioConversations';
import { ChatType, IUserDetails } from '../../interfaces/General';
import { reduxUserConversations, reduxSetMessageIndex, reduxMessageIndex, reduxSetScrollToMessage, reduxSetActiveConversation, reduxActiveConversation } from '../../redux/slicesTS/TwilioConversationsSlice';


interface SearchConversationMessagesProps {
    userDetails: IUserDetails; // UserDetails
    searchAllConversationsText: string;
    setSearchAllConversationsText: React.Dispatch<React.SetStateAction<string>>;
    handleConversationClicked: (conversation: ITwilioConversationsConversationProps) => void;
    setMessageSearchId: React.Dispatch<React.SetStateAction<number>>;
    messageSearchId: number;
    handlePullCurrentPageOfMessages: (conversation: ITwilioConversationsConversationProps) => void;
    setCurrentConversationMessagesPage: React.Dispatch<React.SetStateAction<number>>;
    setHasUserScrolled: React.Dispatch<React.SetStateAction<boolean>>;
    setMessagesHasNextPage: React.Dispatch<React.SetStateAction<boolean>>;
    chatType: String;
}
interface ISearchResultEntry {
    author: string;
    authorProfileImage: string;
    message: string;
    messageTimestamp: string;
    friendly_name: string;

}
const SearchConversationMessages = (
    {
        userDetails,
        searchAllConversationsText,
        setSearchAllConversationsText,
        handleConversationClicked,
        setMessageSearchId,
        messageSearchId,
        handlePullCurrentPageOfMessages,
        setCurrentConversationMessagesPage,
        setHasUserScrolled,
        setMessagesHasNextPage,
        chatType,

    }: SearchConversationMessagesProps) => {
    const dispatch = useAppDispatchTS();
    const [searchText, setSearchText] = useState('');
    const [listOfMessages, setListOfMessages] = useState<ITwilioConversationsMessageProps[]>([]);
    const [listOfMessagesFiltered, setListOfMessagesFiltered] = useState<ITwilioConversationsMessageProps[]>([]);
    const [popperSearchOpen, setPopperSearchOpen] = useState(false);
    const [searchedConversation, setSearchedConversation] = useState<ITwilioConversationsConversationProps>();
    const refMessageSearch = useRef<HTMLDivElement>(null);
    const [pullMessagesBool, setPullMessagesBool] = useState(false);
    const userConversations = useAppSelectorTs(reduxUserConversations);
    const messageIndexH = useAppSelectorTs(reduxMessageIndex);
    const activeConversation = useAppSelectorTs(reduxActiveConversation);
    const [state, setState] = useState({
        warnToastShow: false,
        warnMessage: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (pullMessagesBool && !isLoading) {

            // Load messages of active conversation from API and keep pulling messages based on page number and 
            setIsLoading(true);
            const fetchAllMessages = async (userConversations?: ITwilioConversationsConversationProps[]) => {
                const allMessages = [];
                if (userConversations) {
                    console.log(userConversations);
                    for await (const conversation of userConversations) {
                        if (conversation.type === chatType) {
                            const messagesAndPage = await TCUtilities.getAllMessagesForConversation(dispatch, conversation, false);
                            allMessages.push(...messagesAndPage.messages);
                        }
                    }
                    // Order messages from newest to oldest
                    allMessages.sort((a, b) => {
                        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                    });
                    setListOfMessages(allMessages);
                    setIsLoading(false);
                }
            }
            fetchAllMessages(userConversations);
        }
    }, [pullMessagesBool, userConversations]);

    const fetchAllMessagesFromConversation = async (conversation?: ITwilioConversationsConversationProps) => {
        const allMessages = [];
        if (conversation) {
            const messagesAndPage = await TCUtilities.getAllMessagesForConversation(dispatch, conversation, false);
            allMessages.push(...messagesAndPage.messages);
            // Order messages from newest to oldest
            allMessages.sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            });

            return allMessages;
        }
    }
    useEffect(() => {
        setPopperSearchOpen(false);
        setSearchText('');
    }, [messageSearchId]);

    useEffect(() => {
        setListOfMessages([]);
        setPullMessagesBool(false);
        setSearchText('');
        setPopperSearchOpen(false);
    }, [chatType]);

    useEffect(() => {
        if (listOfMessages.length > 0) {
            searchMessagesApi();
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [listOfMessages]);

    useEffect(() => {
        if (searchedConversation && searchedConversation?.messages) {
            dispatch(reduxSetActiveConversation(searchedConversation));
            setSearchText('');
            setPopperSearchOpen(false);
        }
        if (searchedConversation && searchedConversation?.sid !== activeConversation?.sid && searchedConversation?.messages) {
            handleConversationClicked(searchedConversation);
            setSearchText('');
            setPopperSearchOpen(false);
        }
        if (searchedConversation && searchedConversation?.sid === activeConversation?.sid && searchedConversation?.messages) {
            setSearchText('');
            setPopperSearchOpen(false);
            handlePullCurrentPageOfMessages(searchedConversation);
        }
    }, [searchedConversation]);

    const handlePageChange = (pageIndex: number, totalPages: number, messageIndex: number, conversation: ITwilioConversationsConversationProps) => {
        dispatch(reduxSetMessageIndex(messageIndex));
        setMessageSearchId(pageIndex);
        setCurrentConversationMessagesPage(pageIndex + 1);
        setPopperSearchOpen(false);
        setSearchedConversation(conversation);
        if(searchedConversation === conversation) {
            dispatch(reduxSetScrollToMessage(messageIndex));
        }
        if (pageIndex === totalPages) {
            setMessagesHasNextPage(false);
        }
        setHasUserScrolled(true);

    };

    useEffect(() => {
        if (searchText.length === 0) {
            setSearchText('');
            setListOfMessagesFiltered([]);
            setPopperSearchOpen(false);
            setSearchAllConversationsText('');
        }
        if (searchText.length >= 2) {
            searchMessagesApi();
            setPopperSearchOpen(true);
        }
    }, [searchText]);

    const searchMessagesApi = async () => {
        let searchQuery = searchText.trim();
        if (searchQuery.length >= 1) {

            let filteredMessages = listOfMessages.filter((message) => {
                let messageText = message.body;
                let searchRegex = new RegExp(searchQuery, 'i');
                return searchRegex.test(messageText);
            });
            setListOfMessagesFiltered(filteredMessages);
        }
    };
    return (
        <div className='search-conversation-messages'>
            <div className="search-area py-12" ref={refMessageSearch}>
                <input
                    placeholder="Search All Conversations..."
                    className="form-control"
                    value={searchText}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSearchText(value);
                        setSearchAllConversationsText(value);
                    }}
                    onFocus={() => {
                        setPullMessagesBool(true);
                    }}
                />
                <button className="search-btn icon-20" onClick={() => {
                    setSearchAllConversationsText(searchText);
                }}>
                    <em className="icon-search-outline"></em>
                </button>
                {searchAllConversationsText !== "" && (
                    <button
                        className="close-search-btn icon-20"
                        onClick={(event) => {
                            setSearchAllConversationsText("");
                            setSearchText('');
                        }}
                    >
                        <em className="icon-x-circle-filled"></em>
                    </button>
                )}
            </div>
            <Popper
                id={popperSearchOpen ? 'message-search-popper' : undefined}
                open={popperSearchOpen}
                anchorEl={refMessageSearch.current}
                placement="bottom-end"
                className='message-search-popper'
            >
                <div className='search-results'>
                    {listOfMessagesFiltered.length > 0 ? (
                        listOfMessagesFiltered.map((message: ITwilioConversationsMessageProps, index) => {
                            const conversation = userConversations.find((conversation) => conversation.sid === message.conversation_sid);
                            const participant = conversation?.participants.find((participant) => participant.sid === message.participant_sid);
                            const result: ISearchResultEntry = {
                                author: message.author.startsWith('+') ? conversation?.friendly_name || 'Unknown Author' : message.author,
                                authorProfileImage: participant?.profile_image || '',
                                message: message.body,
                                messageTimestamp: message.created_at,
                                friendly_name: conversation?.friendly_name || 'Unknown Conversation',
                            };
                            return (
                                <div key={index} className='search-result-item' onClick={async () => {
                                    if (conversation?.messages) {
                                        var conversationClicked = await fetchAllMessagesFromConversation(conversation);
                                        if (conversationClicked) {
                                            var actualIndex = 0;
                                            for (let i = 0; i < conversationClicked.length; i++) {
                                                const element = conversationClicked[i];
                                                if(element.index === message.index) {
                                                    actualIndex = i;
                                                }
                                            }
                                            var pageIndex = Math.floor(actualIndex / 20);
                                            var totalPages = Math.floor((conversationClicked.length - 1) / 20);
                                            handlePageChange(pageIndex, totalPages, message.index, conversation);
                                            
                                        }
                                    }
                                }}>
                                    <div className='item-image'>
                                        {result.authorProfileImage ? (
                                            <img src={result.authorProfileImage} alt="Profile" />
                                        ) : (
                                            <span className='user-photo-no-image'>
                                                {getInitials(result.author)}
                                            </span>
                                        )}
                                    </div>
                                    <div className='item-details'>
                                        <div className='details-header'>
                                            <h6>{result.friendly_name}</h6>
                                            <div className='message-time'>{convertISOToTime(result.messageTimestamp, false, true)}</div>
                                        </div>
                                        <h6>{result.author}</h6>
                                        <p>{result.message}</p>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className='no-search-results'>
                            No search results found
                        </div>
                    )}
                </div>
            </Popper>
        </div>
    );
};

export default SearchConversationMessages;