import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import Header from './Header';
import SideBar from './SideBar';

const Mainlayout = () => {
	const [toggleMobileMenu, settoggleMobileMenu] = useState(false);
	const headerRef = useRef(null);
	const [contentHeight, setContentHeight] = useState(0);
	const togglebtn = () => {
		settoggleMobileMenu(!toggleMobileMenu);
	}
	const location = useLocation();

	return (
		<div style={{ height: '100vh', display: 'grid'}}>
			<Header togglebtn={togglebtn} headerRef={headerRef} />
			<div className='d-flex content-wrap overflow-auto'>
				<SideBar toggleMobileMenu={toggleMobileMenu} togglebtn={togglebtn} />
				<div className='flex-fill main-content-bg content-right overflow-auto'>
					<Outlet />
				</div>
			</div>
			{/* <Footer /> */}
		</div>
	)
}

export default Mainlayout;