// Tanner Fry
// tfry@monetagroup.com
// File contains constants for Twilio Conversations.

export const twilioConversationsConstants = {
    GET_ADVISOR_LIST_SUCCESS: "GET_ADVISOR_LIST_SUCCESS",
    GET_ADVISOR_LIST_FAILURE: "GET_ADVISOR_LIST_FAILURE",
    POST_NEW_CHAT_CONVERSATION_SUCCESS: "POST_NEW_CHAT_CONVERSATION_SUCCESS",
    POST_NEW_CHAT_CONVERSATION_FAILURE: "POST_NEW_CHAT_CONVERSATION_FAILURE",
    POST_NEW_TEXT_CONVERSATION_SUCCESS: "POST_NEW_TEXT_CONVERSATION_SUCCESS",
    POST_NEW_TEXT_CONVERSATION_CONFLICT: "POST_NEW_TEXT_CONVERSATION_CONFLICT",
    POST_NEW_TEXT_CONVERSATION_FAILURE: "POST_NEW_TEXT_CONVERSATION_FAILURE",
    POST_DELETE_TEXT_PARTICIPANT_FROM_CONVERSATION_SUCCESS: "POST_DELETE_TEXT_PARTICIPANT_FROM_CONVERSATION_SUCCESS",
    POST_DELETE_TEXT_PARTICIPANT_FROM_CONVERSATION_FAILURE: "POST_DELETE_TEXT_PARTICIPANT_FROM_CONVERSATION_FAILURE",
    GET_USER_CONVERSATIONS_SUCCESS: "GET_USER_CHATS_SUCCESS",
    GET_USER_CONVERSATIONS_FAILURE: "GET_USER_CHATS_FAILURE",
    POST_NEW_CONVERSATION_MESSAGE_SUCCESS: "POST_NEW_CONVERSATION_MESSAGE_SUCCESS",
    POST_NEW_CONVERSATION_MESSAGE_CONFLICT: "POST_NEW_CONVERSATION_MESSAGE_CONFLICT",
    POST_NEW_CONVERSATION_MESSAGE_FAILURE: "POST_NEW_CONVERSATION_MESSAGE_FAILURE",
    POST_NEW_FILES_SUCCESS: "POST_NEW_FILES_SUCCESS",
    POST_NEW_FILES_FAILURE: "POST_NEW_FILES_FAILURE",
    POST_NEW_FILES_CONFLICT: "POST_NEW_FILES_CONFLICT",
    GET_CONVERSATION_MESSAGES_SUCCESS: "GET_CHAT_MESSAGES_SUCCESS",
    GET_CONVERSATION_MESSAGES_FAILURE: "GET_CHAT_MESSAGES_FAILURE",
    POST_LEAVE_CONVERSATION_SUCCESS: "POST_LEAVE_CHAT_SUCCESS",
    POST_LEAVE_CONVERSATION_FAILURE: "POST_LEAVE_CHAT_FAILURE",
    POST_MUTE_CONVERSATION_SUCCESS: "POST_MUTE_CHAT_SUCCESS",
    POST_MUTE_CONVERSATION_FAILURE: "POST_MUTE_CHAT_FAILURE",
    UPDATE_CONVERSATION_NAME_SUCCESS: "UPDATE_CONVERSATION_NAME_SUCCESS",
    UPDATE_CONVERSATION_NAME_FAILURE: "UPDATE_CONVERSATION_NAME_FAILURE",
    POST_KICK_CHAT_USER_FROM_CONVERSATION_SUCCESS: "POST_KICK_CHAT_USER_FROM_CONVERSATION_SUCCESS",
    POST_KICK_CHAT_USER_FROM_CONVERSATION_FAILURE: "POST_KICK_CHAT_USER_FROM_CONVERSATION_FAILURE",
    POST_KICK_SMS_USER_FROM_CONVERSATION_SUCCESS: "POST_KICK_SMS_USER_FROM_CONVERSATION_SUCCESS",
    POST_KICK_SMS_USER_FROM_CONVERSATION_FAILURE: "POST_KICK_SMS_USER_FROM_CONVERSATION_FAILURE",
    POST_USER_READ_CONVERSATION_SUCCESS: "POST_USER_READ_CONVERSATION_SUCCESS",
    POST_USER_READ_CONVERSATION_FAILURE: "POST_USER_READ_CONVERSATION_FAILURE",
    GET_USER_UNREAD_MESSAGE_COUNT_TOTALS_SUCCESS: "GET_USER_UNREAD_MESSAGE_COUNT_TOTALS_SUCCESS",
    GET_USER_UNREAD_MESSAGE_COUNT_TOTALS_FAILURE: "GET_USER_UNREAD_MESSAGE_COUNT_TOTALS_FAILURE",
    UPDATE_USER_MESSAGE_READ_BY_SUCCESS: "UPDATE_USER_MESSAGE_READ_BY_SUCCESS",
    UPDATE_USER_MESSAGE_READ_BY_FAILURE: "UPDATE_USER_MESSAGE_READ_BY_FAILURE",
    GET_ALLOWED_TC_TEXT_USERS_SUCCESS: "GET_ALLOWED_TC_TEXT_USERS_SUCCESS",
    GET_ALLOWED_TC_TEXT_USERS_FAILURE: "GET_ALLOWED_TC_TEXT_USERS_FAILURE",
    GET_ALLOWED_TC_CHAT_USERS_SUCCESS: "GET_ALLOWED_TC_CHAT_USERS_SUCCESS",
    GET_ALLOWED_TC_CHAT_USERS_FAILURE: "GET_ALLOWED_TC_CHAT_USERS_FAILURE",
    POST_VALIDATE_LEGITIMACY_OF_PHONE_NUMBER_SUCCESS: "POST_VALIDATE_LEGITIMACY_OF_PHONE_NUMBER_SUCCESS",
    POST_VALIDATE_LEGITIMACY_OF_PHONE_NUMBER_FAILURE: "POST_VALIDATE_LEGITIMACY_OF_PHONE_NUMBER_FAILURE",
  };
  