// Tanner Fry
// tfry@monetagroup.com
// File contains the header component for the Twilio Conversations dashboard.

import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import { useAppSelectorTs } from "../../redux/hooksTS";
import { reduxSelectIsDarkMode } from "../../redux/slicesTS/UsersSlice";

interface IComplianceHeader {
    refreshFunction: React.DispatchWithoutAction;
    isRefreshing: boolean;
}

const ComplianceHeader = ({
    refreshFunction,
    isRefreshing,
} : IComplianceHeader) => {
    const isDarkMode = useAppSelectorTs(reduxSelectIsDarkMode);

    return (
        <div>
            <div className="d-flex align-items-center">
                <span className="head-icon me-16">
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 384 512"
                        // fill="#ffffff"
                        xmlns="http://www.w3.org/2000/svg">
                        <path 
                            fill="#313f56" 
                            d="M320 64l-40 0-9.6 0C263 27.5 230.7 0 192 0s-71 27.5-78.4 64L104 64 64 64C28.7 64 0 92.7 0 128L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64zM80 112l0 24c0 13.3 10.7 24 24 24l88 0 88 0c13.3 0 24-10.7 24-24l0-24 16 0c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l16 0zm88-32a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM289 267.6c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-89.7 89.7L129 287c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l53.3 53.3c4.5 4.5 10.6 7 17 7s12.5-2.5 17-7L289 267.6z"
                        />
                    </svg>
                </span>
                <h4 className="m-0" style={{color: isDarkMode ? "#fff" : ""}}>Compliance Dashboard</h4>
                <LoadingButton 
                    loading={isRefreshing}
                    loadingPosition="end"
                    variant="contained" 
                    onClick={refreshFunction} 
                    endIcon={<RefreshIcon />}
                    style={{marginLeft: "1em"}}
                >
                    Refresh
                </LoadingButton>
            </div>
        </div>
    );
}

export default ComplianceHeader;