// Tanner Fry
// tfry@monetagroup.com
// File contains the interface for info on a conversation.

import { Menu } from "antd";
import type { MenuProps } from "antd/";
import { useEffect, useRef, useState } from "react";
import { getInitials, notifyInAppError, notifyInAppSuccess, ppJson } from "../../components/UtilitiesTS";
import { AdminType, ChatType, EmployeeRole, IUserDetails } from "../../interfaces/General";
import { ITwilioConversationsConversationProps, ITwilioConversationsParticipantProps } from "../../interfaces/TwilioConversations";
import { TwilioConversationsRedux } from "../../redux/actions/TwilioConversations/TwilioConversations";
import { twilioConversationsConstants } from "../../redux/constants/TwilioConversationsConstant";
import { useAppDispatchTS, useAppSelectorTs } from "../../redux/hooksTS";
import TCUtilities from "./TwilioConversationUtilities";
import TwilioConversationViewMemberInfo from "./TwilioConversationViewMemberInfo";
import { ModalTwilioConversationChangeName, ModalTwilioInfoGeneralPopup } from "./TwilioModals";
import { reduxUserConversations, reduxSetActiveConversation, reduxActiveConversation } from '../../redux/slicesTS/TwilioConversationsSlice';

interface TwilioConversationInfoProps {
    userDetails: IUserDetails;
    setUserConversations: (conversations: ITwilioConversationsConversationProps[]) => void;
    setShowModalError: (show: boolean) => void;
    setShowModalErrorText: (text: string) => void;
    setShowConversationInfo: (show: boolean) => void;
    handleConversationLeaveClicked: (conversation: ITwilioConversationsConversationProps) => void;
    setShowModalTwilioDeleteParticipantFromConversation: (show: boolean) => void;
    chatType: string;
}

const TwilioConversationInfo = ({
    userDetails,
    setUserConversations,
    setShowModalError,
    setShowModalErrorText,
    setShowConversationInfo,
    handleConversationLeaveClicked,
    setShowModalTwilioDeleteParticipantFromConversation,
    chatType,
}: TwilioConversationInfoProps) => {
    const dispatch = useAppDispatchTS();
    const activeConversation = useAppSelectorTs(reduxActiveConversation);
    const [activeConversationName, setActiveConversationName] = useState<string>(activeConversation?.friendly_name || '');
    const [showConversationNameEditModal, setShowConversationNameEditModal] = useState<boolean>(false);
    const [showConversationDetailedInfo, setShowConversationDetailedInfo] = useState<boolean>(false);
    const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [conversationProfileImages, setConversationProfileImages] = useState<string[]>([]);
    const [conversationMemberCount, setConversationMemberCount] = useState<number>(0);
    const [currentlySelectedMember, setCurrentlySelectedMember] = useState<ITwilioConversationsParticipantProps | null>(null);
    const [showConversationMemberInfo, setShowConversationMemberInfo] = useState<boolean>(false);
    const userConversations = useAppSelectorTs(reduxUserConversations);
    const conversationMemberItemOptions: MenuProps['items'] = [
        {
            key: '1',
            onClick: (e) => { currentlySelectedMember && handleViewMemberInfo(currentlySelectedMember); },
            label: 'View Info',
        },
        // Hidden for now since the functionality is not included.
        // {
        //     key: '2',
        //     onClick: (e) => {currentlySelectedMember && handleResendOptInMessage(currentlySelectedMember);},
        //     label: 'Resend Opt-In Message',
        //     style: {display: (
        //         chatType === ChatType.TEXT 
        //         && currentlySelectedMember 
        //         && currentlySelectedMember.messaging_binding 
        //         && currentlySelectedMember.messaging_binding.address !== "" 
        //         && currentlySelectedMember.opt_in_status === false
        //     ) ? 'block' : 'none'},
        // },
        {
            key: '2',
            onClick: (e) => { currentlySelectedMember && handleKickMember(currentlySelectedMember); },
            label: 'Kick Member',
            // Add disabled class if the currently selected member is the user
            className: (
                currentlySelectedMember && (
                    currentlySelectedMember.identity === userDetails.twilio_conversations_user_identity
                ) || currentlySelectedMember && (
                    currentlySelectedMember.is_active === false
                )
            ) ? 'disabled' : '',
        }
    ];

    // Only take the newest participant in the conversation for a particular SMS number since a single user can have multiple participants in a conversation (due to opting in or out)
    const [uniqueParticipants, setUniqueParticipants] = useState<ITwilioConversationsParticipantProps[]>([]);

    useEffect(() => {
        let newUniqueParticipants: ITwilioConversationsParticipantProps[] = [];
        // Always add active participants
        activeConversation?.participants.forEach((participant: ITwilioConversationsParticipantProps) => {
            if (participant.is_active) {
                newUniqueParticipants.push(participant);
            }
        });
        // Check if any inactive are different than all of the active
        activeConversation?.participants.forEach((participant: ITwilioConversationsParticipantProps) => {
            if (!participant.is_active && !newUniqueParticipants.some((activeParticipant: ITwilioConversationsParticipantProps) => activeParticipant.messaging_binding?.address === participant.messaging_binding?.address)) {
                newUniqueParticipants.push(participant);
            }
        });
        setUniqueParticipants(newUniqueParticipants);
        setConversationMemberCount(newUniqueParticipants.length);
    }, [activeConversation]);

    const conversationItemOptions: MenuProps['items'] = [
        {
            key: '3',
            onClick: (e) => {

            },
            label: (
                <div className='members-label d-flex justify-content-between align-items-center'>
                    <span>Members</span>
                    <div className='member-count'>{conversationMemberCount}</div>
                </div>
            ),
            icon: (
                <span
                    className='icon-32 cursor-pointer'
                    onClick={() => {

                    }}
                >
                    <em className='icon-users-outline fs-14'></em>
                </span>
            ),
            children: uniqueParticipants.map((participant: ITwilioConversationsParticipantProps) => {
                return {
                    key: participant.sid,
                    onMouseEnter: (e) => {
                        {
                            setCurrentlySelectedMember(participant);
                        }
                    },
                    label: (
                        <div className='member-item'>
                            <div className='user-image'>
                                {participant.profile_image ? (
                                    <img src={participant.profile_image} alt='User Image' />
                                ) : (
                                    // <UserOutlined />
                                    // Show the user's initials in a circle
                                    <>
                                        {participant.name ? (
                                            <div className='user-initials'>{getInitials(participant.name)}</div>
                                        ) : (
                                            <div className='user-initials'>{participant.messaging_binding ? TCUtilities.getLastFourOfPhoneNumber(participant.messaging_binding.address) : participant.uuid}</div>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className='user-name'>
                                {participant.name ? (
                                    <div className='user-initials'>
                                        {participant.name}&nbsp;
                                        ({participant.is_active ? 'Active' : 'Inactive'})
                                    </div>
                                ) : (
                                    <div className='user-initials'>
                                        {participant.messaging_binding ? TCUtilities.formatPhoneNumber(participant.messaging_binding.address) : participant.uuid}&nbsp;
                                        ({participant.is_active ? 'Active' : 'Inactive'})
                                    </div>
                                )}
                            </div>
                        </div>
                    ),
                    children: conversationMemberItemOptions
                }
            })
        },
        {
            key: '4',
            onClick: (e) => { handleLeaveConversation(); },
            label: 'Leave Conversation',
            icon: (
                <span
                    className='icon-32 cursor-pointer'
                    onClick={() => {

                    }}
                >
                    <em className='icon-logout-filled fs-14'></em>
                </span>
            )
        }
    ];

    const handleEditConversationNameViaModal = () => {
        setShowConversationNameEditModal(true);
    }

    const handleEditConversationNameOnInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        // Make sure conversation name has more than 0 characters and less than 255 characters
        if (newValue.length === 0 || newValue.length > 255) {
            return;
        }

        setActiveConversationName(newValue);

        // Clear the previous timeout
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        // Set a new timeout to update the conversation name in the db after 5 seconds of inactivity

        // Update conversation name in db after 5 seconds of inactivity
        if (activeConversation) {
            typingTimeoutRef.current = setTimeout(() => {
                dispatch(TwilioConversationsRedux.UpdateUserChatConversationName(activeConversation.sid, newValue, userDetails.twilio_conversations_user_token)).then((response: any) => {
                    if (response.type === twilioConversationsConstants.UPDATE_CONVERSATION_NAME_SUCCESS) {
                        notifyInAppSuccess("Successfully updated conversation name!");
                        // Updated conversation name, change it everywhere else in TwilioConversations
                        dispatch(reduxSetActiveConversation({
                            ...activeConversation,
                            friendly_name: newValue
                        }));
                        setUserConversations(userConversations.map((conversation: ITwilioConversationsConversationProps) => {
                            if (activeConversation && conversation.sid === activeConversation.sid) {
                                return {
                                    ...conversation,
                                    friendly_name: newValue
                                }
                            } else {
                                return conversation;
                            }
                        }));
                    } else if (response.type === twilioConversationsConstants.UPDATE_CONVERSATION_NAME_FAILURE) {
                        notifyInAppError("Error when updating conversation name: " + JSON.stringify(response.error.error));
                        // setShowModalError(true);
                        // setShowModalErrorText('Failed to update conversation name. Error: ' + response.error.error);
                    }
                });
            }, 5000);
        }
    }

    const handleViewMemberInfo = (participant: ITwilioConversationsParticipantProps) => {
        // Aggregate all the information about the participant and display it in a modal
        setCurrentlySelectedMember(participant);
        setShowConversationMemberInfo(true);
    }

    const handleResendOptInMessage = (participant: ITwilioConversationsParticipantProps) => {

    }

    const getConversationParticipantWithNumber = (conversation: ITwilioConversationsConversationProps): ITwilioConversationsParticipantProps | null => {
        let participantWithNumber: ITwilioConversationsParticipantProps | null = null;
        conversation.participants.map(participant => {
            if (participant.messaging_binding && participant.messaging_binding.address != null && participant.messaging_binding.address != "") {
                participantWithNumber = participant
            }
        })

        return participantWithNumber
    }

    const handleKickMember = (participant: ITwilioConversationsParticipantProps) => {
        // Kick the member from the conversation
        if (activeConversation) {
            // Determine if we're kicking an SMS participant or a chat participant
            if (participant.identity && participant.identity !== '') {
                // Kick chat participant
                dispatch(TwilioConversationsRedux.PostKickChatUserFromConversation(activeConversation.sid, participant.sid)).then((response: any) => {
                    if (response.type === twilioConversationsConstants.POST_KICK_CHAT_USER_FROM_CONVERSATION_SUCCESS) {
                        notifyInAppSuccess("Successfully kicked chat user from conversation!");
                        // Remove conversation from userConversations
                        setUserConversations(userConversations.map((conversation: ITwilioConversationsConversationProps) => {
                            if (conversation.sid === activeConversation.sid) {
                                return {
                                    ...conversation,
                                    participants: conversation.participants.filter((conversationParticipant: ITwilioConversationsParticipantProps) => conversationParticipant.sid !== participant.sid)
                                }
                            } else {
                                return conversation;
                            }
                        }));
                        // Update active conversation with the new participants
                        dispatch(reduxSetActiveConversation({
                            ...activeConversation,
                            participants: activeConversation.participants.filter((conversationParticipant: ITwilioConversationsParticipantProps) => conversationParticipant.sid !== participant.sid)
                        }));
                    } else if (response.type === twilioConversationsConstants.POST_KICK_CHAT_USER_FROM_CONVERSATION_FAILURE) {
                        notifyInAppError("Error when kicking chat user from conversation: " + JSON.stringify(response.error.error));
                        // setShowModalError(true);
                        // setShowModalErrorText('Failed to kick chat user from conversation. Error: ' + response.error.error);
                    }
                });
            } else {
                // Kick SMS participant
                dispatch(TwilioConversationsRedux.PostKickSMSUserFromConversation(activeConversation.sid, participant.sid)).then((response: any) => {
                    if (response.type === twilioConversationsConstants.POST_KICK_SMS_USER_FROM_CONVERSATION_SUCCESS) {
                        notifyInAppSuccess("Successfully kicked SMS user from conversation!");
                        // Remove conversation from userConversations
                        setUserConversations(userConversations.map((conversation: ITwilioConversationsConversationProps) => {
                            if (conversation.sid === activeConversation.sid) {
                                return {
                                    ...conversation,
                                    participants: conversation.participants.filter((conversationParticipant: ITwilioConversationsParticipantProps) => conversationParticipant.sid !== participant.sid)
                                }
                            } else {
                                return conversation;
                            }
                        }));
                        // Update active conversation with the new participants
                        dispatch(reduxSetActiveConversation({
                            ...activeConversation,
                            participants: activeConversation.participants.filter((conversationParticipant: ITwilioConversationsParticipantProps) => conversationParticipant.sid !== participant.sid)
                        }));
                    } else if (response.type === twilioConversationsConstants.POST_KICK_SMS_USER_FROM_CONVERSATION_FAILURE) {
                        notifyInAppError("Error when kicking SMS user from conversation: " + JSON.stringify(response.error.error));
                        // setShowModalError(true);
                        // setShowModalErrorText('Failed to kick sms user from conversation. Error: ' + response.error.error);
                    }
                });
            }
        } else {
            notifyInAppError("Error: No conversation found to kick user from.")
            // setShowModalErrorText('No conversation found to kick user from.');
            // setShowModalError(true);
        }
    }

    const handleLeaveConversation = () => {
        if (activeConversation) {
            handleConversationLeaveClicked(activeConversation);
            setShowConversationInfo(false);
        } else {
            notifyInAppError("Error: No conversation found to leave.");
            // setShowModalErrorText('No conversation found to leave.');
            // setShowModalError(true);
        }
    }

    useEffect(() => {
        if (activeConversation) {
            setActiveConversationName(activeConversation.friendly_name);
        }
        // Grab a profile image from each participant if it exists, otherwise ignore
        const profileImages: string[] = activeConversation?.participants
            .map((participant: ITwilioConversationsParticipantProps) => participant.profile_image || '')
            .filter(image => image !== '') || [];

        setConversationProfileImages(profileImages);
    }, [activeConversation]);

    return (
        <div className='twilio-conversation-info d-flex' style={{ width: '320px' }}>
            {showConversationMemberInfo && currentlySelectedMember ? (
                <TwilioConversationViewMemberInfo
                    memberInfo={currentlySelectedMember}
                    setShowConversationMemberInfo={setShowConversationMemberInfo}
                    setCurrentlySelectedMember={setCurrentlySelectedMember}
                />
            ) : (
                <>
                    <div className='conversation-info-header'>
                        <h5 className='conversation-title m-0'>Conversation Information</h5>
                        <span
                            className='icon-32 cursor-pointer'
                            onClick={() => {
                                setShowConversationInfo(false);
                            }}
                        >
                            <em className='icon-x-outline fs-14'></em>
                        </span>
                    </div>
                    <div className='conversation-info'>
                        {/* <div className='edit-text cursor-pointer'> */}
                        <button
                            className='btn-edit-plain'
                            onClick={() => { handleEditConversationNameViaModal() }}
                        >
                            Edit
                        </button>
                        {/* </div> */}
                        <div className='conversation-image'>
                            <div className='conversation-initials'>{activeConversation ? getInitials(activeConversation.friendly_name) : ''}</div>
                            {/* {chatType === ChatType.CHAT ? (
                                <div className='conversation-initials'>{getInitials(activeConversation.friendly_name)}</div>
                            ) : (
                                <div className='conversation-initials'>
                                    {activeConversation && activeConversation.has_text_message_binding_setup_with_participant ? (
                                        TCUtilities.getLastFourOfPhoneNumber(
                                            getConversationParticipantWithNumber(activeConversation)?.messaging_binding?.address ?? ""
                                        ) ?? "<Undefined>"
                                    ) : (
                                        activeConversation ? getConversationParticipantWithNumber(activeConversation)?.uuid ?? "<Undefined>" : "<Undefined>"
                                    )}
                                </div>
                            )} */}
                            {/* Show circle with participants initials if image of participants cant be found */}
                            {/* // {conversationProfileImages.length > 0 ? (
                            //     <img src={conversationProfileImages[0]} alt='Conversation Image' />
                            // ) : (
                            //     <div className='conversation-initials'>{getInitials(activeConversation.friendly_name)}</div>
                            // )} */}
                        </div>
                        {/* <div className='conversation-name'>
                            {activeConversation.friendly_name}
                        </div> */}
                        <div className='conversation-name'>
                            <input
                                className='input-editable-otf-plain'
                                value={activeConversationName}
                                onChange={(e) => { handleEditConversationNameOnInput(e) }}
                                size={activeConversationName.length + 1}
                            />
                        </div>
                        <div className='conversation-details'>
                            {userDetails.twilio_assigned_proxy_number && chatType === ChatType.TEXT && (
                                <div>Conversation Number: {userDetails.twilio_assigned_proxy_number}</div>
                            )}
                            <button
                                className="btn-icon-info-plain"
                                onClick={() => {
                                    setShowConversationDetailedInfo(true);
                                }}
                            >
                                <em className="icon-info-circle-outline" />
                            </button>
                        </div>
                        <Menu
                            items={conversationItemOptions}
                            mode='inline'
                            className='conversation-info-member-menu'
                        />
                        {/* Only show button if user is userDetails.employee.role = staff */}
                        {userDetails.employee && userDetails?.employee?.role === EmployeeRole.STAFF && userDetails.admin_type === AdminType.FIRM_ADMIN
                            && userDetails.email === "tfry@monetagroup.com" && chatType == ChatType.TEXT && (
                                <button
                                    className="btn btn-success mt-16 mb-16"
                                    disabled={false}
                                    onClick={() => {
                                        setShowModalTwilioDeleteParticipantFromConversation(true);
                                    }}
                                >
                                    Delete Text Participant From Conversation
                                </button>
                            )}
                    </div>
                </>
            )}
            {showConversationNameEditModal && (
                <ModalTwilioConversationChangeName
                    userDetails={userDetails}
                    isShowing={showConversationNameEditModal}
                    handleClose={() => setShowConversationNameEditModal(false)}
                    setShowModalError={setShowModalError}
                    setShowModalErrorText={setShowModalErrorText}
                />
            )}
            {showConversationDetailedInfo && userDetails.employee?.role === EmployeeRole.STAFF && userDetails.admin_type === AdminType.FIRM_ADMIN ? (
                <ModalTwilioInfoGeneralPopup
                    isShowing={showConversationDetailedInfo}
                    handleClose={() => setShowConversationDetailedInfo(false)}
                    infoTitle="Conversation Detailed Info"
                    infoText={
                        <div>
                            Name: {activeConversation?.friendly_name ?? 'N/A'}<br />
                            UUID: {activeConversation?.uuid ?? 'N/A'}<br />
                            SID: {activeConversation?.sid ?? 'N/A'}<br />
                            Active: {activeConversation?.is_active ? 'Yes' : 'No'}<br />
                            Conversation Number: {(() => {
                                let conversationNumber = '';
                                const participantWithProxyAddress = activeConversation?.participants.find(
                                    (participant: ITwilioConversationsParticipantProps) => participant.messaging_binding && participant.messaging_binding.proxy_address
                                );

                                if (participantWithProxyAddress && participantWithProxyAddress.messaging_binding) {
                                    conversationNumber = participantWithProxyAddress.messaging_binding.proxy_address;
                                }

                                return conversationNumber;
                            })()}<br />
                            {ppJson('Conversation Info: ', activeConversation, false) || ''}
                        </div>
                    }
                />
            ) : (
                <>
                    {showConversationDetailedInfo && (
                        <ModalTwilioInfoGeneralPopup
                            isShowing={showConversationDetailedInfo}
                            handleClose={() => setShowConversationDetailedInfo(false)}
                            infoTitle="Conversation Detailed Info"
                            infoText={
                                <div>
                                    Name: {activeConversation?.friendly_name ?? 'N/A'}<br />
                                    UUID: {activeConversation?.uuid ?? 'N/A'}<br />
                                    SID: {activeConversation?.sid ?? 'N/A'}<br />
                                    Active: {activeConversation?.is_active ? 'Yes' : 'No'}<br />
                                    Conversation Number: {(() => {
                                        let conversationNumber = '';
                                        const participantWithProxyAddress = activeConversation?.participants.find(
                                            (participant: ITwilioConversationsParticipantProps) => participant.messaging_binding && participant.messaging_binding.proxy_address
                                        );

                                        if (participantWithProxyAddress && participantWithProxyAddress.messaging_binding) {
                                            conversationNumber = participantWithProxyAddress.messaging_binding.proxy_address;
                                        }

                                        return conversationNumber;
                                    })()}
                                </div>
                            }
                            modalSize="md"
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default TwilioConversationInfo;