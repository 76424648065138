import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IChatOpenState {
    wrapperClass: string;
}

const initialState: IChatOpenState = {
    wrapperClass: "", // Default class
};

const chatOpenSlice = createSlice({
    name: "chatOpenSlice",
    initialState,
    reducers: {
        setChatOpenClass: (state, action: PayloadAction<string>) => {
            state.wrapperClass = action.payload;
        },
        clearChatOpenClass: (state) => {
            console.log("clearChatOpenClass called");
            state.wrapperClass = "";
        },
    },
});

export const { setChatOpenClass, clearChatOpenClass } = chatOpenSlice.actions;
export default chatOpenSlice.reducer;