// Tanner Fry
// tfry@monetagroup.com
// File contains utilities for Twilio Conversations.

import { Client, State } from '@twilio/conversations';
import { convertISOToTime } from "../../components/UtilitiesTS";
import { ITwilioConversationsConversationProps, ITwilioConversationsMessageProps } from '../../interfaces/TwilioConversations';
import { useAppDispatchTS, useAppSelectorTs } from '../../redux/hooksTS';
import { AppDispatchTS } from '../../redux/storeTS';
import { TwilioConversationsRedux } from '../../redux/actions/TwilioConversations/TwilioConversations';
import { IResponseTwilioConversationMessages } from '../../interfaces/TwilioConversationResponses';
import { twilioConversationsConstants } from '../../redux/constants/TwilioConversationsConstant';

interface DateTimeFormatOptions {
    localeMatcher?: "best fit" | "lookup" | undefined;
    weekday?: "long" | "short" | "narrow" | undefined;
    era?: "long" | "short" | "narrow" | undefined;
    year?: "numeric" | "2-digit" | undefined;
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
    day?: "numeric" | "2-digit" | undefined;
    hour?: "numeric" | "2-digit" | undefined;
    minute?: "numeric" | "2-digit" | undefined;
    second?: "numeric" | "2-digit" | undefined;
    timeZoneName?: "short" | "long" | "shortOffset" | "longOffset" | "shortGeneric" | "longGeneric" | undefined;
    formatMatcher?: "best fit" | "basic" | undefined;
    hour12?: boolean | undefined;
    timeZone?: string | undefined;
}

// General Conversation Utilities

const TCUtilities = {
    
    // Get the Twilio client and set it up
    getTwilioClient: (token: string): Promise<Client> => {
        return new Promise((resolve, reject) => {
            const client: Client = new Client(token.replace(/^b'/, '').replace(/'$/, ''));
            client.on('stateChanged', (state: State) => {
                if (state === 'failed') {
                    reject(new Error('Twilio client failed to initialize'));
                }

                if (state === 'initialized') {
                    resolve(client);
                }
            });
        });
    },

    // Add target blank for chat links
    performTcMessageModifications: (htmlLink: string): string => {
        return htmlLink.replace(/<a /g, '<a target="_blank" ');
    },
    
    // Escape HTML to prevent injection
    escapeHtml: (unsafe: string): string => {
        // Not sure if this really does much
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    },

    // Format given string phone number
    // Desired format: +1 (123) 456-7890
    // +[country code] ([area code]) [local number]
    formatPhoneNumber: (phoneNumber: string): string => {
        // Check if the phone number is a US number. If not, return and don't format 
        if (!phoneNumber) {
            return '';
        } else if (!phoneNumber.startsWith('+1')) {
            return phoneNumber;
        }

        // Format number
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = match[1] ? '+1 ' : '';
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }

        return phoneNumber;
    },
    
    // Return a date separator if the current message doesn't have the same date as the previous message
    getDateSeparator: (currentMessage: ITwilioConversationsMessageProps, previousMessage?: ITwilioConversationsMessageProps): string => {
        const currentMessageDate = new Date(currentMessage.created_at);
        const previousMessageDate = previousMessage ? new Date(previousMessage.created_at) : null;
        
        // Helper function to check if two dates are the same day
        const isSameDay = (date1: Date, date2: Date | null) => {
            if (!date2) {
                return false;
            }

            return date1.getDate() === date2.getDate() &&
                date1.getMonth() === date2.getMonth() &&
                date1.getFullYear() === date2.getFullYear();
        };        
    
        // Helper function to format date
        const formatDate = (date: Date) => {
            return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
        };
    
        // If there is no previous message, return the current message date
        if (!previousMessageDate || !isSameDay(currentMessageDate, previousMessageDate)) {
            // Check if the current message date is today
            const currentDate = new Date();
            if (isSameDay(currentMessageDate, currentDate)) {
                return 'Today';
            } else if (isSameDay(currentMessageDate, new Date(currentDate.setDate(currentDate.getDate() - 1)))) {
                return 'Yesterday';
            } else {
                return formatDate(currentMessageDate);
            }
        }
    
        return "";
    },
    
    // Return the last four digits of a phone number
    getLastFourOfPhoneNumber: (number:string): string | null => {
        if (number == "") {
            return null
        }

        return number.slice(-4);
    },
    
    
    // Return the time of the message in 12 hour format if the message is from the current day. Otherwise return
    // yesterday or the date of the message. 
    getMessageTime: (
        messageDateTime: string, 
        dateTimeFormatOptions: DateTimeFormatOptions = {
            month: "long",
            day: "numeric",
            year: "numeric"
        }
    ): string => {
        const messageDate = new Date(messageDateTime);
        const currentDate = new Date();
        const yesterday = new Date(currentDate.getDate() - 1);
        
        if (messageDate.getDate() === currentDate.getDate() &&
            messageDate.getMonth() === currentDate.getMonth() &&
            messageDate.getFullYear() === currentDate.getFullYear()) {
            return convertISOToTime(messageDateTime);
        } else if (messageDate.getDate() === yesterday.getDate() &&
            messageDate.getMonth() === yesterday.getMonth() &&
            messageDate.getFullYear() === yesterday.getFullYear()) {
            return 'Yesterday';
        } else {
            return messageDate.toLocaleDateString('en-US', { month: dateTimeFormatOptions.month, day: dateTimeFormatOptions.day, year: dateTimeFormatOptions.year });
        }
    },
    
    // Check if the message is from the current user
    isMessageFromCurrentUser: (message: ITwilioConversationsMessageProps, currentUserParticipantSid: string): boolean => {
        return message.participant_sid === currentUserParticipantSid;
    },
    
    // Preserve special characters in Twilio message with HTML elements
    preserveSpecialCharactersInTwilioMessageWithHTMLElements: (message: string): string => {
        // General checks
        if (typeof message !== 'string') {
            return message;
        }

        // Escape HTML to prevent injection
        message = TCUtilities.escapeHtml(message);
        
        // Newline
        message = message.replace(/\n/g, '<br>');

        // Get the file name
        const url = message.match(/(https:\/\/[^\s]+)/g);
        const messageCompleteFileName = url ? url[0].split('/').pop() : null;
        const messageFileName = messageCompleteFileName ? messageCompleteFileName.split('.')[0] : null;
        const messageFileNameExtension = messageCompleteFileName ? messageCompleteFileName.split('.').pop() : null;
        // Trim the file name to 40 characters and add the extension
        let trimmedFileNameComplete = messageCompleteFileName;
        if (messageFileName && messageFileName.length > 40) {
            const trimmedFileName = messageFileName ? messageFileName.substring(0, 40) : null;
            trimmedFileNameComplete = trimmedFileName ? trimmedFileName + '...' + messageFileNameExtension : null;
        } else {
            trimmedFileNameComplete = messageCompleteFileName;
        }

        // Convert URLs to clickable links unless the URL is from https://media.tenor.com
        // Old version: message = message.replace(/(https?:\/\/[^\s<]+)/g, '<a href="$1" target="_blank">$1</a>');
        // message = message.replace(/(https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g, (url) => {
        message = message.replace(/(https:\/\/[^\s]+)/g, (url) => {            
            if (url.includes("https://media.tenor.com")) {
                return `<img class="tc-media tenor-gif" src="${url}" alt="gif" />`;
            } else if (url.includes(".jpeg") || url.includes(".jpg") || url.includes(".png") || url.includes(".gif")) {
                if (url.includes(".gif")) {
                    return `<a href=${url} target="_blank" rel="noreferrer">
                    <img class="tc-media tc-gif" src="${url}" alt="gif" />
                    </a>`;
                } else {
                    return `<a href=${url} target="_blank" rel="noreferrer">
                    <img class="tc-media tc-image" src="${url}" alt="image" />
                    </a>`;
                }
            } else if (url.includes(".mp4") || url.includes(".mov") || url.includes(".avi") || url.includes(".wmv")) {
                return `<a href=${url} target="_blank" rel="noreferrer">
                    <video class="tc-media tc-video" controls>
                    <source src="${url}" type="video/mp4">
                    Your browser does not support the video tag.
                    </video>
                    </a>
                    <a href=${url} target="_blank" rel="noreferrer" class="file-name-text-as-link">
                    ${trimmedFileNameComplete}
                    </a>`;
            } else if (url.includes(".pdf") || url.includes(".doc") || url.includes(".docx") || url.includes(".xls") || url.includes(".xlsx") || url.includes(".ppt") || url.includes(".pptx")) {
                if (url.includes(".pdf")) {
                    return `<a href=${url} target="_blank" class="icon-as-link" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5zm4-3H19v1h1.5V11H19v2h-1.5V7h3zM9 9.5h1v-1H9zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4zm10 5.5h1v-3h-1z"></path></svg>
                        </a>
                        <a href=${url} target="_blank" rel="noreferrer" class="file-name-text-as-link">
                        ${trimmedFileNameComplete}
                        </a>`;
                } else {
                    return `<a href=${url} target="_blank" class="icon-as-link" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0z" fill="none"/><path d="m20.41 8.41-4.83-4.83c-.37-.37-.88-.58-1.41-.58H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.83c0-.53-.21-1.04-.59-1.42M7 7h7v2H7zm10 10H7v-2h10zm0-4H7v-2h10z"></path></svg>
                        </a>
                        <a href=${url} target="_blank" rel="noreferrer" class="file-name-text-as-link">
                        ${trimmedFileNameComplete}
                        </a>`;
                }
            } else {
                // Unknown file at this time, default to file icon with a link
                return `<a href=${url} target="_blank" class="icon-as-link" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0z" fill="none"/><path d="m20.41 8.41-4.83-4.83c-.37-.37-.88-.58-1.41-.58H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9.83c0-.53-.21-1.04-.59-1.42M7 7h7v2H7zm10 10H7v-2h10zm0-4H7v-2h10z"></path></svg>
                        </a>
                        <a href=${url} target="_blank" rel="noreferrer" class="file-name-text-as-link">
                        ${trimmedFileNameComplete}
                        </a>`;
            }
        });
    
        return message;
    },
    getAllMessagesForPageOfConversation: async (dispatchTS: AppDispatchTS, conversationSid: string, page: number, pageSize: number = 20): Promise<{ messages: ITwilioConversationsMessageProps[], has_next_page: boolean }> => {
        let messages: ITwilioConversationsMessageProps[] = [];
        let has_next_page = true;

        // Start by checking if user is on the first page of messages
        await dispatchTS(TwilioConversationsRedux.GetUserConversationMessages(conversationSid, page, pageSize)).then(async (response: any) => {
            response = response as IResponseTwilioConversationMessages;
            if (response.type == twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                console.log('[Desktop]: Messages fetching success for page: ', page);
                messages = response.data.messages;
                has_next_page = response.data.has_next_page;
            } else if (response.type == twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                console.log('[Desktop]: Messages fetching failed for page: ', page);
            }
        });

        return { messages, has_next_page };
    },
    getAllMessagesForConversation: async (
        dispatchTS: AppDispatchTS,
        conversation: ITwilioConversationsConversationProps,
        reverse: boolean = false,
    ): Promise<{ messages: ITwilioConversationsMessageProps[], page: number }> => {
        let allMessages: ITwilioConversationsMessageProps[] = [];
        let currentMessagePage = 1;

        // TODO: Gather all messages based on conversation
        while (true) {
            // Fetch messages for the current page
            const { messages, has_next_page } = await TCUtilities.getAllMessagesForPageOfConversation(dispatchTS, conversation.sid, currentMessagePage);

            // Add messages to the allMessages array
            allMessages = [...messages, ...allMessages];

            // If there are no more messages, break out of the loop
            if (!has_next_page) {
                break;
            }

            // Increment the page number
            currentMessagePage++;
        }

        // Reverse the array if needed
        if (reverse) {
            allMessages.reverse();
        }

        return { messages: allMessages, page: currentMessagePage };
    },
}

export default TCUtilities;