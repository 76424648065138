// Tanner Fry
// tfry@monetagroup.com
// File contains the overall interface for the twilio chat integration.

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import dingCorrect from '../../Assets/sounds/ding-correct.mp3';
import { ModalGeneralError } from '../../components/GeneralModals';
import SoundPlayer from '../../components/SoundPlayer';
import { LoadingSpinner, notifyInAppError, notifyInAppSuccess, notifyInAppSuccessWithOnClose } from '../../components/UtilitiesTS';
import { AdminType, EmployeeRole, IUserDetails } from '../../interfaces/General';
import { ITwilioConversationsConversationProps, ITwilioConversationsMessageProps, ITwilioConversationsParticipantProps } from '../../interfaces/TwilioConversations';
import { TwilioConversationsRedux } from '../../redux/actions/TwilioConversations/TwilioConversations';
import { twilioConversationsConstants } from '../../redux/constants/TwilioConversationsConstant';
import { useAppDispatchTS, useAppSelectorTs } from '../../redux/hooksTS';
import { clearChatOpenClass, setChatOpenClass } from "../../redux/slicesTS/ChatOpenSlice";
import { reduxActiveConversation, reduxMessageIndex, reduxSetActiveConversation, reduxSetLoadedConversationsPreSendMessage, reduxSetScrollToMessage, reduxSetUserConversations, reduxUserConversations } from '../../redux/slicesTS/TwilioConversationsSlice';
import { reduxSelectIsDarkMode } from '../../redux/slicesTS/UsersSlice';
import SearchConversationMessages from './SearchConversationMessages';
import TwilioConversationInfo from './TwilioConversationInfo';
import TwilioConversationList from './TwilioConversationList';
import TwilioHeader from './TwilioHeader';
import TwilioMessages from './TwilioMessages';
import { ModalTwilioDeleteTextParticipantFromConversation, ModalTwilioNewConversation } from './TwilioModals';

interface ITwilioDashboardProps {
    chatType: string;
}

const TwilioDashboard = ({
    chatType
}: ITwilioDashboardProps) => {
    // Setup
    const [twilioConversationsContentHeight, setTwilioConversationsContentHeight] = useState(0);
    const refTwilioConversationsContainer = useRef(null);
    const refTwilioConversationsHeader = useRef(null);
    const [loader, setLoader] = useState(true);
    const dispatch = useAppDispatchTS();
    // Themeing
    const isDarkMode = useAppSelectorTs(reduxSelectIsDarkMode);
    // Modals 
    const [showModalTwilioNewConversation, setShowModalTwilioNewConversation] = useState(false);
    const [showModalTwilioDeleteParticipantFromConversation, setShowModalTwilioDeleteParticipantFromConversation] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [showModalErrorText, setShowModalErrorText] = useState("");
    // Details
    const [userDetails, setUserDetails] = useState<IUserDetails>(
        JSON.parse(localStorage.getItem("userDetails") || "")
    );
    const [userDetailsAsParticipant, setUserDetailsAsParticipant] = useState<ITwilioConversationsParticipantProps | null>(null);
    //const [userConversations, setUserConversations] = useState<ITwilioConversationsConversationProps[]>([]);
    const loadedConversationsPreSendMessage = useAppSelectorTs(state => state.twilioConversations.loadedConversationsPreSendMessage);
    //const [activeConversation, setActiveConversation] = useState<ITwilioConversationsConversationProps | null>(null);
    const activeConversation = useAppSelectorTs(reduxActiveConversation);
    const [currentConversationMessagesPage, setCurrentConversationMessagesPage] = useState<number>(1);
    const chatOpenClass = useAppSelectorTs(state => state.chatOpenClass.wrapperClass);
    const userConversations = useAppSelectorTs(reduxUserConversations);
    // Switches
    const [showConversationInfo, setShowConversationInfo] = useState(false);
    const [showConversationSearch, setShowConversationSearch] = useState(false);
    const [messagesHasNextPage, setMessagesHasNextPage] = useState(false);
    const [messagesPaginatorClicked, setMessagesPaginatorClicked] = useState(false);
    const [isMessagingEnabled, setIsMessagingEnabled] = useState(false);
    const [noResponseFromServer, setNoResponseFromServer] = useState(false)
    const soundPlayerRef = useRef<{ play: () => void; pause: () => void }>(null);
    const [filterOption, setfilterOption] = useState<string>("all");
    const [messageSearchId, setMessageSearchId] = useState<number>(0);
    // Special Conversation states
    const [conversationToLeave, setConversationToLeave] = useState<ITwilioConversationsConversationProps | null>(null);
    const [isModalConfirmLeaveConversationOpen, setIsModalConfirmLeaveConversationOpen] = useState(false);
    const [searchAllConversationsText, setSearchAllConversationsText] = useState("");
    const [filteredConversations, setFilteredConversations] = useState<ITwilioConversationsConversationProps[]>([]);
    const [hasUserScrolled, setHasUserScrolled] = useState<boolean>(false); // Lifted state
    //const [messageIndex, setMessageIndex] = useState<number>(-1);
    const messageIndex = useAppSelectorTs(reduxMessageIndex);

    const conversationFilterHandler = (message: string) => {
        {/* TODO: Archived state still needs to be adressed */}
        setfilterOption(message);
    }
    const playSoundDingCorrect = () => {
        if (soundPlayerRef.current) {
            soundPlayerRef.current.play();
        }
    };

    const pauseSoundDingCorrect = () => {
        if (soundPlayerRef.current) {
            soundPlayerRef.current.pause();
        }
    };

    useEffect(() => {
        // Clear active conversation when chat type changes
        
        //setUserConversations([]);
        // NOTE: I don't think we need to reset the presend messages since they are checked against the conversation SID
        // dispatch(reduxSetLoadedConversationsPreSendMessage([]));
        dispatch(reduxSetActiveConversation(null));
        setCurrentConversationMessagesPage(1);
        setShowConversationInfo(false);
        setShowConversationSearch(false);
        setMessagesHasNextPage(false);
        setMessagesPaginatorClicked(false);
        setIsMessagingEnabled(false);
        setLoader(true);
    }, [chatType]);

    useEffect(() => {
        console.log('userDetails', userDetails);
    }, [userDetails]);

    // Get user conversations
    useEffect(() => {
        dispatch(TwilioConversationsRedux.GetUserChatConversations(userDetails.twilio_conversations_user_identity)).then((response: any) => {
            // console.log('Get user conversations response for user ' + userDetails.twilio_conversations_user_identity + ':', response);
            if (response.type === twilioConversationsConstants.GET_USER_CONVERSATIONS_SUCCESS) {
                dispatch(reduxSetUserConversations(response.data.conversations));
                // Reset presend messages for conversations
                dispatch(reduxSetLoadedConversationsPreSendMessage(response.data.conversations.map((conversation: ITwilioConversationsConversationProps) => {
                    return {
                        conversation_sid: conversation.sid,
                        message: ""
                    };
                })));
                console.log("Conversations: ", response.data.conversations);
                
                // Set conversation unread message indicators for each conversation
                // dispatch(TwilioConversationsRedux.GetU)
                setLoader(false);
            }
        });
    }, [chatType]);

    // Periodically call the API to get all conversation updates
    // TODO: Refactor based on chatType
    useEffect(() => {
        // Check if active conversation is set. If so, check if conversation has sms participant. If so, check if they've opted in
        // TODO: REFACTOR THIS BELOW TO GET MESSAGE ENABLING
        // if (activeConversation && activeConversation.has_text_message_binding_setup_with_participant) {
        //     const sms_participants = activeConversation.participants.filter((participant: ITwilioConversationsParticipantProps) => participant.messaging_binding?.type === 'sms');
        //     let shouldMessagingBeEnabled = true;
        //     console.log('sms_participants', sms_participants);
            
        //     sms_participants.forEach((participant: ITwilioConversationsParticipantProps) => {
        //         if (!participant.opt_in_status) {
        //             shouldMessagingBeEnabled = false;
        //         }
        //     });
        //     console.log('Should messaging be enabled: ', shouldMessagingBeEnabled);
            
        //     setIsMessagingEnabled(shouldMessagingBeEnabled);
        // } else if (activeConversation && !activeConversation.has_text_message_binding_setup_with_participant) {
        //     console.log('No text message binding setup with participant.');
            
        //     setIsMessagingEnabled(true);
        // } else {
        //     console.log('No active conversation set that has a text message binding setup with participant.');
        //     console.log('Active conversation: ', activeConversation);
            
        // }

        // Setup interval to call for user conversations periodically
        const interval = setInterval(() => {
            // Check conversations
            dispatch(TwilioConversationsRedux.GetUserChatConversations(userDetails.twilio_conversations_user_identity)).then(async (response: any) => {
                // Check and make sure response was given
                if (!response) {
                    notifyInAppError("Error getting your conversations: No response from the server.");
                    setNoResponseFromServer(prevState => prevState || true);

                    return;
                } else {
                    setNoResponseFromServer(prevState => {
                        if (prevState) {
                            notifyInAppSuccessWithOnClose("Connected back to the server!", () => {
                                setNoResponseFromServer(false);
                            });
                        }

                        return prevState; // Returning prevState ensures it remains unchanged if already true
                    });

                    if (response && response.type === twilioConversationsConstants.GET_USER_CONVERSATIONS_SUCCESS) {
                        dispatch(reduxSetUserConversations(response.data.conversations));
                        // Update active conversation and it's messages if active conversation is set and a new message has been added
                        if (activeConversation) {
                            let potentialNewActiveConversation: ITwilioConversationsConversationProps = response.data.conversations.find((conversation: ITwilioConversationsConversationProps) => conversation.sid === activeConversation.sid);
                            if (!potentialNewActiveConversation) {
                                console.log('No potential new active conversation found.');

                                return; 
                            }
                            await dispatch(TwilioConversationsRedux.GetUserConversationMessages(potentialNewActiveConversation.sid, 1, 20)).then((response: any) => {
                                if (response && response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                                    // Update potential new conversation messages by combining old and new messages
                                    potentialNewActiveConversation = {
                                        ...potentialNewActiveConversation,
                                        messages: response.data.messages
                                    }
                                } else if (response && response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                                    console.log('Error getting potential new conversation messages.');
                                } else {
                                    console.log('No response from server when getting potential new conversation messages.');
                                }
                            });

                            // Log comparison of indexes
                            if (activeConversation.messages 
                                && activeConversation.messages.length > 0 
                                && activeConversation.messages[activeConversation.messages.length - 1].index !== undefined
                                && activeConversation.messages[activeConversation.messages.length - 1].index !== null
                                && potentialNewActiveConversation.messages && (
                                activeConversation.messages[activeConversation.messages.length - 1].index
                                !== potentialNewActiveConversation.messages[potentialNewActiveConversation.messages.length - 1].index
                            )) {
                                console.log('New messages in active conversation, updating messages 1.');
                                if (potentialNewActiveConversation.messages[potentialNewActiveConversation.messages.length - 1].participant_sid !== userDetailsAsParticipant?.sid) {
                                    playSoundDingCorrect();
                                }
                                // Handle pulling active conversation data
                                handlePullCurrentPageOfMessages(activeConversation);
                            } else {
                                if (activeConversation.messages 
                                    && activeConversation.messages.length == 0 
                                    && potentialNewActiveConversation.messages 
                                    && potentialNewActiveConversation.messages.length > 0) {
                                    console.log('New messages in active conversation, updating messages');
                                    if (potentialNewActiveConversation.messages[potentialNewActiveConversation.messages.length - 1].participant_sid !== userDetailsAsParticipant?.sid) {
                                        playSoundDingCorrect();
                                    }
                                    // Handle pulling active conversation data
                                    handlePullCurrentPageOfMessages(activeConversation);
                                }
                                if (
                                    activeConversation.messages && activeConversation.messages[activeConversation.messages.length - 1] &&
                                    activeConversation.messages[activeConversation.messages.length - 1].attributes.is_unread &&
                                    potentialNewActiveConversation.messages && !potentialNewActiveConversation.messages[potentialNewActiveConversation.messages.length - 1].attributes.is_unread
                                ){
                                    console.log('Unread update in active conversation, updating messages');
                                    handlePullCurrentPageOfMessages(activeConversation);
                                }
                                // console.log('No new messages in active conversation, not updating messages.');
                                // TODO: Check modified date since some messages could be updated (such as read_by, editing a message -- which is a future thing, or other)
                            }
                        } else {
                            // console.log('No active conversation set, not updating messages.');
                        }
                    } else if (response && response.type === twilioConversationsConstants.GET_USER_CONVERSATIONS_FAILURE) {
                        notifyInAppError("Error getting your conversations: " + JSON.stringify(response.error.error));
                    }
                }
            });
        }, 5000);  // 5 seconds
        return () => clearInterval(interval);
    }, [activeConversation]);

    useEffect(() => {
        // Filter conversation messages based on search text
        // if (searchAllConversationsText !== "") {
        //     console.log('Filtering conversations based on search text: ', searchAllConversationsText);
            
        //     // Get conversations that have messages that match the search text
        //     let searchResultEntries = userConversations.filter((conversation: ITwilioConversationsConversationProps) => {
        //         // Check conversation messages for search text. if it exists, return the conversation
        //         console.log(conversation.messages);
        //         const conversationMessages = conversation.messages || [];
        //         const searchRegex = new RegExp(searchAllConversationsText, 'i');
        //         let foundMatch = false;

        //         conversationMessages.forEach((message: ITwilioConversationsMessageProps) => {
        //             if (searchRegex.test(message.body)) {
        //                 foundMatch = true;
        //             }
        //         });

        //         return foundMatch;
        //     });

        //     // Filter out messages around the search text
        //     searchResultEntries = searchResultEntries.map((conversation: ITwilioConversationsConversationProps) => {
        //         if (!conversation.messages) return conversation;

        //         // Filter out messages that don't match the search text
        //         const searchResultMessages = conversation.messages.filter((message: ITwilioConversationsMessageProps) => {
        //             return new RegExp(searchAllConversationsText, 'i').test(message.body);
        //         });

        //         // Return a new conversation with only the filtered messages
        //         return {
        //             ...conversation,
        //             messages: searchResultMessages
        //         };
        //     });

        //     setFilteredConversations(searchResultEntries);
        // } else {
        //     setFilteredConversations([]);
        // }
    }, [searchAllConversationsText]);

    // Functions

    const handleCreateNewConversationClicked = () => {
        // Create popup that gives a form to create a new conversation
        setShowModalTwilioNewConversation(true);
    }

    const handleConversationClicked = (conversation: ITwilioConversationsConversationProps) => {
        setLoader(true);
        if (activeConversation && activeConversation.sid === conversation.sid) {
            dispatch(clearChatOpenClass())        
            dispatch(reduxSetActiveConversation(null));
            setLoader(false);
            return;
        }
        setShowConversationSearch(false);
        dispatch(setChatOpenClass("chat-open"))
        dispatch(reduxSetActiveConversation(conversation));
        setCurrentConversationMessagesPage(messageSearchId + 1);
        setMessagesHasNextPage(false);
        setMessagesPaginatorClicked(false);
        // Get current user's participant details from conversation
        const participant = conversation.participants.find((participant: ITwilioConversationsParticipantProps) => 
            participant.identity === userDetails.twilio_conversations_user_identity
        );
        setUserDetailsAsParticipant(participant || null);

        // Get conversation messages from API
        // setLoader(true);
        dispatch(TwilioConversationsRedux.GetUserConversationMessages(conversation.sid, messageSearchId + 1, 20)).then((response: any) => {
            // setLoader(false);
            console.log('response', response);
            if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                // Update active conversation messages
                const updatedConversation: ITwilioConversationsConversationProps = conversation ? {...conversation} : {} as ITwilioConversationsConversationProps;
                updatedConversation.messages = response.data.messages;
                setMessagesHasNextPage(response.data.has_next_page);                
                dispatch(reduxSetActiveConversation(updatedConversation));
                setLoader(false);
                dispatch(reduxSetScrollToMessage(messageIndex));
            } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                console.log('Error getting conversation messages after update interval.');
            }
        });
    }

    // NOTE: This should only be called if active conversation messages has been updated
    const handlePullCurrentPageOfMessages = async (activeConversation: ITwilioConversationsConversationProps) => {
        
        if (currentConversationMessagesPage > 1) {
            
            let updatedConversation: ITwilioConversationsConversationProps = activeConversation ? {...activeConversation} : {} as ITwilioConversationsConversationProps;
            // Pull messages up to current page
            const promises = [];
            const messagesByPage: { [page: number]: ITwilioConversationsMessageProps[] } = {};
            for (let i = 1; i <= currentConversationMessagesPage; i++) {
                // Pull messages for page i and add them all together
                const promise = dispatch(TwilioConversationsRedux.GetUserConversationMessages(activeConversation.sid, i, 20)).then((response: any) => {
                    // console.log('On Message refresh pull next page of messages depending on user page at time of refresh - response: ', response);
                    // console.log("Current page: ", i);
                    // console.log("Current updated conversation messages: ", updatedConversation.messages, " on page ", i);

                    if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                        messagesByPage[i] = response.data.messages;
                    } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                        console.log('Error getting conversation messages after update interval.');
                    }
                });

                promises.push(promise);
            }

            // Wait for all promises to resolve
            await Promise.all(promises).then(() => {
                // Sort messages by page number and combine them
                const sortedMessages = Object.keys(messagesByPage)
                    .map(Number) // Convert keys to numbers
                    .sort((a, b) => b - a) // Sort numerically in ascending order
                    .reduce((messageList: ITwilioConversationsMessageProps[], page) => {
                        return [...messageList, ...messagesByPage[page]];
                }, []);

                updatedConversation.messages = sortedMessages;
                if (!activeConversation) return;

                console.log("Current vs updated conversation: ", activeConversation, updatedConversation);
                dispatch(reduxSetScrollToMessage(messageIndex));
                // Compare current conversation to updated conversation to see if any data has changed
                if (JSON.stringify(activeConversation) !== JSON.stringify(updatedConversation)) {
                    // console.log("Setting active conversation to updated conversation: ", updatedConversation);
                    
                    dispatch(reduxSetActiveConversation(updatedConversation));
                    const sms_participants = activeConversation.participants.filter((participant: ITwilioConversationsParticipantProps) => participant.messaging_binding?.type === 'sms');
                    let shouldMessagingBeEnabled = true;
                    sms_participants.forEach((participant: ITwilioConversationsParticipantProps) => {
                        if (!participant.opt_in_status) {
                            shouldMessagingBeEnabled = false;
                        }
                    });
                    setIsMessagingEnabled(shouldMessagingBeEnabled);
                    //dispatch(reduxSetScrollToMessage(messageIndex));
                } else {
                    // console.log("Current conversation messages: ", activeConversation.messages);
                    // console.log("Updated conversation messages: ", updatedConversation.messages);
                    // console.log("Current conversation is the same as updated conversation, no need to update.");
                }
            });
        } else {
            // Pull conversation messages and update if new data is different than current
            // Pull messages for page 2 and add to current messages
            let updatedConversation: ITwilioConversationsConversationProps = activeConversation ? {...activeConversation} : {} as ITwilioConversationsConversationProps;
            let hasNextPage = false;
            dispatch(TwilioConversationsRedux.GetUserConversationMessages(activeConversation.sid, 1, 20)).then((response: any) => {
                // console.log('On Message refresh pull page 1 of messages - response: ', response);
                if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                    // Set updated conversation messages to response data messages
                    updatedConversation.messages = response.data.messages;
                    hasNextPage = response.data.has_next_page;

                    // Compare current conversation to updated conversation to see if any data has changed
                    // console.log("Current vs updated conversation: ", activeConversation, updatedConversation);
                    
                    if (!activeConversation) return;
                    dispatch(reduxSetScrollToMessage(messageIndex));
                    if (JSON.stringify(activeConversation) !== JSON.stringify(updatedConversation)) {
                        // console.log("Setting active conversation to updated conversation: ", updatedConversation);
                        
                        dispatch(reduxSetActiveConversation(updatedConversation));
                        setMessagesHasNextPage(hasNextPage);
                        const sms_participants = activeConversation.participants.filter((participant: ITwilioConversationsParticipantProps) => participant.messaging_binding?.type === 'sms');
                        let shouldMessagingBeEnabled = true;
                        sms_participants.forEach((participant: ITwilioConversationsParticipantProps) => {
                            if (!participant.opt_in_status) {
                                shouldMessagingBeEnabled = false;
                            }
                        });
                        setIsMessagingEnabled(shouldMessagingBeEnabled);
                        dispatch(reduxSetScrollToMessage(messageIndex));
                    } else {
                        // console.log("Current conversation messages: ", activeConversation.messages);
                        // console.log("Updated conversation messages: ", updatedConversation.messages);
                        // console.log("Current conversation is the same as updated conversation, no need to update.");
                    }
                } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                    console.log('Error getting conversation messages after conversation clicked.');
                }
            });
            
        }
    }
    const handlePullNextPageOfMessages = (conversation: ITwilioConversationsConversationProps) => {
        // Get conversation messages from API
        setMessagesPaginatorClicked(true);
        let updatedConversation: ITwilioConversationsConversationProps = conversation ? {...conversation} : {} as ITwilioConversationsConversationProps;
        let hasNextPage = false;
        console.log(currentConversationMessagesPage);
        // Pull new data
        dispatch(TwilioConversationsRedux.GetUserConversationMessages(conversation.sid, currentConversationMessagesPage + 1, 20)).then((response: any) => {
            console.log('Pull next page of messages response: ', response);
            if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                // Keep original messages and add new messages to the beginning
                updatedConversation.messages = updatedConversation.messages ? [...response.data.messages, ...updatedConversation.messages] : response.data.messages;
                hasNextPage = response.data.has_next_page;
            } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                console.log('Error getting conversation messages after conversation clicked.');
            }

            if (!activeConversation) return;

            // Compare current conversation to updated conversation to see if any data has changed
            if (JSON.stringify(conversation) !== JSON.stringify(updatedConversation)) {
                dispatch(reduxSetActiveConversation(updatedConversation));
                setMessagesHasNextPage(hasNextPage);
                setCurrentConversationMessagesPage(currentConversationMessagesPage + 1);
            } else {
                console.log("Current conversation messages: ", conversation.messages);
                console.log("Updated conversation messages: ", updatedConversation.messages);
                console.log("Current conversation is the same as updated conversation, no need to update.");
            }
        });
    }

    const handleCreateNewMessage = (conversationId: string, message: string, participantSid: string, participantName: string) => {
        // Fire off new message dispatch
        console.log('Creating new message for conversation ' + activeConversation?.friendly_name + ' with message: ' + message);
        dispatch(TwilioConversationsRedux.PostNewConversationMessage(conversationId, message, participantSid, participantName)).then((response: any) => {
            console.log('new message response', response);
            if (response.type === twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_SUCCESS) {
                // Get conversation messages from API
                dispatch(TwilioConversationsRedux.GetUserConversationMessages(conversationId)).then((response: any) => {
                    console.log('after new message, get conversation messages response', response);
                    if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                        // Update active conversation messages
                        const updatedConversation: ITwilioConversationsConversationProps = activeConversation ? {...activeConversation} : {} as ITwilioConversationsConversationProps;
                        updatedConversation.messages = response.data.messages;
                        dispatch(reduxSetActiveConversation(updatedConversation));
                        // Update user conversations locally with new messages
                        const updatedUserConversations = userConversations.map((conversation: ITwilioConversationsConversationProps) => {
                            if (conversation.sid === conversationId) {
                                conversation = { ...conversation, messages: response.data.messages };
                            }
                            return conversation;
                        });
                        dispatch(reduxSetUserConversations(updatedUserConversations));

                        // Update pre-send message for existing conversation with empty message so it doesn't appear if the user switches back to the conversation
                        dispatch(reduxSetLoadedConversationsPreSendMessage(loadedConversationsPreSendMessage.map((conversation: { conversation_sid: string, message: string }) => {
                            if (conversation.conversation_sid === updatedConversation.sid) {
                                return { ...conversation, message: "" };
                            }
                            return conversation;
                        })));
                    } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                        console.log('Error getting updated conversation messages after new conversation message created.');
                    }
                });
            } else if (response.type === twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_FAILURE) {
                console.log('Error creating new conversation message.');
            } else if (response.type === twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_CONFLICT) {
                notifyInAppError("Conflict when creating new conversation message: " + JSON.stringify(response.data.error))
                console.log('Conflict creating new conversation message: ', response.data.error);
                // setShowModalErrorText(response.data.error);
                // setShowModalError(true);
            }
        });
    }

    

    const handleConversationMuteClicked = (conversation: ITwilioConversationsConversationProps) => {
        // Fire off new dispatch for current user to mute the given conversation
    }

    const handleConversationLeaveClicked = (conversation: ITwilioConversationsConversationProps) => {
        // Set conversation to leave
        setConversationToLeave(conversation);
        // Open modal to confirm leaving conversation
        setIsModalConfirmLeaveConversationOpen(true);
    }

    const handleConversationLeave = () => {
        if (conversationToLeave && conversationToLeave.sid) {
            // Get current users participant sid from conversation
            const userParticipant = conversationToLeave.participants.find((participant: ITwilioConversationsParticipantProps) => 
                participant.identity === userDetails.twilio_conversations_user_identity
            );
            if (userParticipant && userParticipant.sid) {
                // Fire off new dispatch for current user to leave the given conversation
                console.log('Leaving conversation ' + conversationToLeave.friendly_name);
                setLoader(true);
                dispatch(TwilioConversationsRedux.PostLeaveUserChatConversation(conversationToLeave.sid, userParticipant?.sid)).then((response: any) => {
                    setLoader(false);
                    console.log('response', response);
                    if (response.type === twilioConversationsConstants.POST_LEAVE_CONVERSATION_SUCCESS) {
                        notifyInAppSuccess("Successfully left the conversation!");
                        // Update user conversations locally
                        const updatedUserConversations = userConversations.filter((conversation: ITwilioConversationsConversationProps) => conversation.sid !== response.data.conversation_id);
                        dispatch(reduxSetUserConversations(updatedUserConversations));
                        // Update active conversation locally
                        if (activeConversation && activeConversation.sid === response.data.conversation_id) {
                            dispatch(reduxSetActiveConversation(null));
                        }
                        // Update pre-send message for existing conversation by removing it
                        dispatch(reduxSetLoadedConversationsPreSendMessage(
                            loadedConversationsPreSendMessage.filter(
                                (conversation: { conversation_sid: string, message: string }) => conversation.conversation_sid !== response.data.conversation_id)
                            )
                        );
                    } else if (response.type === twilioConversationsConstants.POST_LEAVE_CONVERSATION_FAILURE) {
                        notifyInAppError("Error when leaving conversation: " + JSON.stringify(response));
                        console.log('Error leaving conversation.');
                    }
                });
            } else {
                notifyInAppError("Error: Could not find participant details for current user in conversation.");
            }
        } else {
            notifyInAppError("Error: No conversation set to leave.");
        }
    }

    useEffect(() => {
        console.log("Container height: ", refTwilioConversationsContainer.current && refTwilioConversationsContainer.current['offsetHeight']);
        console.log("Header height: ", refTwilioConversationsHeader.current && refTwilioConversationsHeader.current['offsetHeight']);
        console.log("Content height: ", twilioConversationsContentHeight);
    }, [twilioConversationsContentHeight, refTwilioConversationsContainer, refTwilioConversationsHeader]);
    useEffect(() => {
        console.log("loader: ", loader);
    }, [loader]);
    return (
        <div 
            className={`twilio-conversations-container h-100 overflow-auto position-relative ${chatOpenClass}`} 
            ref={refTwilioConversationsContainer} 
            style={{
                // Only apply background if dark mode is enabled
                backgroundColor: isDarkMode ? "#15222b" : "",
            }}
        >
            <div 
                className={
                    "twilio-conversations-header heading-wrap d-flex align-items-center justify-content-between px-16 px-md-24 chat-type-" + chatType
                } 
                ref={refTwilioConversationsHeader}
                style={{
                    // Only apply background if dark mode is enabled
                    backgroundColor: isDarkMode ? "#15222b" : "",
                }}
            >
                <TwilioHeader 
                    filterOption={filterOption}
                    onFilterClick={conversationFilterHandler}
                    chatType={chatType}
                    advisor_or_staff_number={userDetails.twilio_assigned_proxy_number ? userDetails.twilio_assigned_proxy_number : ""}
                    searchAllConversationsText={searchAllConversationsText}
                    setSearchAllConversationsText={setSearchAllConversationsText}
                    userDetails={userDetails}
                    handleConversationClicked={handleConversationClicked}
                    setMessageSearchId={setMessageSearchId}
                    messageSearchId={messageSearchId}
                    handlePullCurrentPageOfMessages={handlePullCurrentPageOfMessages}
                    setCurrentConversationMessagesPage={setCurrentConversationMessagesPage}
                    currentConversationMessagesPage={currentConversationMessagesPage}
                    setHasUserScrolled={setHasUserScrolled}
                    setMessagesHasNextPage={setMessagesHasNextPage}
                />
            </div>
            {loader ? (
                <LoadingSpinner isActive={loader} />
            ) : (
                <>
                    <div className='twilio-conversations-content d-flex w-100 position-relative' >
                        <TwilioConversationList 
                            userConversations={filteredConversations.length > 0 ? filteredConversations : userConversations}
                            handleNewConversationClicked={handleCreateNewConversationClicked} 
                            handleConversationClicked={handleConversationClicked}
                            handleConversationMuteClicked={handleConversationMuteClicked}
                            handleConversationLeaveClicked={handleConversationLeaveClicked}
                            setShowModalError={setShowModalError}
                            setShowModalErrorText={setShowModalErrorText}
                            activeConversation={activeConversation}
                            chatType={chatType}
                            userDetails={userDetails}
                            filterOption={filterOption}
                            messageSearchId={messageSearchId}
                            setMessageSearchId={setMessageSearchId}
                            setHasUserScrolled={setHasUserScrolled}
                        />
                        <TwilioMessages 
                            conversation={
                                filteredConversations.length > 0 
                                ? filteredConversations.find((conversation: ITwilioConversationsConversationProps) => conversation.sid === activeConversation?.sid) || null
                                : activeConversation
                            }
                            userDetailsAsParticipant={userDetailsAsParticipant}
                            handleCreateNewMessage={handleCreateNewMessage}
                            handlePullNextPageOfMessages={handlePullNextPageOfMessages}
                            paginatorClicked={messagesPaginatorClicked}
                            setPaginatorClicked={setMessagesPaginatorClicked}
                            messagesHasNextPage={messagesHasNextPage}
                            setShowConversationInfo={setShowConversationInfo}
                            setShowConversationSearch={setShowConversationSearch}
                            isMessagingEnabled={isMessagingEnabled}
                            userDetails={userDetails}
                            chatType={chatType}
                            setHasUserScrolled={setHasUserScrolled}
                            hasUserScrolled={hasUserScrolled}
                            
                        />
                        {showConversationInfo && activeConversation && (
                            <TwilioConversationInfo 
                                userDetails={userDetails}
                                setUserConversations={reduxSetUserConversations}
                                setShowConversationInfo={setShowConversationInfo}
                                setShowModalError={setShowModalError}
                                setShowModalErrorText={setShowModalErrorText}
                                handleConversationLeaveClicked={handleConversationLeaveClicked}
                                setShowModalTwilioDeleteParticipantFromConversation={setShowModalTwilioDeleteParticipantFromConversation}
                                chatType={chatType}
                            />
                        )}
                        {showConversationSearch && activeConversation && (
                            // <TwilioConversationSearch 
                            //     userConversation={activeConversation}
                            //     setShowModalError={setShowModalError}
                            //     setShowModalErrorText={setShowModalErrorText}
                            //     setShowConversationSearch={setShowConversationSearch}
                            // />
                            <SearchConversationMessages 
                                userConversation={activeConversation}
                                setShowConversationSearch={setShowConversationSearch}
                                handlePullCurrentPageOfMessages={handlePullCurrentPageOfMessages}
                                setCurrentConversationMessagesPage={setCurrentConversationMessagesPage}
                                setHasUserScrolled={setHasUserScrolled}
                                setMessagesHasNextPage={setMessagesHasNextPage}
                                //setMessageIndex={setMessageIndex}
                            />
                        )}
                    </div>
                    <ModalTwilioNewConversation
                        userDetails={userDetails}
                        isShowing={showModalTwilioNewConversation}
                        handleClose={() => {setShowModalTwilioNewConversation(false)}}
                        chatType={chatType}
                    />
                    {userDetails?.employee?.role === EmployeeRole.STAFF && userDetails.admin_type === AdminType.FIRM_ADMIN && userDetails.email === "tfry@monetagroup.com" && (
                        <ModalTwilioDeleteTextParticipantFromConversation
                            isShowing={showModalTwilioDeleteParticipantFromConversation}
                            handleClose={() => {setShowModalTwilioDeleteParticipantFromConversation(false)}}
                            // Try to pass active conversation sid and text participant sid
                            presetActiveConversationSid={activeConversation?.sid}
                            // presetParticipantSid={userDetailsAsParticipant?.sid}
                        />
                    )}
                    <ModalGeneralError 
                        isShowing={showModalError}
                        setIsShowing={setShowModalError}
                        errorMessage={showModalErrorText}
                        setErrorMessage={setShowModalErrorText}
                    />
                    <Modal
                        open={isModalConfirmLeaveConversationOpen}
                        onClose={() => setIsModalConfirmLeaveConversationOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="mc-modal mc-modal-xsmall"
                    >
                        <Box>
                            {/* Exit button */}
                            <Button className="exit-button" onClick={() => setIsModalConfirmLeaveConversationOpen(false)}>
                                <CloseIcon />
                            </Button>
                            <Typography id="modal-modal-title" className="mc-modal-title" variant="h6" component="h2">
                                Are you sure?
                            </Typography>
                            <Typography id="modal-modal-description" className="mc-modal-description" sx={{ mt: 2 }}>
                                Leaving the conversation will automatically opt the client out of texting with you in the future. You will have to have them opt back in to continue to text.
                            </Typography>
                            <Box sx={{ mt: 2 }} className="mc-footer-buttons">
                                <Button className="secondary" onClick={() => setIsModalConfirmLeaveConversationOpen(false)}>Cancel</Button>
                                <Button className="primary" onClick={() => {
                                    setIsModalConfirmLeaveConversationOpen(false);
                                    handleConversationLeave();
                                }}>
                                    Leave
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                    <SoundPlayer ref={soundPlayerRef} url={dingCorrect} />
                </>
            )}
        </div>
    );
}

export default TwilioDashboard;