import { notification } from "antd";
import Croppie from "croppie";
import "croppie/croppie.css";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { profileSetting } from "../../redux/actions/ProfileSetting/ProfileSetting";
import { useAppDispatchJS } from "../../redux/hooks";

const ProfilePhotoSetting = (props) => {
  const croppieOptions = {
    showZoomer: true,
    enableOrientation: true,
    viewport: {
      width: 200,
      height: 200,
      type: "circle",
    },
    boundary: {
      width: "200px",
      height: "200px",
    },
  };
  const [showProfUpdate, setProfUpdateShow] = useState(false);
  const [profileImage, setProfileImage] = useState();

  const [zoom, setZoomValue] = useState(1);
  const dispatch = useAppDispatchJS();

  const handleProfUpdateClose = () => {
    setProfUpdateShow(false);
  };
  const [file, setFile] = useState();
  var c = "";

  useEffect(() => {
    const { visible, closeModal } = props;
    setProfUpdateShow(visible);
  }, [props]);

  useEffect(() => {
    setTimeout(() => {
      auseEffect();
    }, 200);
  }, [profileImage]);

  const Notify = (type, mesage, icons) => {
    notification[type]({
      description: mesage,
      icon: icons,
      top: 65,
    });
  };

  function uploadImage(e) {
    e.preventDefault();
    let file = e.target.files[0];
    setFile(e.target.files[0]);
    let reader = new FileReader();
    reader.onload = function (e) {
      setProfileImage(e.currentTarget.result);
    };
    reader.readAsDataURL(file);
  }

  function auseEffect() {
    console.log(profileImage);
    let ele = document.getElementById("hiqwe");
    console.log(ele);
    try {
      c = new Croppie(ele, croppieOptions);
      c.bind({
        url: profileImage,
      }).catch((e) => {
        console.log("Error binding image to croppie:", e);
      }).then((d) => {
        // console.log("Croppie binded image:", d);
      });
    } catch (e) {
      console.log("Error setting up croppie for profile image:", e);
    }
  }

  async function uploadImageSave() {
    c.result("base64").then((base64) => {
      console.log(base64, " this is new ");
      let img = dataURLtoFile(base64, file.name);
      var formData = new FormData();
      formData.append("profile_image", img);

      dispatch(profileSetting.imageUpload(formData)).then((response) => {
        // setLoader(false);
        if (response.type === "IMAGE_UPLOAD_SUCCESS") {
          Notify(
            "success",
            `Updated profile photo`,
            <em className="icon-check-outline"></em>
          );
          const { closeModal } = props;
          closeModal();
        }
      });
    });
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function handleDrop(e) {
    e.preventDefault();
    //const dt = e.dataTransfer;
    const files = e.dataTransfer.files;
    if (files[0].type.includes("image")) {
      setFile(files[0]);
      let reader = new FileReader();
      reader.onload = function (e) {
        setProfileImage(e.currentTarget.result);
      };
      reader.readAsDataURL(files[0]);
    }
  }

  return (
    <div>
      {/* Profile Update Model */}
      <Modal
        show={showProfUpdate}
        onHide={() => {
          handleProfUpdateClose;
          const { closeModal } = props;
          closeModal();
        }}
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title>Update Profile Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!profileImage && (
            <div className="upload-area profupload-area d-flex align-items-center justify-content-center">
              <div
                className="drag-block mx-auto"
                onDrop={(e) => handleDrop(e)}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <span className="upload-icon mx-auto">
                  <em className="icon-upload-filled"></em>
                </span>
                <span className="d-none d-lg-block fs-18 lh-24">
                  Drag files here
                </span>
                <span className="or-line d-none d-lg-block">
                  <span>Or</span>
                </span>
                <div className="file-upload">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      uploadImage(e);
                    }}
                  />
                  <span className="file-upload-link">Browse Files</span>
                </div>
              </div>
            </div>
          )}

          {profileImage && (
            <>
              <div
                className="profile-image-zoom result"
                //id="hubble-container"
                id="hiqwe"
              ></div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="pt-24 border-0">
          {file && (
            <>
              <button
                className="btn btn-outline-secondary"
                variant="secondary"
                onClick={(e) => {
                  const { closeModal } = props;
                  closeModal();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                variant="primary"
                onClick={(e) => {
                  uploadImageSave();
                }}
              >
                Save
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfilePhotoSetting;
