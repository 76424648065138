import { useEffect, useState } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { useAppSelectorJS } from './redux/hooks';
import { useAppDispatchTS } from './redux/hooksTS';
import { reduxSetCurrentEnvironment } from './redux/slicesTS/UsersSlice';
import AppRoutes from './routes';

const App = () => {
  // Test

  const [user, setUser] = useState(false);
  const loggedIn = localStorage.getItem("user");
  const authentication = useAppSelectorJS(state => state.authentication);
  const dispatchTS = useAppDispatchTS();

  useEffect(() => {
    if (authentication?.data?.uuid?.length > 0) {
      setUser("true");
    } else if (loggedIn === "true") {
      setUser("true");
    } else if(localStorage.getItem("user") === "true"){
      setUser("true")
    }else {
      setUser("false")
    }

  }, [authentication, loggedIn]);

  useEffect(() => {
    dispatchTS(reduxSetCurrentEnvironment(process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'local'));
  }, []);

  return (
    <div className="">
      <BrowserRouter>
        <AppRoutes loggedIn={user} />
      </BrowserRouter>
    </div>
  )
}

export default App
